import { Injectable } from '@angular/core';

@Injectable()
export class AdminActionService {

    APPROVE_MESSAGE: {title,description, config} = {
        title: 'Confirm Action',
        description: 'Thread will be approved for posting, no further actions can be performed, please confirm',
        config: {
            type: 'LOCATION_DELETE',
            action: {
                cancel: "Cancel",
                ok: "Ok"
            }
        }
    };
  

     CLOSE_MESSAGE: {title,description, config} = {
        title: 'Confirm Action',
        description: 'Thread will be closed, no further actions can be performed, please confirm',
        config: {
            type: 'LOCATION_DELETE',
            action: {
                cancel: "Cancel",
                ok: "Ok"
            }
        }
    };

     SPAM_MESSAGE: {title,description, config} = {
        title: 'Confirm Action',
        description: 'Thread will be tagged as spam and a notification would be sent to user with the moderation comments , no further actions can be performed, please confirm',
        config: {
            type: 'LOCATION_DELETE',
            action: {
                cancel: "Cancel",
                ok: "Ok"
            }
        }
    }; 

    constructor() { }
}

