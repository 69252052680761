<div class="modal fade tag-dialog-modal" bsModal #tagModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Select Tags</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="cancelClicked()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-5 list-left">
                        <div class="row">
                            <div class="col-md-5 text-padding">All Topics
                            </div>
                            <div class="col-md-7">
                                <div class="form-group has-feedback" style="position: relative;">

                                    <input type="text" class="form-control" placeholder="keywords" [(ngModel)]="keyword" (keyup.enter)="onSearch()">

                                    <span style="position: absolute;right: 10px;top: 10px;" class="form-control-feedback search-icon" (click)="onSearch()"> <i class="fa fa-search"></i></span>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-2 list-center"></div>
                    <div class="col-md-5 list-right text-padding">Selected Topics</div>

                </div>
                <div class="row mt-2">
                    <div class="col-md-5 list-left">
                        <div class="list-main">
                            <div class="" *ngFor="let item of lstTags">
                                <div class="list-category" [style.display]="(item.tags&&item.tags.length>0)?'block':'none'"> {{item.categoryName}}</div>
                                <div class="list-tag" *ngFor="let subItem of item.tags" (click)="subItem.selected=!subItem.selected" [ngClass]="{'active':subItem.selected}">
                                    {{subItem.tagName}}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-2 list-center">
                        <div class="center arrows">
                            <div (click)="onMoveToSelectedTagList()"><i class="fa fa-angle-right"></i></div>
                            <div (click)="onMoveToTagList()"><i class="fa fa-angle-left"></i></div>
                        </div>

                    </div>
                    <div class="col-md-5 list-right">
                        <div class="list-main">
                            <div class="list-seleted-tag" *ngFor="let subItem of lstSelectedTags" (click)="subItem.selected=!subItem.selected" [ngClass]="{'active':subItem.selected}">
                                {{subItem.tagName}}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-ey-default me-2" (click)="okayClicked();" type="button">Done</button>
                        <button class="btn btn-ey-default" (click)="cancelClicked();" type="button">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>