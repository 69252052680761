import { ProgressBarTypes } from './progress-bar-types.enum';
import { ElementRef } from '@angular/core';

export class ProgressBarOptions {
    showProgressBar: boolean = false;
    progressBarType: ProgressBarTypes = ProgressBarTypes.NOTHING;
    updatePanelName?: string = "";
}

export class ProgressBarHelper {
    static readonly NoProgressBar: ProgressBarOptions = { "showProgressBar": false, "progressBarType": ProgressBarTypes.NOTHING }
    static readonly DefaultPost: ProgressBarOptions = { "showProgressBar": true, "progressBarType": ProgressBarTypes.PAGE_POSTING }
    static readonly PageTransition: ProgressBarOptions = { "showProgressBar": true, "progressBarType": ProgressBarTypes.PAGE_TRANSITION }
    static newUpdateProgressOption(panelName): ProgressBarOptions {
        var updateProgressOption: ProgressBarOptions = {
            showProgressBar: true, progressBarType: ProgressBarTypes.PAGE_UPDATING, updatePanelName: panelName
        };
        return updateProgressOption;
    }
   
}