import { Component, OnInit, Input, ViewChild, Injector, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { QueryService } from "../../service/query.service";
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";


@Component({
    selector: 'edit-discussion',
    templateUrl: './edit-discussion.component.html',
    styleUrls:['./edit-discussion.component.scss']
})

export class EditDiscussionComponent implements OnInit {

    @ViewChild('editModal', { static: true }) editModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
    threadDetails: any;
    comments = '';
    title = '';
    description = '';
    topics = [];
    tempSelectArr = [];
    tempUnselectArr = [];
    tags = [];
    communities = [];
    communityId: number;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    oThreadDetails:any;
    oTags = [];
    oCommunityId = 0;
    titleChange = false;
    descChange = false;
    tagsChange = false;
    communityChange = false;
    showCommentError = false;
    showTitleError = false;
    showDescriptionError = false;
    isAnswer: boolean;
    answerId: number;
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };
    constructor(
        private queryService: QueryService,
        private injector: Injector,
        private logTypes: LogTypes,
        private logService: LogService
    ) {
        this.isAnswer = this.injector.get('isAnswer');
        if (this.isAnswer) {
            this.threadDetails = this.injector.get('content');
            this.description = this.threadDetails.response;
            this.oThreadDetails = JSON.parse(JSON.stringify(this.threadDetails));
        } else {
            this.threadDetails = this.injector.get('content');

            this.tags = this.threadDetails.tags;
            this.communityId = this.threadDetails.communityId;
            this.title = this.threadDetails.title;
            this.oCommunityId = JSON.parse(JSON.stringify(this.threadDetails.communityId));
            this.oTags = JSON.parse(JSON.stringify(this.threadDetails.tags.filter(x => !x.isCommunityTag)
                .map(obj => {
                    if (!obj.isCommunityTag)
                        return obj.topicId;
                })));

            this.description = this.threadDetails.description;
            this.oThreadDetails = JSON.parse(JSON.stringify(this.threadDetails));
        }
       
    }

    ngOnInit() {

        this.queryService.getTopics().subscribe(
            (data: any) => {
                if (data) {
                    this.topics = data;
                    let temp;
                    for (let i in this.topics) {
                        temp = this.tags.find(o => o.topicId == this.topics[i].id && !o.isCommunityTag);
                        if(temp)
                            this.topics[i].selected = true;
                         else
                            this.topics[i].selected = false;

                    }
                }

            });
        
        this.queryService.getAllAuthorisedCommunities(this.threadDetails.owner.id).subscribe(
            (data: any) => {
                if (data) {
                    this.communities = data;
                    console.log('communities', data);
                }

            });
    }

    getTopics(select: boolean) {
        if (select)
            return this.topics.filter(x => x.selected);
        else
            return this.topics.filter(x => !x.selected);

    }
    tempSelect(topicId: number, select: boolean) {
        if (select) {
            let index = this.tempSelectArr.indexOf(topicId);
            if (index > -1) {
                this.tempSelectArr.splice(index, 1);
            } else {
                this.tempSelectArr.push(topicId);
            }
        } else {
            let index = this.tempUnselectArr.indexOf(topicId);
            if (index > -1) {
                this.tempUnselectArr.splice(index, 1);
            } else {
                this.tempUnselectArr.push(topicId);
            }
        }
    }
    checkSelectedCategory(id, select: boolean) {
        if (select) {
            if (this.tempSelectArr.indexOf(id) > -1) {
                return true;
            } else {
                return false;
            }
        }
        else {
            if (this.tempUnselectArr.indexOf(id) > -1) {
                return true;
            } else {
                return false;
            }
        }
    }
    moveToOtherColumn(type: boolean) {
        if (type) {
            for (let i in this.topics) {
                if (this.tempSelectArr.indexOf(this.topics[i].id) > -1)
                    this.topics[i].selected = true;
            }
            this.tempSelectArr = [];
        } else {
            for (let i in this.topics) {
                if (this.tempUnselectArr.indexOf(this.topics[i].id) > -1)
                    this.topics[i].selected = false;
            }
            this.tempUnselectArr = [];
        }
    }
    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }


    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.editModal.config = this.config;
        this.editModal.show();
        return this.observable;
    }

     closeDialog() {
        this.editModal.hide();
    }
    validate() {
        if (!this.isAnswer) {
            if (this.title == '') {
                this.showTitleError = true;
                return false;
            }
        }
        if (this.description == '') {
            this.showDescriptionError = true;
            return false;
        } else if (this.comments == '') {
            this.showCommentError = true;
            return false;
        } else {
            return true;
        }
    }
    editThread() {
       
        if (this.validate()) {
            if (this.comments != '') {
                let params: any = {};
                params.postId = this.threadDetails.id;
                params.action = 4;
                params.editedResponse = this.description;
                params.actionComments = this.comments;
                console.log(params);
                this.queryService.postAnswerModerate(params).subscribe(
                    (data: any) => {
                        if (data) {
                            this.returnedValue = "edited";
                            this.onModalClosed(null);

                        }
                    }
                );

                // Log the answer edit action
                let log = new LogAction();
                log.action = this.logTypes.ANSWER_MODIFIED;
                log.postId = this.threadDetails.id;
                this.logService.logAction(log);
            }
        }
    }
    edit() {
        if (this.validate()) {
            if (this.comments != '') {
                let params: any = {};
                params.threadId = this.threadDetails.id;
                params.action = 5;
                params.actionComments = this.comments;
                var topics = this.getTopics(true).map(function (obj) {
                    return obj.id;
                });
                params.edit = {
                    subjectEdited: this.title,
                    descriptionEdited: this.description,
                    communityIdEdited: this.communityId,
                    topicIdsEdited: topics

                }
                console.log(params);
                this.queryService.postDicusssionModerate(params).subscribe(
                    (data: any) => {
                        if (data) {
                            this.returnedValue = "edited";
                            this.onModalClosed(null);

                        }
                    }
                );

                // Log the Discussion actions
                let log = new LogAction();
                log.action = this.logTypes.DISCUSSION_MODIFIED;
                log.threadId = this.threadDetails.id;
                this.logService.logAction(log);
            }
        }
    }
    cancel() {
        this.onModalClosed(null);
    }
    valueChange(value, type) {
        if (type == 'title') {
            if (this.showTitleError && value)
                this.showTitleError = false;
            if (this.oThreadDetails.title != value) {
                this.titleChange = true;
            } else {
                this.titleChange = false;
            }
        } else if (type == 'desc') {
            if (this.showDescriptionError && value)
                this.showDescriptionError = false;
            if (this.isAnswer) {
                if (this.oThreadDetails.description != value) {
                    this.descChange = true;
                } else {
                    this.descChange = false;
                }
            } else {
                if (this.oThreadDetails.response != value) {
                    this.descChange = true;
                } else {
                    this.descChange = false;
                }
            }
        } else if (type == 'community') {            
            if (this.oCommunityId != value) {
                this.communityChange = true;
            } else {
                this.communityChange = false;
            }
        } else if (type=='comment'){
            if (this.showCommentError && value)
                this.showCommentError = false;

        }else {
            this.tagsChange = false;
            var topics = this.getTopics(true).map(function (obj) {
                return obj.id;
            });
            if (topics.length != this.oTags.length) {
                this.tagsChange = true;
            } else {
                for (var topic of topics) {
                    if (this.oTags.indexOf(topic) < 0) {
                        this.tagsChange = true;
                    }
                }
            }
        }

    }
}