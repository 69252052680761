import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from '../auth/service/user.service';
import { Response } from './model/response';
import { TimerService } from './timer.service';
import { Constants } from './constant.service';
import { forkJoin } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HTTPService } from "../service/http.service";

const TOKEN_API_URL = environment.VELOCITY_API + 'user/RefreshToken';
const CHECK_AUTH_API_URL = environment.VELOCITY_API + 'user/CheckAuth';

@Injectable()
export class StartupService {
    constructor(
        private _http: HttpClient,
        private userService: UserService,
        private timer: TimerService,
        private constants: Constants,
        private httpService: HTTPService
    ) { }

    loadInitialData() {
        return new Promise((resolve, reject) => {

            var refreshTokenObserver: Observable<any> = Observable.create((observer: any) => {
                this._http.post(TOKEN_API_URL, null, { headers: this.getHeadersWithToken() })
                .pipe(
                  retry(1),
                  catchError(this.handleError)
                ).
                subscribe((response: any) => {
                        if (response && response.status && (response.code === '1000') && response.result) {

                            var currentUser = this.userService.getCurrentUser();
                            currentUser.token = response.result.token;
                            this.userService.setCurrentUser(currentUser);
                            observer.next(true);
                            observer.complete();
                            this.timer.start();
                        }
                        else {
                            observer.next(false);
                            observer.complete();
                        }
                    }, (err: any) => {
                        observer.next(false);
                        observer.complete();
                    });
            });

            var checkAuthObserver: Observable<boolean> = Observable.create((observer: any) => {

                let req = {
                    url: CHECK_AUTH_API_URL,
                    headers: this.getHeadersWithToken(),
                    params: null
                  }
                  this.httpService.post(req)
                    .subscribe((response: any) => {
                        if (response && response.status && (response.code === '1000') && response.result && response.result == true) {
                            observer.next(true);
                            observer.complete();
                        }
                        else {
                            observer.next(false);
                            observer.complete();
                        }
                    }, (err: any) => {
                        observer.next(false);
                        observer.complete();
                    });
            });

            //var startupObservables = [refreshTokenObserver];

            var startupObservables = [checkAuthObserver];


            forkJoin(startupObservables)
                .subscribe(startupData => {
                    
                    if ((startupData || []) && startupData.length > 0) {
                        let response: boolean = startupData[0];
                        if (!response) {
                            this.userService.logout();
                        } else {
                            var elapsedSecond = this.timer.checkElapsedTime();
                            if (elapsedSecond >= this.constants.TIMEOUT_HANDLER_OPTIONS.FORCED_LOGOUT_DURATION) {
                                //Clear session, stop timer, clear start time
                                this.userService.logout();
                            }
                            else {
                                this.timer.start();
                            }
                        }
                    }

                    resolve(null);
                },
                (error: any) => {
                    console.log(error);
                    reject(error);
                })
            
        });
    }

    private getHeaders() {
        let headers = new Headers();
        let r = (Math.random() + 1).toString(36).substring(4);
        headers.append('rid', r);
        headers.append('Accept', 'application/json');
        return headers;
    }

    private getHeadersWithToken() {
        var currentUser = this.userService.getCurrentUser();
        let headers;
        if (currentUser && currentUser.token) {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'rid': this.randomvalue(),
            'Authorization': 'Bearer ' + currentUser.token
          })
        } else {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'rid': this.randomvalue()
          })
    
        }
        return headers;
      }
    // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  randomvalue() {
    return (Math.random() + 1).toString(36).substring(4);
  }
}