import { Component, OnInit, Input, ViewChild, Injector, Output, EventEmitter, TemplateRef, QueryList, ViewChildren, AfterViewInit, ViewEncapsulation } from '@angular/core';
// import 'rxjs/Rx';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { TemplateTypes } from './../template-types.enum';
import { VelocityService } from '../../service/velocity.service';
import { ProgressBarHelper, ProgressBarOptions } from '../../progress-bar-module/progress-bar-options';
import { BaseComponent } from '../../component/base.component';
import { DomSanitizer} from '@angular/platform-browser';



@Component({
    moduleId: module.id.toString(),
    selector: "info-dialog",
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    encapsulation:ViewEncapsulation.None
})

export class InfoDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
    templateType: TemplateTypes = TemplateTypes.DEFAULT;
    types = TemplateTypes;
    isPinned = false;

    templateData: any = {};
    hideCloseButton: boolean = false;
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    constructor(private injector: Injector,
        private velocityService: VelocityService,
        public sanitizer:DomSanitizer) {
        super(injector);
        this.templateType = this.injector.get('templateType');
        this.templateData = this.injector.get('templateData');
        this.hideCloseButton = this.injector.get('hideCloseButton');

    }
    //@ViewChild(this.templateName) templateRef: any;
    @ViewChild('infoModal', { static: true }) infoModal: any;
    returnedValue: string = 'close';

    public config = {
        animated: false,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() {

        

    }

    ngAfterViewInit() {


    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }


    openDialog() {

        //To prevent the dialog box closing by ESC key - Implement for Growth Plan No Action card dialog box
        if (this.templateType == 10) {
            this.config.keyboard = false;
        }

        this.returnedValue = "close";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.infoModal.config = this.config;
        this.infoModal.show();
        return this.observable;
    }

    private closeDialog() {
        this.infoModal.hide();
    }
    onGrowthPlanAction() {
        this.returnedValue = "start";
        this.closeDialog();
    }
    goToListPage() {
        this.closeDialog();

    }
}