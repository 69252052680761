import { Component, Input, ViewChild, Injector, Output, EventEmitter , NgZone} from '@angular/core';
//import { ModalModule, Modal } from 'ng2-modal';
import {Observable, Subject } from 'rxjs';

@Component({
    moduleId: module.id.toString(),
    selector: "confirm-delete",
    templateUrl: './confirm-delete-dialog.component.html',
    styleUrls: ['./confirm-delete-dialog.component.scss']
})

export class ConfirmDeleteDialogComponent {
    showWarning: boolean = false;
    isChecked: boolean = false;
    title: string = "";
    message: string = "";
    customize: any = {};
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    constructor(private injector: Injector, private zone: NgZone) {
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.customize = this.injector.get('config');
    }

    @ViewChild('okCancelModal', { static: true }) okCancelModal: any;
    returnedValue: string = 'no';

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() { }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }

    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }

    okayClicked() {
        if (this.isChecked) {
            this.returnedValue = "yes";
            this.closeDialog();
        } else this.showWarning = true;
    }

    openDialog() {
        this.returnedValue = "no";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        console.log(this.okCancelModal);
        console.log(this.config);
        this.okCancelModal.config = this.config;
        this.okCancelModal.show();
        return this.observable;
    }
    
    private closeDialog() {
        this.okCancelModal.hide();
    }
    changeCheckbox() {
        this.isChecked = !this.isChecked;
        if (this.isChecked) this.showWarning = false;
    }
}