import { Component, ViewChild, Injector, Output, EventEmitter , NgZone, ViewEncapsulation} from '@angular/core';
import {Observable } from 'rxjs';
import {Subject } from 'rxjs';

@Component({
    moduleId: module.id.toString(),
    selector: "ok-cancel-dialog",
    templateUrl: './ok-cancel-dialog.component.html',
    styleUrls: ['./ok-cancel-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class OkCancelDialogComponent {
    title: string = "";
    message: string = "";
    customize: any = {};
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    constructor(private injector: Injector, private zone: NgZone) {
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.customize = this.injector.get('config');
    }

    @ViewChild('okCancelModal', { static: true }) okCancelModal: any;
    returnedValue: string = 'no';

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() { }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }

    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }

    okayClicked() {
        this.returnedValue = "yes";
        this.closeDialog();
    }

    openDialog() {
        this.returnedValue = "no";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.okCancelModal.config = this.config;
        this.okCancelModal.show();
        return this.observable;
    }
    
    private closeDialog() {
        this.okCancelModal.hide();
    }
}