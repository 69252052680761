import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from './model/response';
import { UtilService } from './util.service';
import { Constants } from './constant.service';
import { UserService } from '../auth/service/user.service';
import { ProgressBarOptions } from './../progress-bar-module/progress-bar-options';
import { AjaxErrorOptions, ErrorHandlerHelper } from './model/ajax-error-options';
import { ErrorHandlerTypes } from './model/ajax-error-handler-types';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';

const ApiUrl = environment.QUERY_ENGINE_API;

@Injectable()
export class QueryService {
    constructor(
        private utilService: UtilService,
        private userService: UserService,
        private constants: Constants,
        private router: Router,
        private msalService: MsalService
    ) { }

    invokeGetAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {

        return Observable.create(observer => {
            this.utilService.doGet(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    this.handleError(error, errorHandler);
                });
        });
    }

    invokePostAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        const user = this.userService.getCurrentUser();
        if (user && !params.userId) {
            params.userId = user.userId;
        }

        return Observable.create(observer => {

            this.utilService.doPost(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    invokePostUploadAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        const user = this.userService.getCurrentUser();

        return Observable.create(observer => {
            this.utilService.doUpload(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    handleErrorResponse(errorCode, errorMessage, errorHandler?: AjaxErrorOptions) {

        if (errorCode == "401") {
            this.msalService.logout();
            return;
        }

        if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        //else if (errorCode == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
        //    this.adalService.logOut();
        //}
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE && errorCode == "404") {
            this.router.navigate(['general', 'error404']);
        }
    }

    handleError(error, errorHandler?: AjaxErrorOptions) {

        if (error.status == "401") {
            this.msalService.logout();
            return;
        }

        let errorMessage: any = "An error occured while processing the request";
        var parseError = false;
        try {
            if (error._body && JSON.parse(error._body).message) {
                errorMessage = JSON.parse(error._body).message;
            }
        }
        catch (e) {
            parseError = true;
        }
        if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        //else if (error.status == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
        //    this.adalService.logOut();
        //}
        else if (error.status == "404" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE) {
            this.router.navigate(['general', 'error404']);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else {
            this.utilService.showError(errorMessage);
        }
    }

    getDiscussionLists(params: any, progressBarOptions?: ProgressBarOptions) {

        return this.invokePostAPI(this.constants.COMMUNITY.GET_DISCUSSION_LIST, params, progressBarOptions);
    }

    getDiscussionDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        params.userId = 9; //TODO change later
        return this.invokePostAPI(this.constants.COMMUNITY.DISCUSSION_DETAILS, params, progressBarOptions);
    }

    getDiscussionAnswers(params: any, progressBarOptions?: ProgressBarOptions) {
        params.userId = 9; //TODO change later
        return this.invokePostAPI(this.constants.COMMUNITY.DISCUSSION_ANSWERD, params, progressBarOptions);
    }

    postDicusssionModerate(params: any, progressBarOptions?: ProgressBarOptions) {
        params.userId = 1; //TODO change later
        return this.invokePostAPI(this.constants.COMMUNITY.DISCUSSION_MODERATE, params, progressBarOptions);
    }

    postAnswerModerate(params: any, progressBarOptions?: ProgressBarOptions) {
        params.userId = 1; //TODO change later
        return this.invokePostAPI(this.constants.COMMUNITY.ANSWER_MODERATE, params, progressBarOptions);
    }

    getAnswersLists(params: any, progressBarOptions?: ProgressBarOptions) {

        return this.invokePostAPI(this.constants.COMMUNITY.GET_ANSWERS_LIST, params, progressBarOptions);
    }

    getTopics(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.CONNECT.GET_TOPICS, {}, progressBarOptions);
    }

    getAllCommunities(progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.CONNECT.GET_ALL_COMMUNITIES}/${user.userId}`, {}, progressBarOptions);
    }
    getAnswerDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        params.userId = 9; //TODO change later
        return this.invokePostAPI(this.constants.COMMUNITY.GET_ANSWER_DETAILS, params, progressBarOptions);
    }

    getAllCommunitiesForHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_ALL_COMMUNITIES, params, progressBarOptions);
    }

    addCommunityToHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.ADD_COMMUNITY_HT, params, progressBarOptions);
    }

    deleteCommunityFromHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.DELETE_COMMUNITY_HT, params, progressBarOptions);
    }

    getHTCommunities(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_HT_COMMUNITIES, params, progressBarOptions);
    }

    getAllDiscussionsForHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_ALL_DISCUSSIONS, params, progressBarOptions);
    }

    addDiscussionToHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.ADD_DISCUSSION_HT, params, progressBarOptions);
    }

    deleteDiscussionFromHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.DELETE_DISCUSSION_HT, params, progressBarOptions);
    }

    getHTDiscussions(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_HT_DISCUSSIONS, params, progressBarOptions);
    }

    getReferenceChampionList(params: any, progressBarOptions?: ProgressBarOptions) {
        let parameters: any = {};
        parameters.page = params.page || 1;
        parameters.pageSize = params.pageSize || 10;
        parameters.keyword = params.keyword || "";
        return this.invokePostAPI(this.constants.REFERENCE.GET_API_REF_CHAMPION_LIST, parameters, progressBarOptions);
    }

    getReferenceChampionDetails(championId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.REFERENCE.GET_API_REF_CHAMPION_DETAILS}/${championId}`, {}, progressBarOptions);
    }

    saveChampion(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.SAVE_CHAMPION}`, params, progressBarOptions);
    }

    updateChampion(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.UPDATE_CHAMPION_ACTION, params, progressBarOptions);
    }

    getModeratorList(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.COMMUNITY.GET_MODERATOR_LIST}`, {}, progressBarOptions);;
    }

    saveCommunityOverview(params: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        params.adminId = user.userId;
        return this.invokePostAPI(`${this.constants.COMMUNITY.SAVE_COMMUNITY_OVERVIEW}`, params, progressBarOptions);
    }

    updateCommunityOverview(params: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        params.adminId = user.userId;
        return this.invokePostAPI(`${this.constants.COMMUNITY.UPDATE_COMMUNITY_OVERVIEW}`, params, progressBarOptions);
    }

    getCommunityOverviewDetails(communityId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.COMMUNITY.GET_COMMUNITY_OVERVIEW}/${communityId}`, {}, progressBarOptions);
    }

    getManageComminityList(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONNECT.GET_MANAGE_COMMUNITY_LIST, params, progressBarOptions);
    }

    getCommunityChampions(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.COMMUNITY.GET_COMMUNITY_CHAMPIONS}`, params);
    }

    getCommunityEvents(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.COMMUNITY.GET_COMMUNITY_EVENTS}`, params);
    }

    addDeleteCommunityChampions(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.COMMUNITY.ADD_DELETE_COMMUNITY_CHAMPIONS}`, params);
    }

    addDeleteCommunityEvents(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.COMMUNITY.ADD_DELETE_COMMUNITY_EVENTS}`, params);
    }

    getCommunitySectionCount(communityId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
        return this.invokeGetAPI(`${this.constants.COMMUNITY.GET_COMMUNITY_SECTION_COUNT}/${communityId}`, {}, progressBarOptions);
    }

    updateCommunityAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONNECT.COMMUNITY_APPROVE_DELETE_ACTION, params, progressBarOptions);
    }
    getCommunityMembers(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.COMMUNITY.GET_MEMBER_COMMUNITY}`, params);
    }
    saveCommunityMemberAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.COMMUNITY.SAVE_COMMUNITY_MEMBER_ACTION}`, params);
    }

    getAvailableUsers(filterString: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.GET_AVAILABLE_USERS, { keyword: filterString }, progressBarOptions);
    }

    getAllAuthorisedCommunities(discussionOwnerId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONNECT.GET_ALL_AUTHORISED_COMMUNITIES}/${discussionOwnerId}`, {}, progressBarOptions);
    }

    getCommunityPopupDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.COMMUNITY.LINKED_HOT_TOPICS, params, progressBarOptions);
    }

    searchCommunityOwners(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.COMMUNITY.SEARCH_COMMUNITY_OWNER, params, progressBarOptions);
    }

    searchCommunityModerators(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.COMMUNITY.SEARCH_COMMUNITY_MODERATOR, params, progressBarOptions);
    }
}
