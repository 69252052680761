<!--<modal #okCancelModal modalClass="eyb-dialog-modal" [closeOnEscape]='false' [closeOnOutsideClick]='false' (onClose)="onModalClosed();">
    <modal-header>
        <h1>{{title}}</h1>
    </modal-header>
    <modal-content>
        <div class="row">
            <div class="col-md-12">{{message}}</div>
        </div>
    </modal-content>
    <modal-footer>
        <div class="row">
            <div class="col-md-6">
                <button class="btn" (click)="cancelClicked();" type="button">Cancel</button>
                <button class="btn btn-eyb-default" (click)="okayClicked();" type="button">Ok</button>
            </div>
        </div>
    </modal-footer>
</modal>-->


<div class="modal fade ey-dialog-modal" 
[ngClass]='{
    "location-modal": (customize.type === "LOCATION_DELETE"),
    "register-confirm-modal": (customize.type === "REGISTER_CONFIRM"),
    "logout-modal": (customize.type === "LOGOUT"),
    "countPopup-modal": (customize.type === "COUNT_POPUP")
    }' 
bsModal #okCancelModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{title}}</h4>
            </div>
            <div class="modal-body">
                <div [innerHTML]="message"></div>
            </div>

            <div *ngIf="customize.type !== 'COUNT_POPUP'" class="modal-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="action-buttons">
                            <button class="btn btn-cancel" (click)="cancelClicked();" type="button"
                                    [innerHTML]="customize.action.cancel || 'CANCEL'"></button>
                            <button class="btn btn-ey-default btn-ok ms-2" (click)="okayClicked();" type="button"
                                    [innerHTML]="customize.action.ok || 'OK'"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="customize.type === 'COUNT_POPUP'" class="modal-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="action-buttons">
                            <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button"
                                    [innerHTML]="customize.action || 'Proceed'" ></button>
                            <button *ngIf= "customize.noCancel && !customize.noCancel"  class="btn btn-cancel" (click)="cancelClicked();" type="button"
                                    [innerHTML]="'Cancel'"></button>
                            <button *ngIf="customize.noCancel==undefined" class="btn btn-cancel" (click)="cancelClicked();" type="button"
                                    [innerHTML]="'Cancel'"></button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>