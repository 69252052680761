
<div class="user-profile-base-container">
    <div class="user-profile-container">
        <div>
            <div class="profile-image row">
                <img class="user-profile-image" [src]="profileData.profileUrl">
            </div>

            <div class="user-profile-large-text"> {{profileData.firstName}}  {{profileData.lastName}}</div>
            <div class="user-profile-subheading"> {{profileData.company}} </div>
            <div class="user-profile-subheading" (click)="initiateMail()"> {{profileData.userEmail}} </div>
        </div>

    </div>
    <div class="row user-profile-description-container">
        <div class="col-md-2"> </div>
        <div class="col-md-8">
            <div class="row">
                <div  class="col-md-1">
                    <i class="fa fa-quote-left fa-3x col-md-1" aria-hidden="true"></i>
                </div>
                <div class="col-md-11" [innerHTML]="profileData.summary"></div>
            </div>
        </div>

        <div class="col-md-2"> </div>

    </div>
</div>
