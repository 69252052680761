import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from './model/response';
import { UtilService } from './util.service';
import { Constants } from './constant.service';
import { UserService } from '../auth/service/user.service';
import { ProgressBarOptions, ProgressBarHelper } from './../progress-bar-module/progress-bar-options';
import { ErrorHandlerHelper, AjaxErrorOptions } from './model/ajax-error-options';
import { ErrorHandlerTypes } from './model/ajax-error-handler-types';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';

const ApiUrl = environment.GROWTH_NAVIGATOR_API;

@Injectable()
export class GrowthService {
    constructor(
        private utilService: UtilService,
        private userService: UserService,
        private constants: Constants,
        private router: Router,
        private msalService: MsalService
    ) { }

    invokeGrowthAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        return Observable.create(observer => {
            this.utilService.doGet(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    this.handleError(error, errorHandler);
                });
        });
    }

    invokePostAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        var me = this;
        return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        me.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    me.handleError(error, errorHandler);
                });
        });
    }

    invokePostUploadAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions, showProgress?: boolean) {
        const user = this.userService.getCurrentUser();

        //return Observable.create(observer => {
        //    this.utilService.doUpload(ApiUrl + url, params, progressBarOptions).subscribe(
        //        (response: Response) => {
        //            if (response && response.status && (response.code === '1000')) {
        //                observer.next(response.result);
        //                observer.complete();
        //            } else {
        //                //this.utilService.showError(response.message);
        //                this.handleErrorResponse(response.code, response.message, errorHandler);
        //                Observable.throw(response.message);
        //            }
        //        }, (error: any) => {
        //            //this.utilService.errorHandler(error);
        //            this.handleError(error, errorHandler);
        //        });
        //});

        return Observable.create(observer => {
            this.utilService.doUpload(ApiUrl + url, params, progressBarOptions).subscribe((event) => {
                // Look for upload progress events.
                if (event.type === HttpEventType.UploadProgress) {
                    // This is an upload progress event. Compute and show the % done:
                    if (showProgress) {
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        observer.next({ status: 'I', value: percentDone });
                    }
                } else if (event instanceof HttpResponse) {
                    const response = event.body;
                    if (response && response.status && (response.code === '1000')) {
                        observer.next({ status: 'C', value: response.result });
                        observer.complete();
                    } else {
                        observer.next({ status: 'E', value: response.result });
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }
            });
        });
    }

    handleErrorResponse(errorCode, errorMessage, errorHandler?: AjaxErrorOptions) {

        if (errorCode == "401") {
            this.msalService.logout();
            return;
        }

        if (!errorHandler || errorHandler.handleError == undefined) {
            //this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE && errorCode == "404") {
            this.router.navigate(['general', 'error404']);
        }
    }

    handleError(error, errorHandler?: AjaxErrorOptions) {

        if (error.status == "401") {
            this.msalService.logout();
            return;
        }

        let errorMessage: any = "An error occured while processing the request";
        var parseError = false;
        try {
            if (error._body && JSON.parse(error._body).message) {
                errorMessage = JSON.parse(error._body).message;
            }
        }
        catch (e) {
            parseError = true;
        }
        if (error.status == "404" && errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE) {
            this.router.navigate(['general', 'error404']);
        }
        else if (errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else {
            this.utilService.showError(errorMessage);
        }
    }

    /************************* REFERENCE SECTION Start ****************************************/
    getGrowthDrivers(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.FOCUS.SEVEN_DRIVERS_API+'/0/false'  , {}, progressBarOptions);
    }

    getReferenceInsights(params?: any, progressBarOption?: ProgressBarOptions) {
        let parameters: any = {};
        if (!params) {
            params = {};
        }
        parameters.page = params.page || 1;
        parameters.pageSize = params.pageSize || 10;
        parameters.keyword = params.keyword || null;
        parameters.driverId = params.driverId || null;
        parameters.sortBy = params.sortBy || 1;

        return this.invokePostAPI(this.constants.REFERENCE.GET_API_REF_INSIGHTS, parameters, progressBarOption);
    }

    getInsightDetails(insightId: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_API_REF_INSIGHTS_DETAILS}/${insightId}`, {}, progressBarOption);
    }

    saveInsightDetails(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.SAVE_API_INSIGHT_DETAILS}`, params, progressBarOption);
    }

    updateCardAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.POST_API_CARD_ACTION, params, progressBarOptions);
    }

    getEcosystemList(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.GET_ECOSYSTEM, params, progressBarOptions);
    }

    getEcosystemCardDetails(cardID: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGrowthAPI(this.constants.REFERENCE.GET_ECOSYSTEM_DETAILS + '/' + user.userId + '/' + cardID, {}, progressBarOptions);

    }

    saveEcosystemcard(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.SAVE_ECOSYSTEM_CARD}`, params, progressBarOption);
    }

    getToolsAndResourcesList(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_TOOLS_AND_RESOURCES_LIST}`, params, progressBarOption);
    }
    /************************* REFERENCE SECTION End   ****************************************/

    /**************************CONTENT SECTION START ******************************************/

    /**************************CONTENT SECTION END ******************************************/

    getAllActionCards(progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_HT_ACTIONCARDS, progressBarOption);
    }

    getAllToolsAndResources(hotTopicId: any, getSelectedTools: boolean, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.CONTENT.GET_HT_TOOLS_RESOURCES + '/' + hotTopicId + '/' + getSelectedTools, {}, progressBarOptions);
    }

    getAllInsights(hotTopicId: any, getSelectedInsights: boolean, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.CONTENT.GET_HT_INSIGHTS + '/' + hotTopicId + '/' + getSelectedInsights, {}, progressBarOptions);
    }

    getActionCardList(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_ACTIONCARD_LIST}`, params, progressBarOptions);
    }

    getActionCardDetails(cardId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(`${this.constants.REFERENCE.GET_ACTIONCARD_DETAILS}/${cardId}`, {}, progressBarOptions);
    }

    getSubdriverDetails(subdriverId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.CONTENT.GET_SUBDRIVER_THEME_DETAILS + '/' + subdriverId + '/' + 2, {}, progressBarOptions);

    }
    
   saveDriverDetails(params: any, progressBarOptions?: ProgressBarOptions) {
       return this.invokePostAPI(`${this.constants.CONTENT.SAVE_SUBDRIVER_THEME_DETAILS}`, params, progressBarOptions);
    }

   getActionCardsSubDriver(subdriverId: any, progressBarOptions?: ProgressBarOptions) {
       return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_ACTIONCARDS_SUBDRIVER}/${subdriverId}`, {}, progressBarOptions);
   }

   deleteActionCard(params: any, progressBarOptions?: ProgressBarOptions) {
       return this.invokePostAPI(`${this.constants.CONTENT.DELETE_ACTIONCARDS}`, params, progressBarOptions);
   }
    editActionCard(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.EDIT_ADD_ACTIONCARD}`, params, progressBarOptions);
   }

    //getActionsCardsMapped(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(`${this.constants.REFERENCE.GET_ACTION_CARDS}`, params, progressBarOptions);
    //}

    getRelatedToolsAndResources(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_RELATED_TOOLS}`, params, progressBarOptions);
    }

    //addRelatedCards(params: any, progressBarOptions?: ProgressBarOptions) {
    //    return this.invokePostAPI(`${this.constants.REFERENCE.ADD_RELATED_CARDS}`, params, progressBarOptions);
    //}

    deleteRelatedCards(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.DELETE_RELATED_CARDS}`, params, progressBarOptions);
    }

    getToolSectionCount(toolId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(`${this.constants.REFERENCE.GET_TOOL_SECTION_COUNT}/${toolId}`, {}, progressBarOptions);
    }

    toolsFileUpload(toolId: any, params: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.REFERENCE.UPLOAD_RESOURCE_PDF}/${toolId}`, params, progressBarOptions, errorHandler);
    }

    getToolDetails(toolId: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        const user = this.userService.getCurrentUser();
        return this.invokeGrowthAPI(`${this.constants.REFERENCE.GET_TOOL_DETAILS}/${user.userId}/${toolId}`, {}, progressBarOptions, errorHandler);
    }
    saveTool(params: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostAPI(`${this.constants.REFERENCE.SAVE_TOOL}`, params, progressBarOptions, errorHandler);
    }

    /*********************************** Seven Drivers APIs Start ***************************************/
    getDriverDetails(params: any, progressBarOptions?: ProgressBarOptions){
        return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_DRIVER_DETAILS}/${params.driverId}/${params.type}`, {}, progressBarOptions);
    }

    getAllDriverInsights(progressBarOptions?: ProgressBarOptions){
        return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_ALL_INSIGHTS}/0/0`, {}, progressBarOptions);
    }

    updateDriverDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        params.userId = user.userId;
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_DRIVER_DETAILS}`, params, progressBarOptions);
    }

    getSubDriverDetails(params: any, progressBarOptions?: ProgressBarOptions){
        return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_DRIVER_DETAILS}/${params.driverId}/${params.subDriverId}`, {}, progressBarOptions);
    }

    updateSubDriverDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONNECT.UPDATE_DRIVER_DETAILS}`, params, progressBarOptions);
    }

    getThemeDetails(params: any, progressBarOptions?: ProgressBarOptions){
        return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_DRIVER_DETAILS}/${params.driverId}/${params.subDriverId}/${params.themeId}`, {}, progressBarOptions);
    }

    updateThemeDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_DRIVER_DETAILS}`, params, progressBarOptions);
    }

    deleteInsight(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.DELETE_INSIGHT}`, params, progressBarOptions);
    }

    deleteSubDriver(params: any, progressBarOptions?: ProgressBarOptions) {
        params.type = 1;
        return this.invokePostAPI(`${this.constants.CONTENT.DELETE_SUB_DRIVER}`, params, progressBarOptions);
    }

    deleteSubDriverTheme(params: any, progressBarOptions?: ProgressBarOptions) {
          return this.invokePostAPI(`${this.constants.CONTENT.DELETE_SUB_DRIVER}`, params, progressBarOptions);
    }

    deleteTheme(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.DELETE_THEME}`, params, progressBarOptions);
    }
    getDriverList(progressBarOptions?: ProgressBarOptions) {        
        return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_7DRIVERLIST}`, {}, progressBarOptions);

    }

    getSubdriverThemeDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_SUBDRIVER_THEME_DETAILS}/${params.themeId}/${params.driverId}`, {}, progressBarOptions);
    }

    getAllInsightsForThemes(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(`${this.constants.CONTENT.GET_ALL_INSIGHTS}/${params.id}/${params.type}`, {}, progressBarOptions);
    }

    saveSubdriverThemeDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_SUBDRIVER_THEME_DETAILS}`, params, progressBarOptions);
    }
    /*********************************** Seven Drivers APIs End   ***************************************/
    /*Recommended Tools API*/
    getRecommendedList(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_RECOMMENDED_LIST}`, params, progressBarOptions);
    }
    addRecommendedList(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.ADD_RECOMMENDED_LIST}`, params, progressBarOptions);
    }
    deleteRecommendedList(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.DELETE_RECOMMENDED_LIST}`, params, progressBarOptions);
    }

    getLanguages(params: any, progressBarOptions?: ProgressBarOptions) {

        return this.invokeGrowthAPI(this.constants.SYSTEM.GET_LANGUAGE_LIST, params, progressBarOptions);
    }

    saveLanguage(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.SAVE_LANGUAGE, params, progressBarOptions);
    }

    getLanguage(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGrowthAPI(this.constants.SYSTEM.GET_LANGUAGE+'/'+params.languageId, params, progressBarOptions);
    }

    languageFileUpload(languageId: any, params: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.SYSTEM.UPLOAD_LANGUAGE}/${languageId}`, params, progressBarOptions, errorHandler,true);
    }

    getTranslationReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.TRANSLATION_REPORT + '/' + params.languageId, {}, progressBarOptions);
    }
}

