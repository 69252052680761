export class MediaUploadModel {
    mediaUrl: string;
    mediaFullUrl: string;
    mediaThumbnailUrl: string;
    mediaThumbnailFullUrl: string;
    success: boolean;
    isCancelled: boolean;
    constructor() {
        this.mediaUrl = '';
        this.mediaFullUrl = '';
        this.mediaThumbnailUrl = '';
        this.mediaThumbnailFullUrl = '';
        this.success = false;
        this.isCancelled = false;
    }
}
