import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDialogService } from './modal-dialog-module/modal-dialog.service';
import { DynamicModalContainerComponent } from './modal-dialog-module/dynamic-modal-loader.component';
import { Location } from '@angular/common';
import { UtilService } from './service/util.service';
import { Router } from '@angular/router';
import { NotificationService } from './service/notification.service';
import { AuthenticationService } from "./auth/service/auth.service";
import { UserService } from "./auth/service/user.service";
import { LogService } from './service/log.service';
import { LogTypes, LogAction } from './service/model/log-models';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Constants } from './service/constant.service';

import { filter, takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    @ViewChild('dynamicModalContainer', { static: true }) dynamicModalContainer: DynamicModalContainerComponent;
  title = 'app';
  isLoggedIn: boolean;
  currentUser: any = null;
  loggedIn: boolean
  subscription: Subscription;
  private readonly _destroying$ = new Subject<void>();
  //  showLogin = false;
  constructor(
      private dialogService: ModalDialogService,
      private router: Router,
      private utilService: UtilService,
      private notificationService: NotificationService,
      //private adalService: AdalService,
      //private secretService: SecretService,
      private userService: UserService,
      private authService: AuthenticationService,
      private location: Location,
      private logTypes: LogTypes,
      private logService: LogService,
      private msalBroadcastService: MsalBroadcastService,
      private msalService: MsalService,
      private constant: Constants,

  ) {
   //   this.adalService.init(this.secretService.adalConfig);

  }
    ngOnInit() {
       //this.showLogin = environment.USE_LoGIN_DEV && (environment.environmentName == 'dev' || environment.environmentName == 'qa');
       // if (this.showLogin) {
       //     this.currentUser = this.userService.getCurrentUser();
       //     this.constant.APP.LOGIN_DEV = true;
           
       // } else {
            this.msalBroadcastService.msalSubject$
                .pipe(
                    filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                )
                .subscribe((result: EventMessage) => {
                    const payload = result.payload as AuthenticationResult;
                    this.msalService.instance.setActiveAccount(payload.account);
                });
            this.msalService.handleRedirectObservable().subscribe({
                next: (result) => console.log(result),
                error: (error) => console.log(error)
            });

            // this.subscription = this.broadcastService.subscribe("msal:loginSuccess",
            //     (payload) => {
            //         this.loggedIn = true;
            //     });

            //this.broadcastService.subscribe("msal:acquireTokenSuccess", (payload) => {
            //    console.log(payload);
            //    debugger;
            //});
            //this.subscription = this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
            //    console.log("acquire token failure " + JSON.stringify(payload))
            //});
            // this.adalService.handleWindowCallback();
            // this.adalService.getUser();
            //  const tokenStored = this.msalauthService.getCachedTokenInternal(this.msalauthService.getScopesForEndpoint(""));
            //     if (tokenStored && tokenStored.token) {
            //  if (this.msalauthService._oauthData.isAuthenticated) {
            //console.log(this.adalService.userInfo);

            // }
            this.msalBroadcastService.inProgress$
                .pipe(
                   // filter((status: InteractionStatus) => status === InteractionStatus.None),
                    takeUntil(this._destroying$)
                )
                .subscribe((status) => {
                    console.log(this.msalService.instance.getAllAccounts());
                    console.log(this.msalService.instance.getActiveAccount());
                                        //let scopes = this.msalService.instance.g
                    var tokenRequest = {
                        scopes: []
                    };
                    this.userService.inProgressMsal = true;
                    if (status == InteractionStatus.None) {
                        this.userService.inProgressMsal = false;

                        if (this.msalService.instance.getAllAccounts().length > 0) {
                            this.msalService.instance.acquireTokenSilent(tokenRequest).then(response => {
                                console.log(response);
                                this.callAuthentication(response.idToken, this.msalService.instance.getActiveAccount().username);
                            }).catch(err => {
                                console.log(err);
                                this.msalService.logoutRedirect();
                            });
                        } else {
                            var currentPa = this.location.path();
                            if (currentPa != "/general/logout") {
                                this.msalService.loginRedirect();
                            }
                        }
                    }
                })

       // }
      this.dialogService.containerElementRef = this.dynamicModalContainer;
      this.utilService.scrollToTopOnNavigation(this.router);

      //This will close all the modal dialogues when the user clicks on back button
      this.dialogService.closeDialogOnNavigationStart(this.router);

  }
  callAuthentication(token, userName){
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.currentUser = this.userService.getCurrentUser();
      if (this.currentUser == null) {
          this.authService.login(userName,token)
              .subscribe((result: any) => {
                  console.log(result);
                  if (result === true) {

                      // log the user login
                      let log = new LogAction();
                      log.action = this.logTypes.LOGGED_IN;
                      this.logService.logAction(log);

                      this.currentUser = this.userService.getCurrentUser();
                      console.log(this.currentUser);
                      if (this.currentUser == null) {
                          this.isLoggedIn = false;
                          this.router.navigate(['/general/permission-denied']);
                      }
                      else {
                          this.isLoggedIn = true;                        
                          this.router.navigate(['/dashboard']);
                      }
                  }
              });
      }         
 
}
else {
  if (this.location.path() != "/general/logout") {
    this.msalService.logoutRedirect();
  }
}
    }
  //  loginSucess() {
  //      this.currentUser = this.userService.getCurrentUser();
  //      if (this.currentUser == null) {
  //          this.isLoggedIn = false;
  //          this.router.navigate(['/general/permission-denied']);
  //      }
  //      else {
  //          this.isLoggedIn = true;
  //          this.router.navigate(['/dashboard']);
  //      }
  //  }
  ngOnDestroy() {
      // disconnect from broadcast service on component destroy
      //this.broadcastService.getMSALSubject().next(1);
      if (this.subscription) {
          this.subscription.unsubscribe();
      }
  }   }
