import { NgModule } from '@angular/core';
import { PageTransitionComponent } from './page-transition-component/page-transition.component';
import { PagePostingComponent } from './page-posting-component/page-posting.component'
import { ProgressBarService } from './progres-bar.service';

@NgModule({
    declarations: [PageTransitionComponent, PagePostingComponent],

    imports: [],
    exports: [PageTransitionComponent, PagePostingComponent],
    //Removing the provider from here and adding in App Module to maintain singleton instance of the service.
    //providers: [ProgressBarService]
})
export class ProgressBarModule { }