export class Alert {
    title?: string;
    type: AlertType;
    message: string;
}

export enum AlertType {
    Error,
    Info,
    Success,
    Warning,
    Dialog
}