import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilService } from '../../service/util.service';
import { AlertService } from '../../service/alert.service';
import { Constants } from '../../service/constant.service';
import { Response as VResponse } from './../../service/model/response';
import { UserService } from './user.service';
import { Observable,throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TimerService } from '../../service/timer.service';
import { HTTPService } from '../../service/http.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.VELOCITY_API;

@Injectable()
export class AuthenticationService {
    public token: string;

    constructor(
        private http: HttpClient,
        private utilService: UtilService,
        private userService: UserService,
        private constant: Constants,
        private alert: AlertService,
        private msalService: MsalService,
        private httpService: HTTPService,
        private timer: TimerService
    ) {
        userService.setAuthenticationService(this);
    }


    login(username: string,token:any) {

        var user = { username: username, isAdminApp:true, isPortalApp:false };
        return Observable.create(observer => {

            var userObject = this.httpService.request({
                url: AUTH_API + this.constant.AUTH.API_LOGIN,
                method: 'POST',
                headers: this.getHeader(token),
                params: user
            }).subscribe((response:any) => {
               if (response && response.status && (response.code === '1000') && response.result) {
                    this.userService.setCurrentUser(response.result);  
                    observer.next(true);
                    observer.complete();


                    
                    this.timer.setStartTime();
                    this.timer.start();
                }
                else
                {

                    this.timer.stopAndKill();
                    this.timer.clearStartTime();
                    console.log(response.message);
                    observer.next(false);
                    observer.complete();
                }

                }, (err: any) => {

                    this.timer.stopAndKill();
                    this.timer.clearStartTime();

                console.log(err); observer.next(false);
                observer.complete();
            });

        });
    }

    getHeader = (token:any) => {

        let r = (Math.random() + 1).toString(36).substring(4);
    let headers;

    if (token) {
       headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': r,
        'Authorization': 'Bearer ' + token
      })
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'rid': r
      })
    }
      
        return headers;
    }

    logout(): void {
        this.msalService.logout();
    }


    veriyEmail(email: string) {
        return Observable.create((observer) => {
            this.utilService.doPost(AUTH_API + this.constant.AUTH.API_VERIFY_EMAIL, { username: email }).subscribe(
                (response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        this.utilService.errorHandler(response.message);
                        throwError(response.message);
                    }
                },
                (error) => {
                    this.utilService.errorHandler(error);
                }
            )
        });
    }


    checkAuth() {
        return this.utilService.doPost(AUTH_API + this.constant.AUTH.API_CHECK_AUTH)
        .pipe(tap(x => {
            if (x && x.result && x.result == true) {
                this.timer.restart();
            }
            else {
                this.timer.stopAndKill();
            }
        }));
    }

    autoLogout() {
        return this.utilService.doPost(AUTH_API + this.constant.AUTH.AUTO_LOGOUT_API_URL, null).
        pipe(tap(x => {
            //if (x && x.result && x.result == true) {
            //    this.timer.restart();
            //}
            // else {
            //Nothing to do here as this will be handled by the userservice logout method
            //}
        }));
    }

    ///This method is used to extend session
    refreshToken() {
        return Observable.create(observer => {

            var userObject = this.utilService.doPost(AUTH_API + this.constant.AUTH.API_REFRESH_TOKEN).subscribe((response: VResponse) => {
                if (response && response.status && (response.code === '1000') && response.result) {

                    var currentUser = this.userService.getCurrentUser();
                    currentUser.token = response.result.token;
                    this.userService.setCurrentUser(currentUser);

                    observer.next(true);
                    observer.complete();
                    this.timer.restart();
                } else {
                    observer.next(false);
                    observer.complete();
                }

            }, (err: any) => {
                observer.next(false);
                observer.complete();
            });

        });

    }
}
