import { Component, OnInit, Input, ViewChild, Injector, Output, EventEmitter, ElementRef, TemplateRef, QueryList, ViewChildren, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Observable ,Subject} from 'rxjs';
import { IMyDpOptions } from "mydatepicker";
import { DialogTypes } from './../template-types.enum';
import { VelocityService } from '../../service/velocity.service';
import { GrowthService } from '../../service/growth.service';
import { ProgressBarHelper, ProgressBarOptions } from '../../progress-bar-module/progress-bar-options';
import { BaseComponent } from '../../component/base.component';
import { QueryService } from '../../service/query.service';
import { LogTypes, LogAction } from "../../service/model/log-models";
import { LogService } from "../../service/log.service";

@Component({
    moduleId: module.id.toString(),
    selector: "addedit-dialog",
    templateUrl: './ht-addedit-dialog.component.html',
    styleUrls: ['./ht-addedit-dialog.component.scss'],
    encapsulation:ViewEncapsulation.None
})

export class AddEditDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
    dialogType: DialogTypes = DialogTypes.DEFAULT;
    types = DialogTypes;
    dialogData: any = {};
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    @ViewChild('infoModal', { static: true }) infoModal: any;
    returnedValue: any = 'closed';

    actionCards = [];
    filterCards = [];
    filterActionCard = [];
    actionSearchStr = '';
    surveySearchStr = ''
    selectedCard: number;
    selectedTitle: string;
    actionCardDriver = 0;
    driverList = [];
    surveys = [];
    filterSurveys = [];
    selectedSurvey = [];
    toolsList = [];
    filterTools = [];
    toolsSearchStr = '';
    selectedDriver: number = 0;
    insightsList = [];
    filterInsights = [];
    insightsSearchStr = '';
    championsList = [];
    filterChampions = [];
    filterEvents = [];
    championsSearchStr = '';
    hotTopicId: number;
    insightsListDriver = [];
    filterInsightsDriver = [];
    @ViewChild('surveyAccordion') surveyAccordion: ElementRef;
    categoryName = '';
    tagName: string;
    alreadyExist = false;
    category = [];
    tag = [];
    noCategory = false;
    editCategoryId = 0;
    editTagCategoryId = 0;
    editTagId = 0;
    editTagCategoryName = '';
    editTagName = '';
    noTag = false;
    alreadyTagExists = false;
    warningMsg: string;
    catWarning: string;
    driverId: any ;
    duplicateTags = [];
    public config = {
        animated: false,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };

    // =======Hot topic Community pop up variables ====start====
    communitySearchStr: string = "";
    htCommunitiyList: any = [];
    selectedCommunity: any = [];
    // =======Hot topic Community pop up variables=====End=====

    //============Hot topic Discussion pop up variables ====start
    discussionSearchStr: string = "";
    htDiscussionCommunityId: number = 0;
    htDiscussionList: any = [];
    filterDiscussions = [];
    //============Hot topic Discussion pop up variables ====End

    //============Hot topic event pop up variables ====start
    eventSearchStr: string = "";
    public myDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'dd-mmm-yyyy',
        editableDateField: false,
        showSelectorArrow: false,
        openSelectorTopOfInput: false,
        openSelectorOnInputClick: true,
        showTodayBtn: false,
        showClearDateBtn: true
        //  disableUntil: this.getYesterday()
    };
    eventSearchDate: any;
    eventSortBy = 1;
    htEventList: any = [];
    actionSelected: boolean = false;
    //============Hot topic event pop up variables ====End
    
    constructor(private injector: Injector,
        private velocityService: VelocityService,
        private growthService: GrowthService,
        private queryService: QueryService,
        private logTypes: LogTypes,
        private logService: LogService) {
        super(injector);
        this.dialogType = this.injector.get('dialogType');
        this.dialogData = this.injector.get('dialogData');
    }

    ngOnInit() {
        switch (this.dialogType) {
            case this.types.HT_COMMUNITY:
                this.hotTopicId = this.dialogData.topicId;
                this.getAllCommunities();
                break;
            case this.types.HT_ACTIONCARD:
                this.selectedCard = this.dialogData.selectedId;
                this.selectedTitle = this.dialogData.cardTitle;
                this.driverList = this.dialogData.driverList;
                this.getAllActionCard();
                break;
            case this.types.HT_SURVEY:
                this.hotTopicId = this.dialogData.topicId;
                this.getAllSurvey();
                break;
            case this.types.HT_DISCUSSION:
                this.htDiscussionCommunityId = this.dialogData.communityId;
                this.getAllDiscussions();
                break;
            case this.types.HT_EVENT:
                this.getAllEvents();
                break;
            case this.types.HT_TOOLS:
                this.getGrowthDrivers();
                this.getAllTools(this.dialogData.hotTopicId);               
                break;
            case this.types.HT_INSIGHT:
                this.getGrowthDrivers();
                this.getAllInsights(this.dialogData.hotTopicId);
                break;
            case this.types.HT_CHAMPION:
                this.getAllChampions(this.dialogData.hotTopicId);
                break;
            case this.types.COMMUNITY_CHAMPION:
                this.getAllVelocityChampionsForACommunity(this.dialogData.communityId);
                break;
            case this.types.COMMUNITY_EVENTS:
                this.getAllCommunityEvents(this.dialogData.communityId);
                break;
            case this.types.DRIVER_ACTIONCARD:
                this.driverList = this.dialogData.driverList;
                this.getAllDriverActionCard();
                break;
            case this.types.FOP_ACTIONCARD:
                this.driverList = this.dialogData.driverList;
                this.getAllFopActionCard();
                break;
            case this.types.ADD_CATEGORY:
                this.category = this.dialogData.category;
                this.editCategoryId = this.dialogData.editCategoryId;
                if (this.editCategoryId) {
                    this.categoryName = this.category.filter(item => item.categoryId == this.editCategoryId)[0].categoryName;
                }
                break;
            case this.types.ADD_TAG:
                this.category = this.dialogData.category;
                this.tag = this.dialogData.tag;
                this.editTagCategoryId = this.dialogData.editTagCategoryId;
                if (this.editTagCategoryId) {
                    this.categoryName = this.category.filter(item => item.categoryId == this.editTagCategoryId)[0].categoryName;
                }
                this.editTagCategoryName = this.dialogData.editTagCategoryName;
                this.editTagId = this.dialogData.editTagId;
                if (this.editTagId) {
                   this.tagName = this.dialogData.editTagName;
                }
                break;
            case this.types.DRIVER_INSIGHT:    
                 this.driverId = this.dialogData.driverId;
               // this.getGrowthDrivers();
                this.getAllInsightsForDriver(this.dialogData.insightList);
                break;
            case this.types.ADD_TOOLS_TEMPLATE:
                this.getGrowthDrivers();
                if (this.dialogData.toolId)
                    this.getAllToolsAndResources(this.dialogData.toolId);
                if (this.dialogData.recommeded)
                    this.getallRecommendedTools();
                break;
            case this.types.SUBDRIVER_ACTIONCARD:
                this.driverList = this.dialogData.driverList;
                this.getAllSubDriverActionCard();
                break;

        }

    }

    ngAfterViewInit() {


    }

    cancelClicked() {
        this.returnedValue = "Cancelled";
        this.closeDialog();
    }

    okayClicked() {
        switch (this.dialogType) {
            case this.types.HT_COMMUNITY:
                this.addCommunity();
                break;
            case this.types.HT_ACTIONCARD:
                this.addActionCard();
                break;
            case this.types.HT_SURVEY:
                this.addSurvey();
                break;
            case this.types.HT_DISCUSSION:
                this.addDiscussions();
                break;
            case this.types.HT_EVENT:
                this.addEvents();
                break;
            case this.types.HT_TOOLS:
                this.addTools();
                break;
            case this.types.HT_INSIGHT:
                this.addInsights();
                break;
            case this.types.HT_CHAMPION:
                this.addChampions();
                break;
            case this.types.COMMUNITY_CHAMPION:
                this.addCommunityChampions();
                break;
            case this.types.COMMUNITY_EVENTS:
                this.addEvents();
                break;
            case this.types.DRIVER_ACTIONCARD:
                this.addDriverActionCard();
                break;
            case this.types.FOP_ACTIONCARD:
                this.addDriverActionCard();
                break;
            case this.types.DRIVER_INSIGHT:
                this.addInsightsToDriver();
                break;
            case this.types.ADD_CATEGORY:
                this.addCategory();
                break;
            case this.types.ADD_TAG:
                this.addTags();
                break;
            case this.types.ADD_TOOLS_TEMPLATE:
                this.sendToolsToParent();
                break;
            case this.types.SUBDRIVER_ACTIONCARD:
                this.addDriverActionCard();
                break;

        }
        //this.closeDialog();
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }


    openDialog() {
        this.returnedValue = "Closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.infoModal.config = this.config;
        this.infoModal.show();
        return this.observable;
    }

    closeDialog() {
        this.infoModal.hide();
    }

    getMinute(dateString: any) {
        return (new Date(dateString).getMinutes() < 10 ? '0' : '') + new Date(dateString).getMinutes();
    }

    // =========Hot topic community pop up ====start====================

    getAllCommunities() {
        let filter = { keyword: this.communitySearchStr, hotTopicId: this.hotTopicId };
        this.queryService.getAllCommunitiesForHT(filter).subscribe(
            (data: any) => {
                if (data && data.communities.length > 0) {
                    this.htCommunitiyList = data.communities;
                    this.selectedCommunity = this.htCommunitiyList[0];
                }
                else {
                    this.htCommunitiyList = [];
                }

            });
    }

    onSelectionChange(community) {
        this.selectedCommunity = Object.assign({}, this.selectedCommunity, community);
    }

    addCommunity() {
        this.returnedValue = { status: "Success", community: this.selectedCommunity };
        this.closeDialog();
    }

    //=========Hot topic community pop up ====End=======================

    // =========Hot topic discussion pop up ====start====================

    getAllDiscussions() {

        this.queryService.getAllDiscussionsForHT({ communityId: this.htDiscussionCommunityId, keyword: this.discussionSearchStr }).subscribe(
            (data: any) => {
                if (data && data.discussions.length > 0) {
                    this.htDiscussionList = data.discussions;
                    this.filterDiscussions = data.discussions;

                    this.dialogData.discussions.map(selectedItem => {
                        this.htDiscussionList.map(Item => {
                            if (Item.id == selectedItem.id) {
                                Item.isSelected = true;
                            }
                        })

                    });
                }
                else {
                    this.htDiscussionList = [];
                }

            });

    }

    filterDiscussion() {
        this.filterDiscussions = [];
        if (this.discussionSearchStr != '') {
            var filter = this.discussionSearchStr.toUpperCase();

            this.htDiscussionList.forEach((obj) => {

                if (obj.description.toUpperCase().indexOf(filter) > -1) {
                    this.filterDiscussions.push(obj);
                }
            });
        } else {
            this.filterDiscussions = this.htDiscussionList;
        }
    }

    onDiscussionSelectionChange(event, discussion) {
        discussion.isSelected = event.target.checked;
    }

    addDiscussions() {
        this.returnedValue = { status: "Success", discussions: this.htDiscussionList.filter(item => item.isSelected == true) };
        this.closeDialog();
    }

    // =========Hot topic discussion pop up ====End======================

    // =========Hot topic event pop up ====start====================

    getAllEvents() {
        let selectedDate = this.eventSearchDate != null ? this.eventSearchDate.formatted : null;
        this.velocityService.getAllEventsForHT({ date: selectedDate, keyword: this.eventSearchStr, sortBy: this.eventSortBy, hotTopicId: this.dialogData.hotTopicId }).subscribe(
            (data: any) => {
                if (data && data.events.length > 0) {
                    this.htEventList = data.events;
                    this.filterEvents = data.events;

                    this.dialogData.events.map(selectedItem => {
                        this.htEventList.map(Item => {
                            if (Item.id == selectedItem.id) {
                                Item.isSelected = true;
                            }
                        })

                    });
                }
                else {
                    this.htEventList = [];
                }

            });
    }

    getAllCommunityEvents(communityId) {
        let selectedDate = this.eventSearchDate != null ? this.eventSearchDate.formatted : null;
        let params: any = {
            communityId: communityId,
            isSelected: false,
            sortBy: this.eventSortBy
        }
        this.queryService.getCommunityEvents(params).subscribe(
            (data: any) => {
                if (data && data.length > 0) {
                    this.htEventList = data;
                    this.filterEvents = data;

                }
                else {
                    this.htEventList = [];
                }

            });
    }

    onEventSelectionChange(e, event) {
        event.isSelected = e.target.checked;
    }

    addEvents() {
        this.returnedValue = { status: "Success", events: this.htEventList.filter(item => item.isSelected == true) };
        this.closeDialog();
    }

    hasMoreThanOneDay(startDate: any, endDate: any) {
        return (new Date(endDate) > new Date(startDate));
    }

    // =========Hot topic event pop up ====End======================


    getAllActionCard() {
        this.growthService.getAllActionCards().subscribe(
            (data: any) => {
                this.actionCards = data;
                this.filterCards = data;
                console.log(data);
            });

    }
    actionCardSearch() {
        this.filterCards = [];
        if (this.actionSearchStr != '') {
            var filter = this.actionSearchStr.toUpperCase();

            this.actionCards.forEach((obj) => {
                console.log(obj);
                if (obj.title.toUpperCase().indexOf(filter) > -1) {
                    this.filterCards.push(obj);
                }
                if (obj.actionCardNumber.indexOf(+this.actionSearchStr) > -1) {
                    this.filterCards.push(obj);
                }
            });
        } else {
            this.filterCards = this.actionCards;
        }

        if (this.actionCardDriver != 0) {
            this.filterCards = this.filterCards.filter((x: any) => x.driverId == this.actionCardDriver);

        }
        console.log(this.filterCards);
    }

    actionCardFopSearch() {
        this.filterCards = [];
        if (this.actionSearchStr != '') {
            var filter = this.actionSearchStr.toUpperCase();

            this.actionCards.forEach((obj) => {
                console.log(obj);
                if (obj.title.toUpperCase().indexOf(filter) > -1) {
                    this.filterCards.push(obj);
                }
                let actionCardNumber = obj.actionCardNumber.toString();
                if (actionCardNumber.indexOf(+this.actionSearchStr) > -1) {
                    this.filterCards.push(obj);
                }
            });
        } else {
            this.filterCards = this.actionCards;
        }

        if (this.actionCardDriver != 0) {
            this.filterCards = this.filterCards.filter((x: any) => x.driverId == this.actionCardDriver);

        }
        console.log(this.filterCards);
    }

    surveySearch() {
        this.filterSurveys = [];
        if (this.surveySearchStr != '') {
            var filter = this.surveySearchStr.toUpperCase();

            this.surveys.forEach((obj) => {

                if (obj.question.toUpperCase().indexOf(filter) > -1) {
                    this.filterSurveys.push(obj);
                } else {
                    for (var option of obj.options) {

                        if (option.answerText.toUpperCase().indexOf(filter) > -1) {
                            this.filterSurveys.push(obj);
                            break;
                        }
                    }
                }
            });
        } else {
            this.filterSurveys = this.surveys;
        }
    }
    getAllSurvey() {
        this.velocityService.getSurveys(this.hotTopicId, false).subscribe(
            (data: any) => {
                this.selectedSurvey = data.filter(s => s.isSelected).map(s => s.id);;
                console.log(data);
                console.log(this.selectedSurvey);

                this.surveys = data;
                this.filterSurveys = data;

            });
    }

    changeCard(id, title) {
        this.selectedCard = id;
        this.selectedTitle = title;
    }
    addActionCard() {
        this.returnedValue = {
            id: this.selectedCard,
            title: this.selectedTitle
        };
        this.closeDialog();
    }
    addSurvey() {
        this.returnedValue = this.selectedSurvey;
        this.closeDialog();

    }
    changeSurvey(surveyId) {
        let index = this.selectedSurvey.indexOf(surveyId);
        if (this.selectedSurvey.indexOf(surveyId) > -1) {
            this.selectedSurvey.splice(index, 1);
        } else {
            this.selectedSurvey.push(surveyId);
        }
        console.log(this.selectedSurvey);

    }
    isChkedSurvey(surveyId) {
        return (this.selectedSurvey.indexOf(surveyId) > -1);
    }
    openSurvey(id) {
        console.log(id);
        console.log(this.surveyAccordion.nativeElement.querySelector('#survey_' + id));
        let accordian = this.surveyAccordion.nativeElement.querySelector('#survey_' + id);
        accordian.classList.toggle("active");
        let panel = accordian.querySelector('.choices');
        if (panel.style.height) {
            panel.style.height = null;
        } else {
            panel.style.height = panel.scrollHeight + "px";
        }


    }


    // =========Hot topic Tools, Insights and Velocity Champion ====start======================

    getGrowthDrivers() {
        this.growthService.getGrowthDrivers().subscribe(
            (drivers: any) => {
                this.driverList = drivers;
                console.log(this.driverList);
            }
        )
    }

    getAllTools(hotTopicId) {
        this.growthService.getAllToolsAndResources(hotTopicId, false).subscribe(
            (data: any) => {
                this.toolsList = data;
                this.filterTools = data;
                console.log(data);
            });
    }

    addTools() {

        let selectedTools = this.toolsList.filter((item: any) => { return item.isSelected == true; });

        let params = {
            topicId: this.dialogData.hotTopicId,
            tools:
                selectedTools.map(item => { return item.toolId })
        };

        this.velocityService.addHotTopicTools(params).subscribe(
            (data: any) => {

                if (data) {
                    this.returnedValue = selectedTools;
                    this.closeDialog();
                }

            });
    }


    toolsSearch() {
        this.filterTools = [];
        if (this.toolsSearchStr != '') {
            var filter = this.toolsSearchStr.toUpperCase();

            this.toolsList.forEach((obj) => {

                if (obj.title.toUpperCase().indexOf(filter) > -1 || obj.description.toUpperCase().indexOf(filter) > -1) {
                    this.filterTools.push(obj);
                }
            });
        } else {
            this.filterTools = this.toolsList;
        }

        if (this.selectedDriver != 0) {
            this.filterTools = this.filterTools.filter((x: any) => x.driverIds.indexOf(this.selectedDriver) != -1);

        }

    }

    getAllInsights(hotTopicId) {
        if (hotTopicId) {
            this.growthService.getAllInsights(hotTopicId, false).subscribe(
                (data: any) => {
                    if (data) {
                        this.insightsList = data.lstInsightCardItem;
                        this.filterInsights = data.lstInsightCardItem;
                        console.log(data);
                    }
                });
        } else {
            this.growthService.getAllDriverInsights().subscribe(
                (data: any) => {
                    if (data && data.length) {
                        this.insightsList = data;
                        this.filterInsights = data;
                        data.forEach((obj) => {
                            this.filterInsightsDriver.forEach((item) => {
                                if (obj.cardId == item.cardId) {
                                    item.isSelected = true;
                                }
                            });
                        });
                    }
                });
        }
    }

    addInsights() {

        let selectedInsights = this.insightsList.filter((item: any) => { return item.isSelected == true; });

        if (this.dialogData.hotTopicId === 0) {
            this.returnedValue = selectedInsights;
            this.closeDialog();
        } else {
            let params = {
                topicId: this.dialogData.hotTopicId,
                insights:
                    selectedInsights.map(item => { return item.insightId })
            };

            this.velocityService.addHotTopicInsights(params).subscribe(
                (data: any) => {
                    if (data) {
                        this.returnedValue = selectedInsights;
                        this.closeDialog();
                    }

                }
            );
        }
    }


    insightsSearch() {
        this.filterInsights = [];
        if (this.insightsSearchStr != '') {
            var filter = this.insightsSearchStr.toUpperCase();

            this.insightsList.forEach((obj) => {

                if (obj.title.toUpperCase().indexOf(filter) > -1) {
                    this.filterInsights.push(obj);
                }
            });
        } else {
            this.filterInsights = this.insightsList;
        }

        if (this.selectedDriver != 0) {
            this.filterInsights = this.filterInsights.filter((x: any) => x.driverIds.indexOf(this.selectedDriver) != -1);

        }

    }

    getAllChampions(hotTopicId) {
        this.velocityService.getHotTopicChampions(hotTopicId, false).subscribe(
            (data: any) => {
                this.championsList = data;
                this.filterChampions = data;
                console.log(data);
            });
    }

    getAllVelocityChampionsForACommunity(communityId) {
        let params: any = {
            communityId: communityId,
            isSelected: false,
            keyword: ""
        }

        this.queryService.getCommunityChampions(params).subscribe(
            (data: any) => {
                this.championsList = data;
                this.filterChampions = data;
                console.log(data);
            });
    }



    addChampions() {

        let selectedChampions = this.championsList.filter((item: any) => { return item.isSelected == true; });

        let params = {
            topicId: this.dialogData.hotTopicId,
            champions:
                selectedChampions.map(item => { return item.userId })
        };

        this.velocityService.addHotTopicChampions(params).subscribe(
            (data: any) => {

                if (data) {
                    this.returnedValue = selectedChampions;
                    this.closeDialog();
                }

            });
    }

    addCommunityChampions() {
        let selectedChampions = this.championsList.filter((item: any) => { return item.isSelected == true; });
        this.returnedValue = { status: "Success", community: selectedChampions };
        this.closeDialog();
    }


    searchCommunityEventsByDateAndKeyWord() {
       
        this.filterEvents = [];
        if (this.eventSearchStr != '') {
            var filter = this.eventSearchStr.toUpperCase();

            this.htEventList.forEach((obj) => {

                if (obj.title.toUpperCase().indexOf(filter) > -1) {
                    this.filterEvents.push(obj);
                }
            });
        } else {
            this.filterEvents = this.htEventList;
        }

        if (this.eventSearchDate != null) {
            let tmpEvents = this.filterEvents;
            this.filterEvents = [];
            var newDate = this.eventSearchDate.date.month + "/" + this.eventSearchDate.date.day + "/" + this.eventSearchDate.date.year;
            var filter1 = new Date(newDate).getTime();
            tmpEvents.forEach((obj) => {

                let dateToBeChecked = new Date(obj.eventStartDate).getTime();
                if (dateToBeChecked == filter1) {
                    this.filterEvents.push(obj);
                }

            });
        }

    }

    sortCommunityEvents() {
        let tempEvents: any[] = [];
        for (let item of this.filterEvents) {
            tempEvents.push(item);
        }
        this.filterEvents = [];
        let selectedDate = this.eventSearchDate != null ? this.eventSearchDate.formatted : null;
        let params: any = {
            communityId: this.dialogData.communityId,
            isSelected: false,
            sortBy: this.eventSortBy,
            date: selectedDate,
            keyword: this.eventSearchStr
        }
        this.queryService.getCommunityEvents(params).subscribe(
            (data: any) => {
                if (data && data.length > 0) {
                    this.htEventList = data;
                    this.filterEvents = data;

                    tempEvents.forEach(selectedItem => {
                        this.filterEvents.map(Item => {
                            console.log("Selected Item--",selectedItem.eventId + "--Item--",Item.eventId)
                            if (Item.eventId == selectedItem.eventId && selectedItem.isSelected) {
                                Item.isSelected = true;
                            }
                        })

                    });
                }
                else {
                    this.htEventList = [];
                }

            });

    }


    championsSearch() {

        this.filterChampions = [];
        if (this.championsSearchStr != '') {
            var filter = this.championsSearchStr.toUpperCase();

            this.championsList.forEach((obj) => {

                if (obj.name.toUpperCase().indexOf(filter) > -1 || obj.location.toUpperCase().indexOf(filter) > -1) {
                    this.filterChampions.push(obj);
                }
            });
        } else {
            this.filterChampions = this.championsList;
        }
    }

    communityChampionsSearch() {

        this.filterChampions = [];
        if (this.championsSearchStr != '') {
            var filter = this.championsSearchStr.toUpperCase();

            this.championsList.forEach((obj) => {

                if (obj.firstName.toUpperCase().indexOf(filter) > -1 || obj.location.toUpperCase().indexOf(filter) > -1) {
                    this.filterChampions.push(obj);
                }
            });
        } else {
            this.filterChampions = this.championsList;
        }
    }


    insertInlog() {
        // log Hot topic modified
        let log = new LogAction();
        log.topicId = this.hotTopicId;
        log.action = this.logTypes.HOT_TOPIC_MODIFIED;
        this.logService.logAction(log);
    }

    // =========Hot topic Tools, Insights and Velocity Champion ====End======================


    //--- Driver Action Card ------//

    addDriverActionCard() {
        let selectedActionCards = this.actionCards.filter((item: any) => { return item.isSelected == true; });
        this.returnedValue = { status: "Success", cards: selectedActionCards };
        this.closeDialog();
    }


    getAllDriverActionCard() {
        this.growthService.getAllActionCards().subscribe(
            (data: any) => {
                this.actionCards = data;
                this.filterCards = data;
                console.log(data);

                this.dialogData.selectedCards.map(selectedItem => {
                    console.log("Selected Action Card")
                    this.actionCards.map(Item => {
                        if (Item.cardId == selectedItem.cardId) {
                            Item.isSelected = true;
                        }
                    })

                });
            });

    }

    getAllFopActionCard() {
        this.velocityService.getActions().subscribe(
            (data: any) => {
                this.actionCards = data;
                this.filterCards = data;
                console.log(data);
                let actionSelected = false;
                this.dialogData.selectedCards.map(selectedItem => {
                    console.log("Selected Action Card")
                    this.actionCards.map(Item => {
                        if (Item.cardId == selectedItem.cardId) {
                            Item.isSelected = true;
                            actionSelected = true;
                        }
                    })

                });
                if (actionSelected) {
                    this.actionSelected = true;
                } else {
                    this.actionSelected = false;
                }
            });

    }

    onActionCardSelected(e, card) {
        card.isSelected = e.target.checked;

        let actionSelected = false;
        this.actionCards.map(Item => {
            if (Item.isSelected) {
                actionSelected = true;
            }
        });
        if (actionSelected) {
            this.actionSelected = true;
        } else {
            this.actionSelected = false;
        }
    }
    //----Driver Action Card End ----//

    //Insight popup for Driver pages Start

    getAllInsightsForDriver(list) {
      //  debugger;
        let params = {
            id: 0,
            type: 0
        }
        this.growthService.getAllInsightsForThemes(params).subscribe(
            (data: any) => {
                let dId = parseInt(this.driverId); 
                this.insightsListDriver = data.filter((x: any) => x.driverIds.indexOf(dId) != -1);
                this.filterInsightsDriver = data.filter((x: any) => x.driverIds.indexOf(dId) != -1);
                if (list.length > 0) {
                    list.forEach((obj) => {
                        this.filterInsightsDriver.forEach((item) => {
                            if ((obj.cardId == item.cardId) || (obj.id == item.cardId)) {
                                item.isSelected = true;
                            }
                        });
                    });
                }
                console.log(data);
            });
    }

    insightsSearchDriver() {
        debugger;
        this.filterInsightsDriver = [];
        if (this.insightsSearchStr != '') {
            var filter = this.insightsSearchStr.toUpperCase();

            this.insightsListDriver.forEach((obj) => {

                if (obj.bodyFormatted.toUpperCase().indexOf(filter) > -1) {
                    this.filterInsightsDriver.push(obj);
                }
            });
        } else {
            this.filterInsightsDriver = this.insightsListDriver;
        }
       
        if (this.selectedDriver != 0) {
            this.filterInsightsDriver = this.filterInsightsDriver.filter((x: any) => x.driverIds.indexOf(this.selectedDriver) != -1);

        }

    }

    addInsightsToDriver() {
        
        let selectedInsights = this.insightsListDriver.filter((item: any) => {
            item.id = item.cardId;
            item.description = item.bodyFormatted;
            return item.isSelected == true;
        });
        this.returnedValue = selectedInsights;
        console.log('selected: ', this.returnedValue)
        this.closeDialog();

    }
    toChkExists() {
        var upperCaseCategory = this.category.map(a => a.categoryName.toUpperCase());        

        if (upperCaseCategory.indexOf(this.categoryName.toUpperCase()) > -1) {
            this.alreadyExist = true;
            this.catWarning = 'Category already exists.';
            return false;
        } 

        this.alreadyExist = false;
        this.catWarning = '';
        return true;
        

    }
    toChkTagExists(chkString : string) {
        
        var upperCaseCategory = this.tag.map(a => a.tagName.toUpperCase());

        if (upperCaseCategory.indexOf(chkString.toUpperCase()) > -1) {
            this.alreadyTagExists = true;
            
            return false;
        }

        this.alreadyTagExists = false;
        
        return true;
    }
    addCategory() {
        if (this.toChkExists()) {
            if (this.categoryName.trim().length > 0) {
                this.alreadyExist = false;
                if (/^[a-zA-Z0-9\s]+$/.test(this.categoryName)) {
                    this.alreadyExist = false;
                    this.returnedValue = { status: "Success", categoryName: this.categoryName };
                    this.closeDialog();
                } else {
                    this.alreadyExist = true;
                    this.catWarning = 'Only characters allowed.';
                }
            } else {
                this.alreadyExist = true;
                this.catWarning = 'Please enter category name.';
            }
        } 
    }

    addTags() {
        this.warningMsg = '';
        this.alreadyTagExists = false;
        this.duplicateTags = [];
        if (this.editTagId) {
            if (this.tagName && this.tagName.trim().length > 0 && this.tagName !== '') {
                this.noTag = false;
                if (this.toChkTagExists(this.tagName)) {
                    this.alreadyTagExists = false;
                    if (/^[a-zA-Z0-9\s]+$/.test(this.tagName)) {
                        this.alreadyTagExists = false;
                        var tags = [];
                        tags.push({
                            tagId: this.editTagId,
                            tagName: this.tagName
                        })
                        this.returnedValue = { status: "Success", categoryName: this.editTagCategoryId, tagName: tags };
                        this.closeDialog();
                    } else {
                        this.alreadyTagExists = true;
                        this.warningMsg = 'Special characters not allowed';
                        
                    }
                } else {
                    this.alreadyTagExists = true;
                    this.warningMsg = 'This tag already exists.Please enter another name';
                }
            } else {
                this.noTag = true;
                this.alreadyTagExists = false;
            }
           
        } else {
            if (this.categoryName) {
                this.noCategory = false;
                if (this.tagName && this.tagName.trim().length > 0 && this.tagName !== '') {
                    this.noTag = false;
                    if (/^[a-zA-Z0-9\s,]+$/.test(this.tagName)) {
                        this.alreadyTagExists = false;
                        var tags = [];
                        let list = this.tagName.replace(/\n/g, ",").split(",");
                        list.forEach((obj) => {
                            if (this.toChkTagExists(obj)) {
                                this.alreadyTagExists = false;
                                if (obj.trim().length > 0) {
                                    tags.push({
                                        tagId: 0,
                                        tagName: obj
                                    })
                                }
                            } else {
                                this.duplicateTags.push(obj);
                            }
                        });
                        if (this.duplicateTags && this.duplicateTags.length > 0) {
                            this.alreadyTagExists = true;
                            this.warningMsg = ' tags already exists.Please enter another name';
                        } else {
                            this.alreadyTagExists = false;
                            this.warningMsg = '';
                            if (tags.length > 0) {
                                this.returnedValue = { status: "Success", categoryName: this.categoryName, tagName: tags };
                                this.closeDialog();
                            }
                        }
                    } else {
                        this.alreadyTagExists = true;
                        this.warningMsg = 'Please enter characters only.'
                    }
                } else {
                    this.noTag = true;
                    this.alreadyTagExists = false;
                }
             } else {
                this.noCategory = true;
            }
        }
        
    }

    //--------------- Related Tools and Resources Dialog  --------------//

    getAllToolsAndResources(toolId) {
        let params = { toolId: toolId, isSelected: false, keyword: '' };
        this.growthService.getRelatedToolsAndResources(params).subscribe(
            (data: any) => {
                if (data) {
                    this.toolsList = data;
                    this.filterTools = data;
                }
            });
    }
    getallRecommendedTools() {
        let params = {
            isSelected: 0
        };

        this.growthService.getRecommendedList(params).subscribe(
            (data: any) => {
                if (data) {
                    this.toolsList = data;
                    this.filterTools = data;
                }
            });
    }
     sendToolsToParent() {

        let selectedTools = this.toolsList.filter((item: any) => { return item.isSelected == true; });
        this.returnedValue = selectedTools;
        this.closeDialog();
    }
    //---------------        ------------ //


    //---------- Sub Driver action card  --------- //

     getAllSubDriverActionCard() {
         //Needs to change the API 
         this.growthService.getActionCardsSubDriver(this.dialogData.subdriverId).subscribe(
             (data: any) => {
                 this.actionCards = data;
                 this.filterCards = data;
                 console.log(data);

                 this.dialogData.selectedCards.map(selectedItem => {
                     console.log("Selected Action Card")
                     this.actionCards.map(Item => {
                         if (Item.cardId == selectedItem.cardId) {
                             Item.isSelected = true;
                         }
                     })

                 });
             });

     }


     subDriverActionCardSearch() {
         this.filterCards = [];
         if (this.actionSearchStr != '') {
             var filter = this.actionSearchStr.toUpperCase();

             this.actionCards.forEach((obj) => {

                 if (obj.title.toUpperCase().indexOf(filter) > -1) {
                     this.filterCards.push(obj);
                 }
                 if (obj.actionCardNumber.indexOf(+this.actionSearchStr) > -1) {
                     this.filterCards.push(obj);
                 }
             });
         } else {
             this.filterCards = this.actionCards;
         }
                 
         console.log(this.filterCards);
     }
}
  