
    <div class="modal fade coc-modal"
     bsModal #codeOfConductModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content" eyv-update-panel="termsAndCondition">

            <div class="floating-dialog-close pull-right text-end" (click)="closeDialog()">
                <img class="whiteClose" src="../../../assets/images/close-white-btn.png" />&nbsp;close
            </div>

            <div class="modal-header" [innerHTML]="titleText">
            </div>
            <div *ngIf="policyType == 1" class="tcUploaded"><span class="uploadedLabel">{{'Uploaded date'}}</span>&nbsp;{{uploadedDate | datex:'dd-MMM-yyyy hh:mm a'}}</div>
            <div class="modal-body" id="code-of-conduct">
                <p [innerHTML]="codeOfConduct"></p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="action-buttons">
                        <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button"
                                >OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    

