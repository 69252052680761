import { Component, OnInit, Injector, Input } from '@angular/core';

import { UserService } from './../auth/service/user.service';





@Component({
    template: ""
})
export class BaseComponent implements OnInit {
   
    protected currentUser: any; 
    private _userService: UserService;

    
    constructor(private _injector: Injector) {
        
        
        this._userService = _injector.get(UserService);
        this.currentUser = this._userService.getCurrentUser();

    }

    ngOnInit() {
    }
}
