// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environmentName: 'dev',

    //Old Classic Evnvironment

    //VELOCITY_API: 'https://velocityapi.vel-dev.ey.com/api/',
    //GROWTH_NAVIGATOR_API: 'https://velocitygnapi.vel-dev.ey.com/api/',
    //QUERY_ENGINE_API: 'https://velocityqeapi.vel-dev.ey.com/api/',
    //AZURE_TENANT: 'https://login.microsoftonline.com/eygs.onmicrosoft.com/',
    //AZURE_CLIENTID: '8cd8f909-3480-4d00-82eb-8df749cc7b8c',
    //DOMAIN_HINT: 'ey.com',
    //USE_LoGIN_DEV: true

     //New Fabric Environement
    // VELOCITY_API: 'https://localhost:7034/api/',
    // GROWTH_NAVIGATOR_API: 'https://localhost:7136/api/',
    // QUERY_ENGINE_API: 'https://localhost:7106/api/',
    // VELOCITY_API: 'https://genaimm-velocity.azurewebsites.net/api/',
    // GROWTH_NAVIGATOR_API: 'https://genaimm-growthnavigator.azurewebsites.net/api/',
    // QUERY_ENGINE_API: 'https://genaimm-queryengine.azurewebsites.net/api/',
    VELOCITY_API: 'https://velocityapi-genai-mm-dev.ey.com/api/',
    GROWTH_NAVIGATOR_API: 'https://gnapi-genai-mm-dev.ey.com/api/',
    QUERY_ENGINE_API: 'https://qeapi-genai-mm-dev.ey.com/api/',
    // AZURE_TENANT: "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c/",
    // AZURE_CLIENTID: "8412e9be-127e-446a-9954-0fc1cc519f72",
    // DOMAIN_HINT: 'ey.com',
    AZURE_TENANT: 'https://login.microsoftonline.com/eyqa.onmicrosoft.com',
    AZURE_CLIENTID: '5033089c-c73f-417f-9c0d-36cb395a8cdf',
    DOMAIN_HINT: 'eyqa.onmicrosoft.com',
    USE_LoGIN_DEV: true

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
