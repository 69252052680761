import { Injectable } from '@angular/core';
import { ProgressBarTypes } from './progress-bar-types.enum';
import { ProgressBarOptions } from './progress-bar-options';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


@Injectable()
export class ProgressBarService {

    pageTransitionProgressBars: AjaxRequests;
    pagePostingProgressBars: AjaxRequests;

    subject: Subject<any> = new Subject();
    updatePanelObservable: Observable<any> = this.subject.asObservable();


    constructor() {
        this.pageTransitionProgressBars = { runningRequests: 0, completedRequests: 0 };
        this.pagePostingProgressBars = { runningRequests: 0, completedRequests: 0 };
    }

    enable(progressBarOptions: ProgressBarOptions) {
        /**
     * Call this method to show progress bar
     */
        
        var progressBarType = progressBarOptions.progressBarType;
        switch (progressBarType) {
            case ProgressBarTypes.PAGE_TRANSITION: {
                this.pageTransitionProgressBars.runningRequests = this.pageTransitionProgressBars.runningRequests + 1;
                this.showHidePageTransitionPreloader();
                break;
            }
            case ProgressBarTypes.PAGE_POSTING: {
                this.pagePostingProgressBars.runningRequests = this.pagePostingProgressBars.runningRequests + 1;
                this.showHidePageLoadingOverlay();
                break;
            }
        }
    }

    disable(progressBarOptions: ProgressBarOptions) {
        /**
     * Call this method to hide progress bar
     */

        
        var progressBarType = progressBarOptions.progressBarType;
        switch (progressBarType) {
            case ProgressBarTypes.PAGE_TRANSITION: {
                this.pageTransitionProgressBars.completedRequests = this.pageTransitionProgressBars.completedRequests + 1;
                if (this.pageTransitionProgressBars.completedRequests >= this.pageTransitionProgressBars.runningRequests) {
                    this.pageTransitionProgressBars.completedRequests = this.pageTransitionProgressBars.runningRequests = 0;
                }
                this.showHidePageTransitionPreloader();
                break;
            }
            case ProgressBarTypes.PAGE_POSTING: {
                this.pagePostingProgressBars.completedRequests = this.pagePostingProgressBars.completedRequests + 1;
                if (this.pagePostingProgressBars.completedRequests >= this.pagePostingProgressBars.runningRequests) {
                    this.pagePostingProgressBars.completedRequests = this.pagePostingProgressBars.runningRequests = 0;
                }
                this.showHidePageLoadingOverlay();
                break;
            }
        }
    }

    showHidePageTransitionPreloader() {
        if (this.pageTransitionProgressBars.runningRequests > this.pageTransitionProgressBars.completedRequests) {
            document.querySelector("body").classList.add("page-loading");
        } else {
            document.querySelector("body").classList.remove("page-loading");
        }
    }

    showHidePageLoadingOverlay() {
        if (this.pagePostingProgressBars.runningRequests > this.pagePostingProgressBars.completedRequests) {
            document.querySelector("body").classList.add("page-posting");
        } else {
            document.querySelector("body").classList.remove("page-posting");
        }
    }
    

}

class AjaxRequests {
    runningRequests: number;
    completedRequests: number;
}
