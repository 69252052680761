<div class="ey-velocity-header" *ngIf="isLoggedIn" #header>
    <nav class="navbar navbar-expand-lg navbar-inverse p-0">
        <div class="container-fluid nav-cont" style="width:100%">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header mt-1">
                    <button type="button" class="navbar-toggler collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand ey-brand" [routerLink]="['dashboard']">
                        <img class="ey-brand-logo" src="../../../assets/logo/ey-logo.png">
                        <div class="lable ey-label-cont"><p class="ey-label">{{brandName}}</p></div>
                    </a>
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                    <ul class="nav navbar-nav navbar-right ms-auto">
                        <li routerLinkActive='active' *ngIf="isLoggedIn" class="nav-item">
                            <a class="nav-link"><div><span class="fa fa-plus fa-lg plus"></span></div></a>
                        </li>

                        <li routerLinkActive='active' *ngIf="isLoggedIn" class="nav-item">
                            <a class="nav-link"><div><span class="fa fa-bell-o fa-lg bell" aria-hidden="true"></span></div></a>
                        </li>

                        <li class="nav-item"><div class="welcome-text nav-link">Welcome {{userName}}</div></li>
                        
                        <li routerLinkActive='active' *ngIf="isLoggedIn" class="nav-item">
                            <a class="nav-link"><div (click)="logout()"><span class="fa fa-power-off fa-lg" aria-hidden="true"></span></div></a>
                        </li>
                    </ul>
                </div><!-- /.navbar-collapse -->

        </div><!-- /.container-fluid -->
    </nav>
</div>



