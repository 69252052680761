import {
    Component,
    Input,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    NgZone,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef
} from '@angular/core';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';
import { Observable, Subject } from 'rxjs';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';



@Component({
    moduleId: module.id.toString(),
    selector: "code-of-conduct-dialog",
    templateUrl: './code-of-conduct-dialogue.component.html',
    styleUrls: ['./code-of-conduct-dialogue.component.scss']
})

export class CodeOfConductDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    htmlURL: string;
    policyType: number;
    headingString: string;
    uploadedDate: string;
    SiteContents: any = {};
    observable: Observable<any>;
    codeOfConduct: string = "";
    subject: any;
    data: any;
    titleText: string = "";
    @Output("closeEvent") componentEvent: any = new EventEmitter()
    @ViewChild('container') container: ElementRef;

    constructor(
        private injector: Injector,
        private mScrollbarService: MalihuScrollbarService,
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocity: VelocityService) {

        this.htmlURL = this.injector.get('htmlURL');
        this.policyType = this.injector.get('policyType');
        this.headingString = this.injector.get('headingString');
        this.uploadedDate = this.injector.get('uploadedDate');
        this.getCodeOfConduct();
    }

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };


    @ViewChild('codeOfConductModal',{static:true}) termsModal: any;
    returnedValue: any = {};

    ngOnInit() {
        this.setTitle(this.headingString); 

    }
    setTitle(params : any) {
        switch (this.policyType) {
            case 1:
                this.titleText = "Terms & Conditions - " + params;
                break;
            case 2:                    
                this.titleText = "Privacy Policy"
                break;
            case 3:
                this.titleText = "Privacy Registration"
                break;
            case 4:                
                this.titleText = "Code of conduct"
                break;
            case 5:
                this.titleText = "Why EY"
                break;
            case 6:
                this.titleText = "About EY Velocity"
                break;

        }
    }
    
    ngAfterViewInit() {
        const vm = this;
        this.mScrollbarService.initScrollbar('#code-of-conduct', {
            axis: 'y', theme: 'minimal-dark',
            callbacks: {
                onTotalScroll: () => {
                   // this.updateAccpet();
                }
            }
        });
    }
    ngOnDestroy() {
        this.mScrollbarService.destroy('#code-of-conduct');
    }
    getCodeOfConduct() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("termsAndCondition");
        let params: any;
        params = {
            policyType: this.policyType,
            versionId: '6',
            fileName: this.htmlURL
        };
        this.velocity.getPlatformFileContent(params).subscribe(
            (result: any) => {
                console.log('result:', result);

                this.codeOfConduct = result;
            }
        );
    }
    
    cancelClicked() {
        this.returnedValue = "no";
        this.closeDialog();
    }
    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }
    okayClicked() {
       // this.returnedValue.action = 'yes';
        this.closeDialog();
    }
    openDialog() {
        this.returnedValue.action = 'no';
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.termsModal.config = this.config;
        this.termsModal.show();
        return this.observable;
    }

    public closeDialog() {
        
        this.returnedValue.action = 'yes';
        this.termsModal.hide();
    }
}


