import { NgModule } from '@angular/core';
// import { ProgressbarModule, ModalModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//import { BaseRequestOptions, HttpModule } from '@angular/http';
//import { Ng2DragDropModule } from 'ng2-drag-drop';
import { TabsModule } from 'ngx-bootstrap/tabs';
//import { BaseComponent } from './base.component';
import { AlertComponent } from './alert/alert.component';
import { PipeModule } from '../pipe/pipe.module';
//import { NeedHelpComponent } from './need-help/need-help.component';
//import { EYVTooltip } from './eyv-tooltip-directive/eyv-tooltip.directive';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
//import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import { ErrorDialogComponent } from './dialog/dialog.component';
//import { ProgressBarModule as EYVProgressBarModule } from '../progress-bar-module/progress-bar.module';
import { AppHeaderComponent } from "./app-header/app-header.component";
import { AppNavigationComponent } from "./app-navigation/app-navigation.component";
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SectionHeaderComponent } from "./section-header/section-header.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
// //import { QuillEditorModule } from 'ngx-quill-editor';
import Quill from 'quill'

import { RichEditorComponent } from './rich-editor/rich-editor.component';
import { PeoplePickerComponent } from './people-picker/people-picker.component';
import { TimeoutHandlerComponent } from './timeout-dialog/timeout-handler.component';
import { PaginationAlphabeticalComponent } from './pagination-alphabetical/pagination-alphabetical.component';
import { SwitchComponent } from './switch/switch.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
    declarations: [
        AppNavigationComponent,
        AppHeaderComponent,
        AlertComponent,
        //BaseComponent,
        //NeedHelpComponent,
        //EYVTooltip,
        //FilterDropdownComponent,
        ErrorDialogComponent,
        UserProfileComponent,
        SectionHeaderComponent,
        BreadcrumbComponent,
        RichEditorComponent,
        PeoplePickerComponent,
        TimeoutHandlerComponent,
        PaginationAlphabeticalComponent,
        SwitchComponent
    ],
    imports: [
       // HttpModule,
        FormsModule,
        AlertModule.forRoot(),
        ModalModule.forRoot(),
        //ProgressbarModule.forRoot(),
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        //EYVProgressBarModule,
        //Ng2DragDropModule.forRoot(),
        PipeModule,
        //NgxChartsModule,
        TabsModule.forRoot(),
        // //QuillEditorModule,
        // NgxQuillModule,
        QuillModule.forRoot()

    ],
    exports: [
        AppHeaderComponent,
        AppNavigationComponent,
        //BaseComponent,
        AlertComponent,
        //HttpModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        //Ng2DragDropModule,
        PipeModule,
        //NeedHelpComponent,
        //EYVTooltip,
        //NgxChartsModule,
        //ProgressbarModule,
        //FilterDropdownComponent,
        ErrorDialogComponent,
        UserProfileComponent,
        SectionHeaderComponent,
        BreadcrumbComponent,
        RichEditorComponent,
        PeoplePickerComponent,
        TimeoutHandlerComponent,
        PaginationAlphabeticalComponent,
        SwitchComponent
    ],
    providers: []
})
export class ComponentModule { }
