import { Injectable, Inject } from '@angular/core';
import { UserService } from '../auth/service/user.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { map, catchError, retry } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable()
export class HTTPService {
    constructor(private http: HttpClient, private userService: UserService) { }

    getHeader = () => {
        var currentUser = this.userService.getCurrentUser();
        let headers;
        if (currentUser && currentUser.token) {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'rid': this.getRandom(),
            'Authorization': 'Bearer ' + currentUser.token
          })
        } else {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'rid': this.getRandom()
          })
    
        }
       
        return headers;
    }

    getHeaderForUpload = () => {
        const currentUser = this.userService.getCurrentUser();
        let headers;
        if (currentUser && currentUser.token) {
          headers = new HttpHeaders({
            'rid': this.getRandom(),
            'Authorization': 'Bearer ' + currentUser.token
          })
        } else {
          headers = new HttpHeaders({
            'rid': this.getRandom()
          })
    
        }
        return headers;
    }
    post(req: any): Observable<Response> {

        let headers = { headers: req.headers ? req.headers : this.getHeader() };
    
    
        return this.http.post<Response>(req.url, JSON.stringify(req.params), headers)
          .pipe(
            retry(1),
            catchError(this.handleError)
          )
    
    
      }
    request = (req: any) => {
        const requestOptions = {
            headers: req.headers ? req.headers : this.getHeader(),
            body: JSON.stringify(req.params)
          };
          let headers = { headers: req.headers ? req.headers : this.getHeader() };
      
          if (req.method == 'POST') {
            return this.http.post<Response>(req.url, JSON.stringify(req.params), headers)
              .pipe(
                retry(1),
                catchError(this.handleError)
              )
      
          } else {
            return this.http.get<Response>(req.url, headers)
              .pipe(
                retry(1),
                catchError(this.handleError)
              )
      
          }
    }

    // uploadRequest = (req: any) => {
    //     const requestOptions = {
    //         headers: this.getHeaderForUpload(),
    //       };
    //       return this.http.post<Response>(req.url, req.params, requestOptions)
    //         .pipe(
    //           retry(1),
    //           catchError(this.handleError)
    //         )
       
    //     // return this.http.request(new Request(requestOptions))
    //     //     .map((res: Response) => res.json());
    // }

    uploadRequest = (req: any) => {
      // const requestOptions = new RequestOptions({
      //     method: 'POST',
      //     url: req.url,
      //     headers: this.getHeaderForUpload(),
      //     body: req.params
      
      const uploadReq = new HttpRequest('POST', req.url, req.params, { headers: this.getHeaderForUpload(), reportProgress: true, responseType: 'json' });

      return this.http.request(uploadReq);
      // return this.http.request(new Request(requestOptions))
      //     .map((res: Response) => res.json());
    }

    getRandom() {
        return (Math.random() + 1).toString(36).substring(4);
    }

    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //window.alert(errorMessage);
        return throwError(errorMessage);
      }

}
