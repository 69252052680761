import { Component, Input, Output, EventEmitter, ViewChildren, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { UserLookupService } from '../../service/user-lookup.service';
import { QueryService } from "../../service/query.service";
//import { ToolsResourcesListComponent } from 'src/app/reference/tools-resources/tools-resources-list/tools-resources-list.component';

@Component({
    moduleId: module.id.toString(),
    selector: "people-picker",
    templateUrl:'./people-picker.component.html',
    styleUrls: ['./people-picker.component.css']

})

export class PeoplePickerComponent implements OnInit, AfterViewInit{

    constructor(
        private userLookupService: UserLookupService,
        private queryService: QueryService
    ) { }
        
    @Input() multiSelect: boolean;
    @Input() selectedUsers: any[] = [];
    @Input() maxSelectionCount: number = 1;
    @Input() minimumChars: number = 2;
    @Input() source: string = "AD";

    @ViewChild("nameInput",{static:true}) nameInput: any;


    @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

    promises: any[] = [];
    errorMessage: string = "";
    showPreLoader: boolean = false;
    showEmptyText: boolean = false;       

    public inputText: string = '';
    public filteredUsers: any[] = [];   

   

    ngOnInit() {
        
        if (this.multiSelect === false) {
            this.maxSelectionCount = 1;
        }
        console.log(this.source);
    }


    ngAfterViewInit(): void {
        
        //this.focusInput();
    }

    onPaste() {
        this.filter();
    }

    onKeyPress(ev: any) {        
        if (ev.keyCode === 13) {
            ev.preventDefault();            
            return;
        } else if (ev.keyCode === 32 || ev.keyCode === 8 || ev.keyCode === 46 || (ev.keyCode >= 48 && ev.keyCode <= 90) || (ev.keyCode >= 96 && ev.keyCode <= 111) || (ev.keyCode >= 186 && ev.keyCode <= 192) || (ev.keyCode >= 219 && ev.keyCode <= 222)) {
            this.filter();
        }
    }

    onKeyDown(ev: any) {
        if (ev.keyCode === 13) {
            ev.preventDefault();
            return;
        }
    }

    filter() {
        this.showEmptyText = false;
        if (this.inputText === "" || this.inputText.length < this.minimumChars) {
            this.filteredUsers = [];
            this.clearPromises();
        } else {            
            this.filteredUsers = [];
            this.clearPromises();

            if (this.source == 'CO') {
                console.log("------------ Inside community owner  type ahead ----------");
                let parms = { keyword: this.inputText };
                var promise = this.queryService.searchCommunityOwners(parms).subscribe(
                    results => this.filterData(results),
                    error => this.errorMessage = <any>error
                ); 

            }
            if (this.source == 'CM') { // CM is search for Community Moderator.
                let parms = { keyword: this.inputText };
                var promise = this.queryService.searchCommunityModerators(parms).subscribe(
                    results => this.filterData(results),
                    error => this.errorMessage = <any>error
                );

            }
            else {
                var promise = this.userLookupService.searchUsers(this.source, this.inputText).subscribe(
                    results => this.filterData(results),
                    error => this.errorMessage = <any>error
                );
            }


            
            this.promises.push(promise);

            //remove
            //this.filterData(this.dummyUsers)

            this.showPreLoader = true;
        }
    }

    filterData(res: any) {

        this.filteredUsers = [];

        if (this.source == 'CO') {
            for (let user of res) {
                this.filteredUsers.push({
                    "FirstName": user.GivenName || "",
                    "LastName": user.Surname || "",
                    "Email": user.email,
                    "DisplayName": user.userName,
                    "ownerId": user.id
                });
            }
        }
        else if (this.source == 'CM') {
            for (let user of res) {
                this.filteredUsers.push({
                    "DisplayName": user.userName,
                    "Email": user.email,
                    "ModeratorId": user.id
                });
            }
        }
        else {
            for (let user of res) {
                this.filteredUsers.push({
                    "FirstName": user.GivenName,
                    "LastName": user.Surname,
                    "Email": user.UserPrincipalName,
                    "DisplayName": user.DisplayName,
                    "JobTitle": user.JobTitle || ""
                });
            }
        }
        
        //this.filteredUsers = res.filter(
        //    (item: any) => (
        //        (item.GivenName.toLowerCase().indexOf(this.inputText.toLowerCase()) === 0)
        //        || (item.Surname.toLowerCase().indexOf(this.inputText.toLowerCase()) === 0)
        //        || (item.UserPrincipalName.toLowerCase().indexOf(this.inputText.toLowerCase()) === 0)
        //    )
        //);
        

        if (this.filteredUsers.length === 0) {
            this.showEmptyText = true;
        }

        this.clearPromises();
    }

    selectUser(user: any) {
        this.inputText = "";
        this.filteredUsers = [];
        if (this.source == 'CM') {
            let isUserPresent = false;
            this.selectedUsers.forEach(x =>
                                            {
                                                if (x.ModeratorId == user.ModeratorId) {
                                                    isUserPresent = true;
                                                }
                                            }
                                        );
            if (!isUserPresent) { // Avoid duplicate users in the selected box.
                this.selectedUsers.push(user); 
            }
        }
        else{
            this.selectedUsers.push(user);
        }

        
        this.onChange.emit(this.selectedUsers);
    }

    deleteSelectedUser(user: any) {
        this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);        
        this.onChange.emit(this.selectedUsers);
        this.focusInput();
    }

    clearInput() {
        this.inputText = "";
        this.filteredUsers = [];
        this.showEmptyText = false;
    }

    focusInput() {
        /*console.log("focus");*/
        var isTextboxVisible = ((this.multiSelect === false) ? (this.selectedUsers.length < 1) : (this.selectedUsers.length < this.maxSelectionCount));
        
        if (isTextboxVisible ) {
            //window.setTimeout(1000, function () {
            //    this.nameInput.nativeElement.focus();
            //})

            var txtSearchInput = document.getElementById("txtSearchInput");
            if (txtSearchInput) {
                txtSearchInput.focus();
            }
        }
    }

    clearPromises() {
        for (let promise of this.promises) {            
            promise.unsubscribe();
        }
        this.promises = [];
        this.showPreLoader = false;
    }    
}