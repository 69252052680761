import { Component, Input, ViewEncapsulation, ViewChild, Injector } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VelocityService } from "../../service/velocity.service";

@Component({
    moduleId: module.id.toString(),
    selector: "tag-dialog",
    templateUrl: './tag-dialog-component.html',
    styleUrls:['./tag-dialog.component.scss'],
    encapsulation:ViewEncapsulation.None
})

export class TagDialogComponent {
    title: string = "";
    message: string = "";
    showCloseButton: boolean = true;
    @ViewChild('tagModal',{static:true}) tagModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: any = [];

    public config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };

    lstTags = [];
    lstSelectedTags = [];
    keyword: string = "";
    tagIDList = [];

    constructor(private injector: Injector,
        private velocityService: VelocityService) {
        //this.lstSelectedTags = this.injector.get('tags') != null ? this.injector.get('tags').slice() : [];
        this.tagIDList = this.injector.get('tags') != null ? this.injector.get('tags').slice() : [];


        // this.message = this.injector.get('message');
        // this.showCloseButton = this.injector.get('showCloseButton');
    }

    ngOnInit() {

        this.getTagList("", false);

    }

    private getTagList(keyword, isSearch: boolean) {
        let self = this;

        this.velocityService.getTagsList(keyword).subscribe(
            (data: any) => {
                if (data) {
                    self.lstTags = data;

                    if (!isSearch) {
                        self.lstSelectedTags = this.getSelectedTagList(self.tagIDList);
                        self.formatSelectedTagsList();
                    }

                    self.removeSelectedTags();
                    self.formatListTags();

                }
            }
        );

    }

    private getSelectedTagList(tagIds: any) {

        let lst = []

        this.lstTags.map((item) => {
            item.tags.map((tagItem) => {
                if (tagIds.some(tagId => tagItem.tagId == tagId)) {
                    lst.push(tagItem);
                }
            });

        });

        return lst;

    }

    private removeSelectedTags() {

        let self = this;

        this.lstTags.map((item) => {
            item.tags = item.tags.filter((tagItem) => {
                return this.lstSelectedTags.filter((selectedItem) => {
                    return selectedItem.tagId == tagItem.tagId
                }).length == 0;

            });

        });

    }

    private formatListTags() {
        this.lstTags.map((item) => {
            item.tags.map((tagItem) => {
                tagItem.selected = false
            });
        });
    }

    private formatSelectedTagsList() {
        this.lstSelectedTags.map((tagItem) => tagItem.selected = false);
    }

    onSearch() {
        this.getTagList(this.keyword, true);
    }

    onMoveToSelectedTagList() {

        let self = this;

        this.lstTags.map((item) => {

            let selectedTags: any[] = item.tags.filter((tagItem) => tagItem.selected == true);

            if (selectedTags.length > 0) {

                this.lstSelectedTags.push(...selectedTags);

                item.tags = item.tags.filter((tagItem) => tagItem.selected == false);
            }

        });

        this.formatSelectedTagsList();
    }

    onMoveToTagList() {

        let selectedTags = this.lstSelectedTags.filter(item => item.selected == true);

        this.lstTags.map((item) => {

            let addTags = selectedTags.filter((tagItem) => tagItem.categoryId == item.categoryId);

            if (addTags.length > 0) {
                item.tags.push(...addTags);
            }

        });

        this.lstSelectedTags = this.lstSelectedTags.filter(item => item.selected == false);

        this.formatListTags();

    }


    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }
    }

    okayClicked() {

        this.lstSelectedTags.map((item) => {
            item.id = item.tagId;
            item.text = item.tagName;
            item.name = item.tagName;
        })

        this.returnedValue = this.lstSelectedTags;
        this.closeDialog();
    }

    cancelClicked() {
        this.returnedValue = null;
        this.closeDialog();
    }

    openDialog() {
        this.returnedValue = this.lstSelectedTags;
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.tagModal.config = this.config;
        this.tagModal.show();
        return this.observable;
    }

    private closeDialog() {
        this.tagModal.hide();
    }
}