import {Injectable, Inject, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from './model/response';
import { UtilService } from './util.service';
import { Constants } from './constant.service';
import { ProgressBarOptions, ProgressBarHelper } from './../progress-bar-module/progress-bar-options';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';

const ApiUrl = environment.VELOCITY_API;

@Injectable()
export class LookUpService {
    progressBar: ProgressBarOptions;
    constructor (
        private utilService: UtilService,
        private constants: Constants
    ) { }

    invokeAPI(params: any) {
        return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + this.constants.GENERAL.GET_LOOK_UP_DATA, params, ProgressBarHelper.DefaultPost).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    }else {
                        this.utilService.showError(response.message);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    this.utilService.errorHandler(error);
                });
            });
    }
    /* Register Screens: Get Started */
    getGenders() {
        const params: any = {
            getGender: true
        }
        return this.invokeAPI(params);
    }
    /* Register Screens: Company Info */
    getNumberOfEmployee() {
        const params: any = {
            getNumberOfEmployee: true
        }
        return this.invokeAPI(params);
    }
    getCountry() {
        const params: any = {
            getCountry: true
        }
        return this.invokeAPI(params);
    }
    getIndustry() {
        const params: any = {
            getIndustry: true
        }
        return this.invokeAPI(params);
    }
    getCompanyType() {
        const params: any = {
            getCompanyType: true
        }
        return this.invokeAPI(params);
    }
    getFundingStage() {
        const params: any = {
            getFundingStage: true
        }
        return this.invokeAPI(params);
    }
    getMilestone() {
        const params: any = {
            getMilestone: true
        }
        return this.invokeAPI(params);
    }

    getKnewAboutVelocity() {
        const params: any = {
            getKnowingVeolcity: true
        }
        return this.invokeAPI(params);
    }

    getDriverList() {
        const params: any = {
            getDriverList: true
        }
        return this.invokeAPI(params);
    }
    getPaymentType() {
        const params: any = {
            getPaymentType : true
        }
        return this.invokeAPI(params);
    }
}

