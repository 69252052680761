<div class="modal fade people-picker-modal"
     
     bsModal #peoplePickerModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)" (onShown)="onShown($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    Search and add a user
                </h5>
            </div>
            <div class="modal-body">
                
                    <people-picker [selectedUsers]="selectedUsers" (onChange)="onSelectionChange($event)" [source]="source" #peoplePicker></people-picker>
                   
                   
                

            </div>
            <div class="modal-footer" style="justify-content: flex-start;">
                <div class="row">
                    <div class="col-md-12 ps-0">
                        <div class="action-buttons">
                            <button class="btn btn-cancel me-1" (click)="cancelClicked();" type="button">Cancel</button>
                            <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button" [disabled]="this.selectedUsers.length==0">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>