<!--<modal #alertModal modalClass="eyb-alert-modal" [closeOnEscape]='true' [closeOnOutsideClick]='false' (onClose)="onModalClosed();" [hideCloseButton] ="!showCloseButton">
    <modal-header>
        <h1>{{title}}</h1>
    </modal-header>
    <modal-content>
        <div class="row">
            <div class="col-md-12">{{message}}</div>
        </div>
    </modal-content>
    <modal-footer>
        <div class="row">
            <div class="col-md-6">
                <button class="btn btn-eyb-default" (click)="okayClicked();" type="button">Ok</button>
            </div>
        </div>
    </modal-footer>
</modal>-->


<div class="modal fade ey-dialog-modal" bsModal #alertModal="bs-modal" 
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog" style="max-width: 50%;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{title}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeDialog()" style="border: none;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!--<div class="modal-body">
                {{message}}
            </div>-->
            <div class="modal-body" [innerHtml]="message">
            </div>
            <div class="modal-footer" style="justify-content:flex-start;">
                <div class="row">
                    <div class="col-md-6 ps-0">
                        <button class="btn btn-ey-default" (click)="okayClicked();" type="button">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>