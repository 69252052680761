import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class DataShareService {
  
    actionSaved = new BehaviorSubject<string>("action");
    pageNoMember: number = 1;
    pageNoVelocity: number = 1;

    constructor() { }
    public privateData: any = {};

    getSharedData(key: string): any {
        return this.privateData[key];
    }

    setSharedData(key: string, data: any): void {
        if (key && data) {
            if (this.privateData[key] == undefined) {
                this.privateData[key] = data;
            }
        }
    }

   



}
