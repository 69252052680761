import {ErrorHandlerTypes } from './ajax-error-handler-types';

export class AjaxErrorOptions {
    handleError:boolean = true;
    action: ErrorHandlerTypes = ErrorHandlerTypes.SHOW_ALERT_MESSAGE;
}

export class ErrorHandlerHelper {
    static readonly Default: AjaxErrorOptions = new AjaxErrorOptions();
    static readonly DoNothing: AjaxErrorOptions = { handleError: false, action: ErrorHandlerTypes.NO_ACTION };
    static readonly ShowDialog: AjaxErrorOptions = { handleError: true, action: ErrorHandlerTypes.SHOW_DIALOG_MESSAGE };
    static readonly RedirectToNotFoundPage: AjaxErrorOptions = { handleError: true, action: ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE };
    static readonly ShowLoginDialog: AjaxErrorOptions = { handleError: true, action: ErrorHandlerTypes.SHOW_LOGIN_DIALOG };
    static readonly RedirectToLoginPage: AjaxErrorOptions = { handleError: true, action: ErrorHandlerTypes.REDIRECT_TO_LOGIN_PAGE };
}