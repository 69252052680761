<div class="modal fade ey-info-modal deactivate-addedit-dialog" bsModal #infoModal="bs-modal" tabindex="-1" role="dialog"
    (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="deactivate-add-popup">
                    <div class="add-actionCard-driver">
                        <div class="action-header">
                            <span>Deactivate {{pageType}}</span>
                        </div>
                        <div class="action-description">
                            <span style="font-size:14px;">The following actions are still found to be mapped only to this {{pageType}}. Please select the actions and map to appropriate {{pageType}} and then deactivate.</span>
                        </div>
                        <div class="row" style="padding-left:20px;padding-right:10px;">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4 ps-0">
                                        <span class="deactivate-title">Select Action Card</span>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="pull-right">
                                            <input class="searchtext" placeholder="Keywords" [(ngModel)]="actionSearchStr"
                                                   (keyup.enter)="actionCardSearch()" />
                                            <button class="btn btn-search btn-white" (click)="actionCardSearch()">
                                                <span class="glyphicon glyphicon-search search-icon searchIconWhite"></span>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row cards">
                                    <div class="col-md-4 ps-0" *ngFor="let card of filterCards">
                                        <div class="row card flex-row">
                                            <div class="col-md-3 px-2">
                                                <div class="checkbox">
                                                    <label>
                                                        <input type="checkbox" (change)="onActionCardSelected($event, card)"
                                                               [(ngModel)]="card.isSelected">
                                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                                    </label>
                                                </div>
                                                <div class="actionNumber">{{card.actionCardNumber}}</div>
                                            </div>
                                            <div class="col-md-9">
                                                <div class="title">{{card.title}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" style="color: #000000;" *ngIf="!actionCards.length">
                                        <br />
                                        All Action Cards are now re-mapped
                                    </div>
                                    <div class="col-lg-12" style="color: #000000;" *ngIf="actionCards.length > 0 && !filterCards.length">
                                        <br />
                                        No action card found for your search
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map-action">
                    <span class="map-action-text"> Select {{pageType}} to map actions</span>
                    <div>
                        <div class="select-position">
                            <select class="form-select" [(ngModel)]="toItemId" [disabled]="!actionCards.length">
                                <option value='0'>Select</option>
                                <ng-container *ngFor="let item of data">
                                    <option *ngIf="item.id != dialogData.data.id && item.isActive" [ngValue]="item.id">{{ item.name }}</option>
                                </ng-container>
                            </select>
                        </div>
                        <button type="button" class="btn btn-ey-default-gray btn-font-16 btn-position" [disabled]="(!actionSelected || toItemId == 0)"
                                (click)="mapAction()">
                            Map Action
                        </button>
                    </div>
                </div>
                <div class="bottom-btns">
                    <button type="button" class="btn btn-ey-default-gray btn-font-16" [disabled]="!actionCards.length"
                            (click)="cancelClicked()" style="margin-right: 20px;">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-ey-default btn-danger" [disabled]="actionCards.length"
                            (click)="okayClicked()">
                        Deactivate
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>