
<div class="modal fade ey-userprofile-modal" bsModal #userProfileModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <!--<div *ngIf="showLoaderForVideo">Loading</div>-->
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    <img class="whiteClose" src="../../../assets/images/close-white-btn.png" />close
                </div>
                <div class="video-modal-content">
                    <user-profile-component [userID]="userID"></user-profile-component>
                </div>


            </div>
        </div>
    </div>
