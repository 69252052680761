
<div class="modal fade edit-discussion-modal" bsModal #editModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    x&nbsp;close
                </div>
                <p class="heading" *ngIf="!isAnswer">Edit Discussion</p>
                <p class="heading" *ngIf="isAnswer">Edit Thread</p>
[]
                <hr />
                <div class="contents" *ngIf="!isAnswer">
                    <p class="sub-heading">Title<span class="error" *ngIf="showTitleError">&#33;&nbsp;Please enter the value to continue</span></p>
                    <textarea [(ngModel)]="title" 
                              maxlength="200" 
                              (input)="valueChange($event.target.value,'title')"></textarea>

                    <p class="sub-heading">Disussion<span class="error" *ngIf="showDescriptionError">&#33;&nbsp;Please enter the value to continue</span></p>
                    <textarea [(ngModel)]="description" 
                              maxlength="500"
                              (input)="valueChange($event.target.value,'desc')"></textarea>

                    <p class="sub-heading">Edit Community Tags<span class="communityNote">&nbsp;Note - Discussion can be moved to one of the below enabled Communities only. User has access to these Communities based on their Membership</span></p>
                    <div class="community-container">
                        <div class="row">
                            <div class="col-xs-6 community-items" *ngFor="let community of communities" (click)="community.hasAccess && communityId = community.id;valueChange(communityId,'community')" [ngClass]="{'noAccess': !community.hasAccess}">
                                <div *ngIf="community.id != communityId" class="round"></div>
                                <img  *ngIf="community.id == communityId" src="../../../assets/images/tick-icon.png" />
                                <div class="title">{{community.title}}</div>
                            </div>
                        </div>
                    </div>
                    <p class="sub-heading">Edit Topics Tags</p>
                    <div class="list-container">
                        <div class="left">
                            <div class="list" custom-scroll>
                                <div class="items" *ngFor="let topic of getTopics(false)"
                                     (click)="tempSelect(topic.id,true)"
                                     [ngClass]="{'selected':checkSelectedCategory(topic.id,true)}">
                                    {{topic.name}}
                                </div>
                            </div>
                        </div>
                        <div class="center arrows">
                            <div (click)="moveToOtherColumn(true);valueChange(true,'tag')"><i class="fa fa-angle-right"></i></div>
                            <div (click)="moveToOtherColumn(false);valueChange(false,'tag')"><i class="fa fa-angle-left"></i></div>
                        </div>
                        <div class="right">
                            <div class="list" custom-scroll>
                                <div class="items" 
                                     *ngFor="let topic of getTopics(true)" 
                                     (click)="tempSelect(topic.id,false)"
                                     [ngClass]="{'selected':checkSelectedCategory(topic.id,false)}">
                                    {{topic.name}}
                                </div>
                            </div>
                        </div>

                    </div>

                    <p class="sub-heading">Comments for edit discussion<span class="error" *ngIf="showCommentError">&#33;&nbsp;Please enter the value to continue</span></p>
                    <textarea [(ngModel)]="comments" 
                              maxlength="400"
                              (input)="valueChange($event.target.value,'comment')"></textarea>
                    <div class="buttons">
                        <button class="btn btn-ey-default-gray" (click)="cancel()" type="button">Cancel</button>
                        <button class="btn btn-ey-default" (click)="edit()" type="button" [disabled]="!titleChange && !descChange && !communityChange && !tagsChange">Submit</button>
                    </div>
                    <div class="clear-fix"></div>
                </div>
                <div class="contents" *ngIf="isAnswer">
                    <p class="sub-heading"><span class="error" *ngIf="showDescriptionError">&#33;&nbsp;Please enter the value to continue</span></p>
                        <textarea [(ngModel)]="description" 
                                  maxlength="500"
                                  (input)="valueChange($event.target.value,'desc')"></textarea>
                    <p class="sub-heading">Comments for edit thread<span class="error" *ngIf="showCommentError">&#33;&nbsp;Please enter the value to continue</span></p>
                    <textarea 
                              maxlength="400"
                              [(ngModel)]="comments" (input)="valueChange($event.target.value,'comment')"></textarea>
                    <div class="buttons">
                        <button class="btn btn-ey-default-gray" (click)="cancel()" type="button">Cancel</button>
                        <button class="btn btn-ey-default" (click)="editThread()" type="button" [disabled]="!descChange">Submit</button>
                    </div>
                    <div class="clear-fix"></div>
                </div>
                </div>
        </div>
    </div>
</div>