export enum TemplateTypes {
    DEFAULT = 1,
    ASSESSMENT_COMPLETED = 2,
    SIMPLE = 3,
    MATURITY_LEVEL = 4,
    INSIGHT_POPUP=5,
    SUB_DRIVER_ASSESSMENT_COMPLETED = 6,
    THEME_ASSESSMENT_COMPLETED = 7,
    COPYRIGHT_POPUP=8,
    NEED_HELP_POPUP = 9,
    GROWTH_PLAN_NO_ACTION = 10,
    IMAGE_VIDEO_POPUP = 11,
    INSIGHT_PREVIEW_POPUP = 12,
    ACCOUNT_DELETE=13
}

export enum DialogTypes {
    DEFAULT=0,
    HT_COMMUNITY = 1,
    HT_DISCUSSION = 2,
    HT_EVENT = 3,
    HT_TOOLS = 4,
    HT_INSIGHT = 5,
    HT_CHAMPION = 6,
    HT_ACTIONCARD = 7,
    HT_SURVEY = 8,
    COMMUNITY_CHAMPION = 9,
    COMMUNITY_EVENTS = 10,
    DRIVER_ACTIONCARD = 11,
    DRIVER_INSIGHT = 12,
    ADD_TAG = 13,
    ADD_CATEGORY = 14,
    ADD_TOOLS_TEMPLATE = 15,
    SUBDRIVER_ACTIONCARD = 16,
    FOP_ACTIONCARD = 17
}