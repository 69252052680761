import { Component, Input, ComponentFactoryResolver, ViewContainerRef, ViewChild, Injector } from '@angular/core';
import { OkCancelDialogComponent } from './ok-cancel-dialog-component/ok-cancel-dialog.component';
import { AlertDialogComponent } from './alert-dialog-component/alert-dialog.component';
import { InfoDialogComponent } from './info-dialog-component/info-dialog.component';
// import { FormDialogComponent } from './form-dialog-component/form-dialog.component';
// import { DeleteDialogDiscussionComponent } from './delete-dialog-discussion/delete-dialog-discussion.component';
// import { CodeOfConductDialogComponent } from './code-of-conduct-component/code-of-conduct-dialogue.component';


 import { ComponentData } from './component-data';
// import { EditDiscussionComponent } from './edit-discussion-component/edit-discussion.component';
// import { UserProfileDialogComponent } from "./user-profile-dialog-component/user-profile-dialog-component";
// import { PeoplePickerDialogComponent } from './people-picker-dialog/people-picker-dialog.component';
// import { TagDialogComponent } from "./tag-dialog-component/tag-dialog-component";
// import { AddEditDialogComponent } from './ht-addedit-dialog-component/ht-addedit-dialog.component';
import { ConfirmDeleteDialogComponent } from './confirm_delete/confirm-delete-dialog.component';
// import { MediaUploadDialogComponent } from './media-upload-dialog.component/media-upload-dialog.component';
// import { SubDriverDialogComponent } from './sub-driver-dialog/sub-driver-dialog.component';
import { HtmlUploadDialogComponent } from './html-upload-dialog.component/html-upload-dialog.component';
import { DeactivateComponent } from './deactivate-component/deactivate.component';

@Component({
    selector: 'dynamic-modal-container',
    entryComponents: [
        OkCancelDialogComponent,
        AlertDialogComponent,
        InfoDialogComponent,
        // DeleteDialogDiscussionComponent,
        // FormDialogComponent,
        // EditDiscussionComponent,
        // UserProfileDialogComponent,
        // TagDialogComponent,
        // PeoplePickerDialogComponent,
        // AddEditDialogComponent,
        ConfirmDeleteDialogComponent,
        // MediaUploadDialogComponent,
        // SubDriverDialogComponent,
        HtmlUploadDialogComponent,
        // CodeOfConductDialogComponent,
         DeactivateComponent
    ],
    template: `<div #dvDynamicModalContainer></div>`
})
export class DynamicModalContainerComponent {
    currentComponent: any = null;
    @ViewChild('dvDynamicModalContainer', { read: ViewContainerRef }) dvDynamicModalContainer: ViewContainerRef;

    constructor(private resolver: ComponentFactoryResolver) { }

    componentData: ComponentData;

    ngOnInit() { }

    showDialog(componentData: ComponentData) {
        this.componentData = componentData;

        let inputProviders = Object.keys(this.componentData.componentInput).map((inputName) => { return { provide: inputName, useValue: this.componentData.componentInput[inputName] }; });
       // let resolvedInputs = ReflectiveInjector.resolve(inputProviders);
        //let injector = ReflectiveInjector.fromResolvedProviders(resolvedInputs, this.dvDynamicModalContainer.parentInjector);
        let injector = Injector.create({
            providers: inputProviders,
            parent: this.dvDynamicModalContainer.parentInjector
        });
        let factory = this.resolver.resolveComponentFactory(this.componentData.component);
        let component = factory.create(injector);
        this.dvDynamicModalContainer.insert(component.hostView);
        let createdComponentInstance: any = component.instance;
        if (this.currentComponent) {
            this.currentComponent.destroy();
        }
        this.currentComponent = component;

        //calls the component method to show the dialog
        let returnedObservable: any = createdComponentInstance.openDialog();
        if (createdComponentInstance.componentEvent) {
            createdComponentInstance.componentEvent.subscribe((e: string) => this.componentEvent(e));
        }
        return returnedObservable;
    }

    closeDialog() {
        if (this.currentComponent && this.currentComponent.instance && this.currentComponent.instance.closeDialog) {
            this.currentComponent.instance.closeDialog();
        }
    }

    componentEvent(event: any) {
        if (event.value === "closed") {
            if (this.currentComponent) {
                this.currentComponent.destroy();
            }
        }

        if (event.value === "destroyed") {
            if (this.currentComponent) {
                //TODO - Need to find a way todestroy the component when the modal is closed
                //this.currentComponent.destroy();
            }
        }
    }

    unsubscribe() {
        if (this.currentComponent && this.currentComponent.instance && this.currentComponent.instance.observable) {
            if (this.currentComponent.instance.observable && this.currentComponent.instance.observable.source && this.currentComponent.instance.observable.source.observers && this.currentComponent.instance.observable.source.observers.length) {
                for (let observer of this.currentComponent.instance.observable.source.observers) {
                    if (observer.unsubscribe) {
                        observer.unsubscribe();
                    }
                }
            }
            this.closeDialog();
        }
    }

    ngOnDestroy() {
        if (this.currentComponent) {
            this.currentComponent.destroy();
            this.currentComponent = null;
        }
    }
}