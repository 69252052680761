import { Injectable, Inject } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { Response } from './model/response';
import { UtilService } from './util.service';
import { Constants } from './constant.service';
import { UserService } from '../auth/service/user.service';
import { ProgressBarOptions } from './../progress-bar-module/progress-bar-options';
import { SaveActionTask } from './model/save-action-task';
import { SaveRagCost } from './model/save-rag-cost';
import { AjaxErrorOptions, ErrorHandlerHelper } from './model/ajax-error-options';
import { ErrorHandlerTypes } from './model/ajax-error-handler-types';
import { Router } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';

const ApiUrl = environment.VELOCITY_API;

@Injectable()
export class VelocityService {
    constructor(
        private utilService: UtilService,
        private userService: UserService,
        private constants: Constants,
        private router: Router,
        private msalService: MsalService
    ) { }

    invokeGetAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {

        return Observable.create(observer => {
            this.utilService.doGet(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    this.handleError(error, errorHandler);
                    //this.utilService.errorHandler(error);
                });
        });
    }

    invokePostAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        const user = this.userService.getCurrentUser();
        //TODO Uncomment after login flow 
        //if (user) {
        //   params.userId = user.userId;
        //}
        return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    invokePostUploadAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions, showProgress?: boolean) {
        const user = this.userService.getCurrentUser();
        return Observable.create(observer => {
            this.utilService.doUpload(ApiUrl + url, params, progressBarOptions).subscribe((event) => {
                // Look for upload progress events.
                if (event.type == 1) {
                    // This is an upload progress event. Compute and show the % done:
                    if (showProgress) {
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        observer.next({ status: 'I', value: percentDone });
                    }
                } else if (event instanceof HttpResponse) {
                    const response = event.body;
                    if (response && response.status && (response.code === '1000')) {
                        observer.next({ status: 'C', value: response.result });
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }
            });
        });
    }

    handleErrorResponse(errorCode, errorMessage, errorHandler?: AjaxErrorOptions) {
        if (errorCode == "401") {
            this.msalService.logout();
            return;
        }

        if (!errorHandler || errorHandler.handleError == undefined) {
            this.utilService.showError(errorMessage);
        }
        //else if (errorCode == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
        //    this.adalService.logOut();
        //}
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE && errorCode == "404") {
            this.router.navigate(['general', 'error404']);
        }
    }

    handleError(error, errorHandler?: AjaxErrorOptions) {

        if (error.status == "401") {
            this.msalService.logout();
            return;
        }

        let errorMessage: any = "An error occured while processing the request";
        var parseError = false;
        try {
            if (error._body && JSON.parse(error._body).message) {
                errorMessage = JSON.parse(error._body).message;
            }
        }
        catch (e) {
            parseError = true;
        }
        if (!errorHandler || errorHandler.handleError == undefined) {
            this.utilService.showError(errorMessage);
        }
        //else if (error.status == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
        //    this.adalService.logOut();
        //}
        else if (error.status == "404" && errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE) {
            this.router.navigate(['general', 'error404']);
        }
        else if (errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else {
            this.utilService.showError(errorMessage);
        }
    }

    BanTrustUser(params: any, progressBarOptions?: ProgressBarOptions) {
        params.userId = 1;
        return this.invokePostAPI(this.constants.COMMUNITY.BAN_TRUST_USER, params, progressBarOptions);
    }

    getProfileDetails(userId: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        return this.invokeGetAPI(`${this.constants.CONNECT.GET_PROFILE_DETAILS}/${userId}`, {}, progressBarOptions, errorHandler);
    }

    getRoleLists(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SYSTEM.GET_ROLE_LIST, {}, progressBarOptions);
    }

    getRoleDetails(roldeId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.SYSTEM.GET_ROLE_DETAILS}/${roldeId}`, {}, progressBarOptions);
    }

    addEditRoleDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.GET_ROLE_EDIT, params, progressBarOptions);
    }

    getEvents(params, progressBarOptions?: ProgressBarOptions) {
        var errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        return this.invokePostAPI(this.constants.REFERENCE.GET_EVENTS, params, progressBarOptions, errorHandler);
    }
    saveEvents(params, progressBarOptions?: ProgressBarOptions) {
        var errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        return this.invokePostAPI(this.constants.REFERENCE.SAVE_EVENT, params, progressBarOptions, errorHandler);
    }
    eventMediaUpload(eventId: any, params: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.REFERENCE.UPLOAD_EVENT_MEDIA}/${eventId}`, params, progressBarOptions, errorHandler);
    }
    getEventDetails(eventId: any, progressBarOptions?: ProgressBarOptions) {
        const user = this.userService.getCurrentUser();
        return this.invokeGetAPI(`${this.constants.REFERENCE.GET_EVENT_DETAILS}/${eventId}/${user.userId}`, {}, progressBarOptions);
    }
    approveCard(params, progressBarOptions?: ProgressBarOptions) {
        //var errorHandler = ErrorHandlerHelper.RedirectToNotFoundPage;
        return this.invokePostAPI(this.constants.REFERENCE.APPROVE_CARD, params, progressBarOptions);
    }

    insightFileUpload(insightId: any, params: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.REFERENCE.UPLOAD_INSIGHT_FILE}/${insightId}`, params, progressBarOptions, errorHandler);
    }

    getSystemUsers(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.GET_USER_LIST, params, progressBarOptions);
    }
    getProfileHistory(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.GET_PROFILE_HISTORY, params, progressBarOptions);
    }
    getEnquiries(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ENQUIRIES.GET_ENQUIRIES_LIST, params, progressBarOptions);
    }
    getMembershipCodes(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.GET_MEMBERSHIP_CODES, params, progressBarOptions);
    }
    approveUser(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.APPROVE_USER, params, progressBarOptions);
    }
    getSocialMediaLinks( progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SYSTEM.GET_SOCIAL_MEDIA_LINKS,  progressBarOptions);
    }

    saveSocialMediaLinks(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.SAVE_SOCIAL_MEDIA_LINKS, params, progressBarOptions);
    }

    approveOrDeleteUserRole(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.APPOVE_OR_DELETE_USER_ROLE, params, progressBarOptions);
    }

    getHelpTreeList(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.CONTENT.GET_HELP_TREE, {}, progressBarOptions);
    }

    getHelpContent(helpId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_HELP_CONTENT}/${helpId}`, {}, progressBarOptions);
    }

    saveHelpAction(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.SET_HELP_ACTION, params, progressBarOptions);
    }

    saveHelpContent(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.SAVE_HELP_CONTENT, params, progressBarOptions);
    }

    saveUser(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.SAVE_SYSTEM_USER, params, progressBarOptions);
    }
    addEditMemebershipCode(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.ADD_EDIT_MEMBERSHIP_CODE, params, progressBarOptions);

    }
    getInternalRoles(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SYSTEM.GET_INTERNAL_ROLES, {}, progressBarOptions);
    }
    getUserProfileChangeDetails(userId: any) {
        return this.invokeGetAPI(`${this.constants.SYSTEM.GET_PROFILE_CHANGE_DETAILS}/${userId}`);

    }
    getUserDetails(userId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.SYSTEM.GET_USER_DETAILS}/${userId}`, {}, progressBarOptions);
    }
    getContactDetails(contactId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.ENQUIRIES.GET_CONTACTS_DETAILS}/${contactId}`, {}, progressBarOptions);
    }
    getMembershipDetails(membershipId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.REFERENCE.GET_MEMBERSHIP_CODE_DETAILS}/${membershipId}`, {}, progressBarOptions);
    }
    saveEcosystemMedia(cardId: any, params: any, progressBarOption?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.REFERENCE.SAVE_ECOSYSTEM_MEDIA}/${cardId}`, params, progressBarOption, errorHandler);
    }

    getTagsList(value: string, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.GET_TAGS_LIST, { keyword: value }, progressBarOptions);
    }

    getMemberList(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.GET_MEMBER_LIST, params, progressBarOption);
    }

    getRoleList(userType:number,progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.USER.GET_Role_LIST}/${userType}`, {}, progressBarOption);
    }
    getMembershipList(progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.USER.GET_MEMBERSHIP_LIST, {}, progressBarOption);
    }
    getUserTypeList(progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.USER.GET_USERTYPE_LIST, {}, progressBarOption);
    } 
    saveRoleMembership(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.ASSIGN_ROLE_MEMBERSHIP, params, progressBarOption);
    }

    doMemberAction(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.MEMBER_ACTION, params, progressBarOption);
    }

    getUserProfileDetails(params: any) {

        return this.invokePostAPI(`${this.constants.USER.PROFILE}`, params);

    }
   
    getContentHottopics(params?: any, progressBarOption?: ProgressBarOptions) {
        let parameters: any = {};
        if (!params) {
            params = {};
        }
        parameters.page = params.page || 1;
        parameters.pageSize = params.pageSize || 10;
        parameters.keyword = params.keyword || null;
        //parameters.driverId = params.driverId || null;
        parameters.sort = params.sortBy || 1;
        parameters.hotTopicClientType = params.hotTopicClientType || null;
        parameters.hotTopicMembership = params.hotTopicMembership || null;
        return this.invokePostAPI(this.constants.CONTENT.GET_HOT_TOPICS_LIST, parameters, progressBarOption);
    }

    getHTSubtopics(topicId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_HT_SUBTOPICS}/${topicId}`, {}, progressBarOptions);
    }
    getHTSubTopicConsideration(subtopicId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_HT_SUBTOPIC_CONSIDERATION}/${subtopicId}`, {}, progressBarOptions);

    }
    saveHTSubTopicConsideration(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_HT_SUBTOPIC_CONSIDERATION}`, params);
    }
    saveHTSubTopics(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_HT_SUBTOPICS}`, params, progressBarOption);
    }
    deleteHTSubTopics(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.DELETE_HT_SUBTOPICS}`, params, progressBarOption);
    }
    getAllEventsForHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_ALL_EVENTS, params, progressBarOptions);
    }

    addEventToHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.ADD_EVENT_HT, params, progressBarOptions);
    }

    deleteEventFromHT(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.DELETE_EVENT_HT, params, progressBarOptions);
    }

    getHTEvents(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_HT_EVENTS, params, progressBarOptions);
    }

    systemUserAction(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.USER.USER_ACTION}`, params);
    }

    getImpactedCommunityDetailsForUser(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.USER.GET_IMPACTED_COMMUNITIES}`, params, progressBarOption);
    }

    getHotTopicChampions(hotTopicId: any, getSelectedInsights: boolean, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_HT_CHAMPIONS}/${hotTopicId}/${getSelectedInsights}`, {}, progressBarOptions);

    }

    addHotTopicInsights(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.ADD_HT_INSIGHTS}`, params, progressBarOption);
    }
    getSurveys(hotTopicId: any, active: boolean, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_SURVEYS}/${hotTopicId}/${active}`, {}, progressBarOptions);

    }
    saveSurveys(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_SURVEYS}`, params, progressBarOption);
    }
    reorderSurveys(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.REORDER_SURVEYS}`, params, progressBarOption);
    }

    addHotTopicTools(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.ADD_HT_TOOLS}`, params, progressBarOption);
    }

    addHotTopicChampions(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.ADD_HT_CHAMPIONS}`, params, progressBarOption);
    }


    deleteHotTopicCards(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.DELETE_HT_CARD}`, params, progressBarOption);
    }

    uploadMedia(mediaTypeId: any, mediaContainer: string, params: any, progressBarOptions?: ProgressBarOptions, showProgress?: boolean) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.CONTENT.UPLOAD_MEDIA}/${mediaTypeId}/${mediaContainer}`, params, progressBarOptions, errorHandler, showProgress);
    }

    uploadHTML(params: any, progressBarOptions?: ProgressBarOptions, showProgress?: boolean) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.CONTENT.UPLOAD_HTML}`, params, progressBarOptions, errorHandler,false);
    }
    getHTTopicDetailsAdmin(topicId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_HT_DETAILS}/${topicId}`, {}, progressBarOptions);
    }

    getUserCommunityDetails(userId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_USER_COMMUNITY_DETAILS}/${userId}`, {}, progressBarOptions);
    }

    updateHotTopic(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_HT_DETAILS}`, params, progressBarOption);
    }

    memberBulkAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostUploadAPI(`${this.constants.USER.MEMBER_BULK_ACTION}`, params, progressBarOptions);
    }

    userBulkAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostUploadAPI(`${this.constants.USER.USER_BULK_ACTION}`, params, progressBarOptions);
    }

    getHotTopicSectionCount(hotTopicId: any, progressBarOptions?: ProgressBarOptions, isMainApi?: boolean) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_HOT_TOPIC_SECTIOn_COUNT}/${hotTopicId}`, {}, progressBarOptions);
    }

    saveHotTopic(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_HOT_TOPICS}`, params, progressBarOptions);
    }

    getAnnouncementDetails(announcementId?: any, progressBarOptions?: ProgressBarOptions) {
        if(announcementId) {
            return this.invokeGetAPI(`${this.constants.CONTENT.GET_ANNOUNCEMENT_DETAILS}/${announcementId}`, {}, progressBarOptions);
        } else {
            return this.invokeGetAPI(`${this.constants.CONTENT.GET_ANNOUNCEMENT_DETAILS}`, {}, progressBarOptions);
        }
    }

    getAddedAnnouncementData(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_ANNOUNCEMENTS_FOR_ADMIN}`, {}, progressBarOptions);
    }

    saveAnnouncement(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_ANNOUNCEMENT}`, params, progressBarOptions);
    }

    updateAnnouncementOrder(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_ANNOUNCEMENT_ORDER}`, params, progressBarOptions);
    }

    saveAnnouncementDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_ANNOUNCEMENT_DETAILS}`, params, progressBarOptions);
    }

    updateAnnouncementDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_ANNOUNCEMENT}`, params, progressBarOptions);
    }

    getQuickLinkDetails(quickLinkId?: any, progressBarOptions?: ProgressBarOptions) {
        if(quickLinkId) {
            return this.invokeGetAPI(`${this.constants.CONTENT.GET_QUICK_LINK_DETAILS}/${quickLinkId}`, {}, progressBarOptions);
        } else {
            return this.invokeGetAPI(`${this.constants.CONTENT.GET_QUICK_LINK_DETAILS}`, {}, progressBarOptions);
        }
    }

    saveQuickLinkDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_QUICK_LINK}`, params, progressBarOptions);
    }

    updateQuickLinkDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_QUICK_LINK}`, params, progressBarOptions);
    }

    uploadQuickLinkMedia(mediaTypeId: any, params: any, progressBarOptions?: ProgressBarOptions, showProgress?: boolean) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.CONTENT.UPLOAD_QUICK_LINK_MEDIA}/${mediaTypeId}`, params, progressBarOptions, errorHandler, showProgress);
    }

    getAddedQuickLinkData(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_QUICK_LINK_FOR_ADMIN}`, {}, progressBarOptions);
    }

    updateQuickLinkStatus(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_QUICK_LINK_STATUS}`, params, progressBarOptions);
    }

    updateQuickLinkOrder(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.UPDATE_QUICK_LINK_ORDER}`, params, progressBarOptions);
    }

    championProfileUpload(championId: any, params: any, progressBarOptions?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.REFERENCE.UPLOAD_CHAMPION_PROFILE}/${championId}`, params, progressBarOptions, errorHandler);
    }

    getSevenDriverDetails(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.CONTENT.GET_7DRIVER_DETAILS}`, {}, progressBarOptions);
    }

    saveSevenDriverDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.SAVE_7DRIVER_DETAILS}`, params, progressBarOptions);
    }

    deleteTag(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.DELETE_TAG}`, params, progressBarOptions);
    }

    deleteTagCategory(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.DELETE_TAG_CATEGORY}`, params, progressBarOptions);
    }

    saveTagCategory(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.ADD_EDIT_TAG_CATEGORY}`, params, progressBarOptions);
    }

    saveTag(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.ADD_EDIT_TAG}`, params, progressBarOptions);
    }

    getMemberContactList(params, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.ENQUIRIES.GET_MEMBER_CONTACT_LIST, params, progressBarOptions);
    }
    getMemberContactDetails(contactId: number, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.ENQUIRIES.GET_MEMBER_CONTACT_DETAILS}/${contactId}`, {}, progressBarOptions);
    }
    SaveMemberContactUsAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.ENQUIRIES.SAVE_MEMBER_CONTACT_ACTION}`, params, progressBarOptions);
    }

    getToolsPopupDetails(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_TOOLS_POPUP_DETAILS}`, params, progressBarOption);
    }

    getSurveyPopupDetails(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_SURVEY_POPUP_DETAILS}`, params, progressBarOption);
    }

    getEventPopupDetails(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.GET_EVENTS_POPUP_DETAILS}`, params, progressBarOption);
    }
    /*********************** SURVEY APIS Start *******************************/
    getReferenceSurveyList(params: any, progressBarOptions?: ProgressBarOptions) {       
        return this.invokePostAPI(this.constants.REFERENCE.GET_REF_SURVEY_LIST, params, progressBarOptions);
    }

    getReferenceSurveyDetails(surveyId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.REFERENCE.GET_REF_SURVEY_DETAILS}/${surveyId}`, {}, progressBarOptions);
    }

    saveReferenceSurveyUpdate(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.UPDATE_SURVEY, params, progressBarOptions);
    }

    updateSurveyAction(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.UPDATE_SURVEY_ACTION, params, progressBarOptions);
    }
    /*********************** SURVEY APIS End *******************************/

    /*********************** REPORT APIS Start *****************************/

    getAuditReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.AUDIT_REPORT, params, progressBarOptions);
    }

    getLanguageUsageReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.LANGUAGE_USAGE_REPORT, params, progressBarOptions);
    }

    getAnalyticsReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.ANALYTICS_REPORT, params, progressBarOptions);
    }
    getEnquiryReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.ENQUIRY_REPORT, params, progressBarOptions);
    }
    getUserAccessReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.USER_ACCESS_REPORT, params, progressBarOptions);
    }

    getUserReport(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.USER_REPORT, params, progressBarOptions);
    }

    getsevenDriversSession(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REPORT.SEVEN_DRIVER_SESSION_REPORT, params, progressBarOptions);
    }
    getRefereneceReport(referenceTypeId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.REPORT.REFERENCE_REPORT}/${referenceTypeId}`, {}, progressBarOptions);
    }

    /*********************** REPORT APIS End *******************************/
    /*Account Deletion*/

    accountDelete(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.USER.ACCOUNT_DELETION, params, progressBarOptions);
    }
    /*********************** SHOWCASE APIS start *******************************/
    saveShowcaseVideo(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.SAVE_SHOWCASE_VIDEO, params, progressBarOptions);
    }

    getShowcaseVideoList(params: any, progressBarOptions?: ProgressBarOptions) {
        let parameters: any = {};
        if (!params) {
            params = {};
        }
        parameters.page = params.page || 1;
        parameters.pageSize = params.pageSize || 10;
        parameters.keyword = params.keyword || null;
        parameters.isActive = false;
        parameters.sort = params.sortBy || 1;
        return this.invokePostAPI(this.constants.REFERENCE.GET_SHOWCASE_VIDEO_LIST, parameters, progressBarOptions);
    }

    getShowcaseVideo(videoId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.REFERENCE.GET_SHOWCASE_VIDEO}/${videoId}`, {}, progressBarOptions);
    }   

    updateShowcaseVideoStatus(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.UPDATE_SHOWCASE_VIDEO_ACTION, params, progressBarOptions);

    }
    /*********************** SHOWCASE APIS End *******************************/

    /*********************** LABEL MANAGEMENT APIS start *******************************/
    saveLabels(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.SAVE_LABELS, params, progressBarOptions);
    }
    
    saveLabelMedia(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.SAVE_LABELS, params, progressBarOptions);
    }

    saveMediaStatus(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.SAVE_MEDIA_STATUS, params, progressBarOptions);
    }

    getLabelMenu(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.REFERENCE.GET_LABEL_MENU }`, {}, progressBarOptions);
    }

    getLabels(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.GET_LABELS, params, progressBarOptions);
    }

    getLabelMedia(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.REFERENCE.GET_LABELS, params, progressBarOptions);
    }
    /*********************** LABEL MANAGEMENT APIS End *******************************/

    /*********************** PLATFORM CONTENT APIS start *******************************/
    getPlatformContentDetails(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_PLATFORM_DETAILS, params, progressBarOptions);
    }
    getPlatformFileContent(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_PLATFORM_FILE_CONTENT, params, progressBarOptions);  
    }
    savePlatformContent(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.SAVE_PLATFORM_DATA, params, progressBarOptions);
    }

    getTClist(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.GET_TC_LIST, params, progressBarOptions);
    }

    setTCAction(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.SET_TC_ACTION, params, progressBarOptions);
    }

    getCopyRightContent() {
        return this.invokeGetAPI(this.constants.CONTENT.GET_COPY_RIGHT_DETAILS);
    }

    saveCopyRightContent(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.SAVE_COPY_RIGHT_DETAILS, params, progressBarOptions);
    }

    deleteContactUs(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.ENQUIRIES.DELETE_CONTACT}`, params, progressBarOptions);
    }
    deactivateMembershipCode(params: any, progressBarOptions?: ProgressBarOptions) {
           return this.invokePostAPI(`${this.constants.REFERENCE.DEACTIVATE_MEMEBRSHIP_CODE}`, params, progressBarOptions);
    }
    getProfileChangeSearchColumn() {
        return this.invokeGetAPI(this.constants.CONTENT.GET_PROFILE_CHANGE_COLUMN);
    }

    getMembershipAssignedCounts(membershipId: number, progressBarOptions?: ProgressBarOptions) {
       return this.invokeGetAPI(`${this.constants.REFERENCE.GET_MEMEBRSHIP_ASSIGNED_COUNT}/${membershipId}`, {}, progressBarOptions);
    }
    getNotificationFrequency() {
        return this.invokeGetAPI(this.constants.SYSTEM.NOTIFICATION_FREQUENCY);
    }

    getNotificationFList() {
        return this.invokeGetAPI(this.constants.SYSTEM.NOTIFICATION_SETTING_LIST);
    }

    saveNotification(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.SAVE_NOTIFICATION_SETTING, params, progressBarOptions);
    }

    getCookieContent() {
        return this.invokeGetAPI(this.constants.CONTENT.GET_COOKIE_DETAILS);
    }

    saveCookieContent(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.CONTENT.SAVE_COOKIE_DETAILS, params, progressBarOptions);
    }

    /*********************** PLATFORM CONTENT APIS End *******************************/

    getGP(params:any){
        return this.invokePostAPI(this.constants.DATAIMPORT.GET_GP, params);
    }
    getGrowthPlanDetails(growthPlanId: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.DATAIMPORT.GET_GROWTH_PLAN_DETAILS}/` + growthPlanId, {}, progressBarOptions);
    }
    searchMemberShare(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.DATAIMPORT.SEARCH_MEMBER_SHARE, params, progressBarOptions);
    }
    searchFacilitator(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.DATAIMPORT.SEARCH_FACILITATOR}/` + params, progressBarOptions);
    }
    saveGP(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.DATAIMPORT.SAVE_GP, params, progressBarOptions);
    }

    getInactiveLanguageList(progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SYSTEM.GET_INACTIVE_LANGUAGES, {}, progressBarOption);
    }

    getFops(progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SEVEN_DRIVER.GET_FOPS, {}, progressBarOption);
    }

    saveFop(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SEVEN_DRIVER.SAVE_FOP, params, progressBarOptions);
    }

    saveFopMedia(fopId: any, params: any, progressBarOption?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.SEVEN_DRIVER.SAVE_FOP_MEDIA}/${fopId}`, params, progressBarOption, errorHandler);
    }

    getActions(progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SEVEN_DRIVER.GET_ACTIONS, {}, progressBarOption);
    }

    getFop(fopId: any, progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SEVEN_DRIVER.GET_FOP + '/' + fopId, {}, progressBarOption);
    }

    getMappedActions(fopId, progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SEVEN_DRIVER.GET_FOP + '/' + fopId+'/true', {}, progressBarOption);
    }

    mapActionsToFop(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SEVEN_DRIVER.MAP_ACTIONS_TO_FOP}`, params, progressBarOption);
    }

    deactivateFop(fopId, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SEVEN_DRIVER.DEACTIVATE_FOP}/${fopId}`, {}, progressBarOption);
    }

    getNotifications(params, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SEVEN_DRIVER.GET_NOTIFICATIONS, params, progressBarOption);
    }

    downloadNotification(id, progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SEVEN_DRIVER.DOWNLOAD_TRANSLATION + '/' + id, {}, progressBarOption);
    }

    markComplete(params, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SEVEN_DRIVER.MARK_COMPLETE, params, progressBarOption);
    }

    deleteAnnouncement(id,params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.CONTENT.DELETE_ANNOUNCEMENT+'/'+id}`, params, progressBarOptions);
    }

    deleteQuickLink(id, params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.REFERENCE.DELETE_QUICK_LINK + '/' + id}`, params, progressBarOptions);
    }

    getActionSettings(progressBarOption?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SYSTEM.GET_ACTION_SETTINGS, {}, progressBarOption);
    }

    saveActionSettings(params, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.SAVE_ACTION_SETTINGS, params, progressBarOption);
    }


    getIndustries(industryId?: any, progressBarOptions?: ProgressBarOptions) {
        if (industryId) {
            return this.invokeGetAPI(`${this.constants.SYSTEM.GET_INDUSTRY}/${industryId}`, {}, progressBarOptions);
        } else {
            return this.invokeGetAPI(`${this.constants.SYSTEM.GET_INDUSTRIES}`, {}, progressBarOptions);
        }
    }

    updateIndustryOrder(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.UPDATE_INDUSTRY_ORDER}`, params, progressBarOptions);
    }

    updateShowIndustryOnPortal(industryId: any, status: boolean, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.UPDATE_SHOW_INDUSTRY_ON_PORTAL}/${industryId}/${status}`, {}, progressBarOptions);
    }

    saveIndustry(params, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.SAVE_INDUSTRY, params, progressBarOption);
    }

    deleteIndustry(id, params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.DELETE_INDUSTRY + '/' + id}`, params, progressBarOptions);
    }

    getAboutVelocityList(aboutVelocityId?: any, progressBarOptions?: ProgressBarOptions) {
        if (aboutVelocityId) {
            return this.invokeGetAPI(`${this.constants.SYSTEM.GET_ABOUT_VELOCITY}/${aboutVelocityId}`, {}, progressBarOptions);
        } else {
            return this.invokeGetAPI(`${this.constants.SYSTEM.GET_ABOUT_VELOCITY_LIST}`, {}, progressBarOptions);
        }
    }

    updateAboutVelocityOrder(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.UPDATE_ABOUT_VELOCITY_ORDER}`, params, progressBarOptions);
    }

    updateShowAboutVelocityOnPortal(industryId: any, status: boolean, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.UPDATE_SHOW_ABOUT_VELOCITY_ON_PORTAL}/${industryId}/${status}`, {}, progressBarOptions);
    }

    saveAboutVelocity(params, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.SAVE_ABOUT_VELOCITY, params, progressBarOption);
    }

    deleteAboutVelocity(id, params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.DELETE_ABOUT_VELOCITY + '/' + id}`, params, progressBarOptions);
    }

    getCoverImages(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SYSTEM.GET_COVER_IMAGES, params, progressBarOptions);
    }

    saveCoverImage(coverImageId: any, params: any, progressBarOption?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.SYSTEM.UPLOAD_COVER_IMAGE}/${coverImageId}`, params, progressBarOption, errorHandler,false);
    }

    updateReportCover(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.UPDATE_REPORT_COVER}`, params, progressBarOptions);
    }

    getTemplates(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(this.constants.SYSTEM.GET_TEMPLATES, params, progressBarOptions);
    }

    getTemplate(id, params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.SYSTEM.GET_TEMPLATE + '/' + id}`, params, progressBarOptions);
    }

    saveTemplateDetails(params: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SYSTEM.SAVE_TEMPLATE_dDETAILS}`, params, progressBarOptions);
    }
    getSections(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.SYSTEM.GET_LABEL_SECTIONS}`, {}, progressBarOptions);
    }

    getSectionLabels(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SYSTEM.GET_SECTION_LABELS, params, progressBarOptions);
    }
    getBoilerPlate(progressBarOptions?: ProgressBarOptions) {
        return this.invokeGetAPI(`${this.constants.SYSTEM.GET_BOILER_PLATE}`, {}, progressBarOptions);
    }
    saveBoilerPlateMedia(params: any, progressBarOption?: ProgressBarOptions) {
        var errorHandler: AjaxErrorOptions = ErrorHandlerHelper.ShowDialog;
        return this.invokePostUploadAPI(`${this.constants.SYSTEM.SAVE_BOILER_PLATE_MEDIA}`, params, progressBarOption, errorHandler,false);
    }

    saveSolution(params?: any, progressBarOptions?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SEVEN_DRIVER.SAVE_SOLUTION, params, progressBarOptions);
    }

    getSolutions(params?: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SEVEN_DRIVER.GET_SOLUTIONS, params, progressBarOption);
    }
    getSolutionDetails(solutionId: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SEVEN_DRIVER.GET_SOLUTION_DETAILS + '/' + solutionId, {}, progressBarOption);
    }

    getMappedActionsForSolution(solutionId, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(this.constants.SEVEN_DRIVER.GET_SOLUTION_DETAILS + '/' + solutionId+'/true', {}, progressBarOption);
    }

    deactivateSolution(solutionId, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SEVEN_DRIVER.DEACTIVATE_SOLUTION}/${solutionId}`, {}, progressBarOption);
    }

    mapActionsToSolution(params: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SEVEN_DRIVER.MAP_ACTIONS_TO_SOLUTION}`, params, progressBarOption);
    }

    getSolutionName(params?: any, progressBarOption?: ProgressBarOptions) {
        return this.invokePostAPI(`${this.constants.SEVEN_DRIVER.GET_SOLUTION_NAME}`, params, progressBarOption);
    }
}
