<div class="people-picker-container" [class.multiselect]="(multiSelect === true)">
    <div class="selected-user" *ngFor="let user of selectedUsers">
        <span class="user-text" title="{{user.Email}}">{{user.DisplayName}}</span><button class="delete-user" (click)="deleteSelectedUser(user)" type="button"><span>x</span></button>
    </div>
    <div class="user-search-input" *ngIf="((multiSelect === false)?(selectedUsers.length<1):(selectedUsers.length < maxSelectionCount))">
        <input type="text" placeHolder="Start typing here" [(ngModel)]="inputText" (keyup)="onKeyPress($event)" (keydown)="onKeyDown($event)" (onpaste)="onPaste();" #nameInput id="txtSearchInput" autocomplete="off"/>
    </div>
</div>
<div class="preloader-holder" [class.multiselect-preloader-holder]="(multiSelect === true)" *ngIf="showPreLoader">
    Loading...
</div>

<div class="empty-text-holder" [class.multiselect-empty-text-holder]="(multiSelect === true)" *ngIf="showEmptyText">
    No search results found!
</div>
<div class="selection-holder" [class.multiselect-selection-holder]="(multiSelect === true)" *ngIf="filteredUsers.length > 0">
    <div *ngFor="let user of filteredUsers" class="filtered-user-list" (click)="selectUser(user)">
        {{user.DisplayName}} ({{user.Email}})
    </div>
</div>