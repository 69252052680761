import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'error-dialog',
    templateUrl: 'dialog.component.html'
})

export class ErrorDialogComponent {

    @Input("title") title: string = "";
    @Input("message") message: string = "";
    public config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true,
        show: true
    };
    constructor() { }

    ngOnInit() {

    }
}