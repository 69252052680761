import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from './model/response';
import { UtilService } from './util.service';
import { Constants } from './constant.service';
import { UserService } from '../auth/service/user.service';
import { ProgressBarOptions, ProgressBarHelper } from './../progress-bar-module/progress-bar-options';
import { AjaxErrorOptions, ErrorHandlerHelper } from './model/ajax-error-options';
import { ErrorHandlerTypes } from './model/ajax-error-handler-types';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';


const ApiUrl = environment.VELOCITY_API;

@Injectable()
export class UserLookupService {
    constructor(
        private utilService: UtilService,
        private userService: UserService,
        private constants: Constants,
        private router: Router,
        private msalService: MsalService
    ) { }

    private invokeGetAPI(url: string, params?: any, progressBarOption?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {

        return Observable.create(observer => {
            this.utilService.doGet(ApiUrl + url, params, progressBarOption).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        throwError(response.message);
                    }
                }, (error: any) => {
                    throwError(error);
                });
        });
    }



    invokePostAPI(url: string, params?: any, progressBarOptions?: ProgressBarOptions, errorHandler?: AjaxErrorOptions) {
        const user = this.userService.getCurrentUser();
        //TODO Uncomment after login flow 
        //if (user) {
        //    params.userId = user.userId;
        //}
        return Observable.create(observer => {
            this.utilService.doPost(ApiUrl + url, params, progressBarOptions).subscribe(
                (response: Response) => {
                    if (response && response.status && (response.code === '1000')) {
                        observer.next(response.result);
                        observer.complete();
                    } else {
                        //this.utilService.showError(response.message);
                        this.handleErrorResponse(response.code, response.message, errorHandler);
                        throwError(response.message);
                    }
                }, (error: any) => {
                    //this.utilService.errorHandler(error);
                    this.handleError(error, errorHandler);
                });
        });
    }

    handleErrorResponse(errorCode, errorMessage, errorHandler?: AjaxErrorOptions) {
        if (errorCode == "401") {
            this.msalService.logout();
            return;
        }

        if (!errorHandler || errorHandler.handleError == undefined) {
            this.utilService.showError(errorMessage);
        }
        //else if (errorCode == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
        //    this.adalService.logOut();
        //}
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE && errorCode == "404") {
            this.router.navigate(['general', 'error404']);
        }
    }

    handleError(error, errorHandler?: AjaxErrorOptions) {

        if (error.status == "401") {
            this.msalService.logout();
            return;
        }

        let errorMessage: any = "An error occured while processing the request";
        var parseError = false;
        try {
            if (error._body && JSON.parse(error._body).message) {
                errorMessage = JSON.parse(error._body).message;
            }
        }
        catch (e) {
            parseError = true;
        }
        if (!errorHandler || errorHandler.handleError == undefined) {
            this.utilService.showError(errorMessage);
        }
        //else if (error.status == "401" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_LOGIN_DIALOG) {
        //    this.adalService.logOut();
        //}
        else if (error.status == "404" && errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.REDIRECT_TO_NOT_FOUND_PAGE) {
            this.router.navigate(['general', 'error404']);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_ALERT_MESSAGE) {
            this.utilService.showError(errorMessage);
        }
        else if (errorHandler.handleError == true && errorHandler.action == ErrorHandlerTypes.SHOW_DIALOG_MESSAGE) {
            this.utilService.showError(errorMessage, true);
        }
        else {
            this.utilService.showError(errorMessage);
        }
    }

    searchUsers(source: string, inputText: string) {
        //Source can be AD - Active directory or DB
        if (source == "AD") {
            return this.searchActiveDirectory(inputText);
        }
        else if (source == "DB"){
            return this.searchDatabase(inputText);
        }
        else {
            return Observable.create(observer => {
                observer.next([]);
                observer.complete();
            });
        }
    }

    private searchActiveDirectory(inputText: string) {
        let params = { keyword: inputText }
        var pbOptions = ProgressBarHelper.NoProgressBar;
        return this.invokePostAPI(this.constants.USER_LOOKUP.AD_SEARCH, params,
            pbOptions);
    }

    private searchDatabase(inputText: string) {
        return Observable.create(observer => {
            observer.next([]);
            observer.complete();
        });
    }


   
        
}
