import { NgModule } from '@angular/core';

import { AuthenticationService } from '../auth/service/auth.service';
import { UserService } from '../auth/service/user.service';
import { AuthGuard } from '../auth/guard/auth.guard';
import { HTTPService } from './http.service';
import { UtilService } from './util.service';
import { VelocityService } from './velocity.service';
import { GrowthService } from './growth.service';
import { DataShareService } from './data-share.service';
import { ContentResolve } from './content.resolve';
import { Constants } from './constant.service';
import { AlertService } from './alert.service';
import { SubMenuService } from './sub-menu.service';
import { StorageHelperService } from './storage-helper.service';
import { LookUpService } from './look-up.service';
import { QueryService } from './query.service';
import { NotificationService } from './notification.service';
import { ModalDialogModule } from '../modal-dialog-module/modal-dialog.module';
import { AdminActionService } from "./admin-action.service";
import { UserLookupService } from './user-lookup.service';
import { LogService } from "./log.service";
import { LogTypes } from "./model/log-models";
import { TimerService } from './timer.service';
import { HttpClientModule } from '@angular/common/http';
import { Broadcaster } from './broadcast.service';

@NgModule({
    declarations: [],
    imports: [
        ModalDialogModule,
        HttpClientModule
    ],
    providers: [
        AuthGuard,
        AuthenticationService,
        HTTPService,
        UserService,
        UtilService,
        VelocityService,
        GrowthService,
        DataShareService,
        ContentResolve,
        Constants,
        AlertService,
        SubMenuService,
        StorageHelperService,
        LookUpService,
        QueryService,
        NotificationService,
        AdminActionService,
        UserLookupService,
        LogService,
        LogTypes,
        TimerService,
        Broadcaster
    ]
})
export class ServiceModule { }
