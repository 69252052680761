import { Injectable, Inject } from '@angular/core';
import { HTTPService } from './http.service';
import { Observable } from 'rxjs';
import { Response } from './model/response';
import { DataShareService } from './data-share.service';
//import { ModalDialogService } from '../modal-dialog-module/modal-dialog.service';
//import { Alert, AlertType } from '../component/alert/model/alert.model';
import { AlertService } from './alert.service';
import { Constants } from './constant.service';
import { ProgressBarService } from './../progress-bar-module/progres-bar.service';
import { ProgressBarTypes } from './../progress-bar-module/progress-bar-types.enum';
import { ProgressBarOptions, ProgressBarHelper } from './../progress-bar-module/progress-bar-options';
import { NavigationEnd } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';
import { filter, tap, catchError } from 'rxjs/operators';


const CONTENT_API = environment.VELOCITY_API + 'appcontent/';
const ASSESSMENT_API = environment.VELOCITY_API + 'assessment/';

@Injectable()
export class UtilService {
    constructor(
        private httpService: HTTPService,
        private dataShareService: DataShareService,
        //private dialog: ModalDialogService,
        private alert: AlertService,
        private constant: Constants,
        private progressBarService: ProgressBarService
    ) { }

    getFullURL(url: string): string {
        let fullURL: string;
        fullURL = '/' + url;
        return fullURL;
    }

    doGet(baseurl: string, params?: any, progressBarOptions?: ProgressBarOptions) {

        progressBarOptions = progressBarOptions || Object.assign({}, ProgressBarHelper.DefaultPost);
        if (progressBarOptions && progressBarOptions.showProgressBar) {
            this.progressBarService.enable(progressBarOptions);
        }
        return this.httpService.request({
            url: baseurl,
            method: 'GET'
        })
        .pipe(tap((x: any) => {
            if (progressBarOptions && progressBarOptions.showProgressBar) {
                this.progressBarService.disable(progressBarOptions);
            }
        }))
        .pipe(catchError((err: any, caught: any) => {
            if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
                return throwError(err);
            }));
    }

    doPost(url: string, params?: any, progressBarOptions?: ProgressBarOptions) {

        progressBarOptions = progressBarOptions || Object.assign({}, ProgressBarHelper.DefaultPost);
        if (progressBarOptions && progressBarOptions.showProgressBar) {
            this.progressBarService.enable(progressBarOptions);
        }
        return this.httpService.request({
            url: url,
            method: 'POST',
            params: params
        })
        .pipe(tap((x: any) => {
            if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
            }))
            .pipe(catchError((err: any, caught: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
                return throwError(err);
            }));
    }

    doUpload(url: string, params?: any, progressBarOptions?: ProgressBarOptions) {
        progressBarOptions = progressBarOptions || Object.assign({}, ProgressBarHelper.DefaultPost);
        if (progressBarOptions && progressBarOptions.showProgressBar) {
            this.progressBarService.enable(progressBarOptions);
        }

        return this.httpService.uploadRequest({
            url: url,
            params: params

        })
        .pipe(tap((x: any) => {
                console.log(x);
                if (x.type === 3 && progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
            }))
            .pipe(catchError((err: any, caught: any) => {
                if (progressBarOptions && progressBarOptions.showProgressBar) {
                    this.progressBarService.disable(progressBarOptions);
                }
                return throwError(err);
            }));
    }

    getContent(pageName: string): Observable<any> {
        this.progressBarService.enable(ProgressBarHelper.PageTransition);
        return Observable.create(observer => {
            const content = this.dataShareService.getSharedData(pageName);
            if (content) {
                observer.next(content);
                observer.complete();
                this.progressBarService.disable(ProgressBarHelper.PageTransition);
            } else {
                this.doGet(CONTENT_API + pageName).subscribe(
                    (response: Response) => {
                        if (response && response.status && (response.code === '1000') && response.result['Content']) {
                            this.dataShareService.setSharedData(pageName, response.result['Content']);
                            observer.next(response.result['Content']);
                            observer.complete();
                            this.progressBarService.disable(ProgressBarHelper.PageTransition);
                        } else {
                            this.progressBarService.disable(ProgressBarHelper.PageTransition);
                            throwError(response.message);
                        }
                    }, (error: any) => {
                        this.progressBarService.disable(ProgressBarHelper.PageTransition);
                        this.errorHandler(error);
                    });
            }
        });
    }

    getContents(pageNames: Array<string>): Observable<any> {
        return Observable.create(observer => {

            if (pageNames.length == 0) {
                observer.next({});
                observer.complete();
            }
            else {

                var pageContentsObservable: Array<Observable<any>> = new Array<Observable<any>>();
                for (let pageName of pageNames) {
                    if (pageName && pageName != "") {
                        pageContentsObservable.push(this.getContent(pageName));
                    }
                }



                forkJoin(pageContentsObservable).subscribe((results: any) => {
                    if (pageNames && pageNames.length && pageNames.length > 0) {
                        if (pageNames[0] != "") {
                            var pageContent = this.dataShareService.getSharedData(pageNames[0]);
                            observer.next(pageContent);
                        }
                        else {
                            observer.next({});
                        }
                        observer.complete();
                    }
                });
            }

        });
    }

    saveAssessment(parameters: any) {
        return Observable.create(observer => {
            this.doPost(ASSESSMENT_API, parameters).subscribe(
                (response: Response) => {
                    console.log(response);
                    if (response && response.status && (response.code === '1000') && response.result['Content']) {
                        console.log(response);
                        observer.next(response);
                        //  observer.next(response.result['Content']);
                        observer.complete();
                    } else {
                        throwError(response.message);
                    }
                }, (error: any) => {
                    this.errorHandler(error);
                });

        });
    }
    errorHandler(error: any) {
        let message = '';
        message += (error.status || '') + ' ';
        message += (error.statusText || 'Network issue. Please try later')
        this.showError(message);
    }

    //showDialog(options: any) {
    //    const message = options.message || options;
    //    const title = options.title || 'EY Velocity';
    //    this.dialog.alert(title, message);
    //}

    //showConfirmDialog(options: any) {
    //    const message = options.message || options;
    //    const title = options.title || 'EY Velocity';
    //    return this.dialog.confirm(title, message);
    //}

    //showInfoDialog(options: any) {
    //    const message = options.message || options;
    //    this.dialog.info(message, {});
    //}

    showWarning(message: string) {
        this.alert.warn(this.constant.APP.WARN, message || 'An error occured while processing your request');
    }
    showSuccess(message: string) {
        this.alert.success(this.constant.APP.SUCCESS, message || 'An error occured while processing your request');
        this.scrollToTop();
    }
    showInfo(message: string) {
        this.alert.info(this.constant.APP.INFO, message || 'An error occured while processing your request');
    }
    showError(message: string, showErrorAsDialog?: boolean) {
        if (!showErrorAsDialog) {
            this.alert.error(this.constant.APP.ERROR, message || 'An error occured while processing your request');
        }
        else {
            this.alert.dialog(this.constant.APP.ERROR, message || 'An error occured while processing your request');
        }
        this.scrollToTop();
    }

    showLoginPopup() {
        this.alert.showLoginPopup();
    }

    scrollToPosition(element: HTMLElement, to: number, duration: number): void {

        if (duration <= 0) return;
        let difference = to - element.scrollTop;
        let pertick = difference / duration * 10;

        let timeoutId = setTimeout(() => {
            element.scrollTop = element.scrollTop + pertick;
            if (element.scrollTop == to) return;
            this.scrollToPosition(element, to, duration - 10);
        }, 10);
    }

    scrollToTop(): void {

        //if (window.scrollY != undefined) {
        //    this.scrollToPosition(document.body, 0, 100);
        //} else {
        //    this.scrollToPosition(document.documentElement, 0, 100);
        //}
        this.scrollToPosition(document.documentElement, 0, 100);
    }

    scrollToTopOnNavigation(router) {

        router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((x: any) => {
            this.scrollToTop();
        });
    }

    getOffset(element: any): any {
        var offset = { top: 0, left: 0, width: 0, height: 0 };
        if (element) {
            var offsetRectangle = element.getBoundingClientRect();
            if (offsetRectangle.width || offsetRectangle.height || element.getClientRects.length) {
                var clientTop = document.documentElement.clientTop;
                var clientleft = document.documentElement.clientLeft;
                offset = { top: offsetRectangle.top + window.pageYOffset + clientTop, left: offsetRectangle.left + window.pageXOffset + clientleft, width: offsetRectangle.width, height: offsetRectangle.height };
            }

        }
        return offset;

    }

    getSelectValueForId(id: any, array: any[]): any {
        return array.filter((item: any) => item.id === id);
    }

    getIconographicBackground(icon: string) {
        icon = icon.toLowerCase();
        switch (icon) {
            case "a": { return "#7FD1D6"; };
            case "b": { return "#027DC2" };
            case "c": { return "#D8D2E0" };
            case "d": { return "#B893B7" };
            case "e": { return "#95CB89" };
            case "f": { return "#C836C5" };
            case "g": { return "#FF5800" };
            case "h": { return "#6441B4" };
            case "i": { return "#D8005D" };
            case "j": { return "#26787E" };
            case "k": { return "#FB8503" };

            case 'l': { return "#7FD1D6" };
            case 'm': { return "#D8D2E0" };
            case 'n': { return "#B893B7" };
            case 'o': { return "#95CB89" };
            case 'p': { return "#C836C5" };
            case 'q': { return "#6441B4" };
            case 'r': { return "#D8005D" };
            case 's': { return "#26787E" };
            case 't': { return "#FB8503" };

            case 'u': { return "#FB8503" };
            case 'v': { return "#26787E" };
            case 'w': { return "#6441B4" };
            case 'x': { return "#B893B7" };
            case 'y': { return "#027DC2" };
            case 'z': { return "#7FD1D6" };



            default: { return "#FE6600"; }
        }

    }
    getIinitals(value: any, lastName: string, isName?: boolean) {

        if (value) {
            if (isName) {
                if (lastName) {
                    let initials = value.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
                    return initials;
                } else {
                    let initials = value.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                    return initials;
                }
            }
            else {
                let initials = value.charAt(0).toUpperCase();
                return initials;
            }
        }
        return '';
    }
}

