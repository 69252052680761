<ng-container *ngFor="let alert of alerts">
    <!--add class to apply custom syles [class]='getAlertType(alert)+"-box"'-->
    <alert [type]="getAlertType(alert)" dismissible="true" [dismissOnTimeout]='alert.timeout || defaultTimeout' *ngIf="alert.type != undefined && alert.type!==4">
        <span *ngIf="alert.type===0" class='fa-lg fa fa-times-circle'></span>
        <span *ngIf="alert.type===1" class='fa-lg fa fa-info-circle'></span>
        <span *ngIf="alert.type===2" class='fa-lg fa fa-check-circle'></span>
        <span *ngIf="alert.type===3" class='fa-lg fa fa-exclamation-triangle'></span>
        <span *ngIf="alert.title" class="title"> {{alert.title}} :</span>
        <span class="message">{{alert.message}}</span>
    </alert>
    <!-- <div class="alert alert-{{getAlertType(alert)}}" role="alert" [dismissOnTimeout]='alert.timeout || defaultTimeout' *ngIf="alert.type != undefined && alert.type!==4">
        <span *ngIf="alert.type===0" class='fa-lg fa fa-times-circle'></span>
        <span *ngIf="alert.type===1" class='fa-lg fa fa-info-circle'></span>
        <span *ngIf="alert.type===2" class='fa-lg fa fa-check-circle'></span>
        <span *ngIf="alert.type===3" class='fa-lg fa fa-exclamation-triangle'></span>
        <span *ngIf="alert.title" class="title"> {{alert.title}} :</span>
        <span class="message">{{alert.message}}</span>
        <button type="button" class="btn-close float-end" data-bs-dismiss="alert" aria-label="Close"></button>
    </div> -->


    <error-dialog *ngIf="alert.type===4" [title]="alert.title" [message]="alert.message"></error-dialog>
</ng-container>