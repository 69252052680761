import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UtilService } from './util.service';
import { BaseComponent } from './../component/base.component';

@Injectable()
export class ContentResolve implements Resolve<any> {
  constructor(private utilService: UtilService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {



      /* TODO - resolve content based on componentcontent & componentchildrencontent decorators */
      var currentComponent: any;
      currentComponent = route.component;
      var pageName = (currentComponent && currentComponent.prototype && currentComponent.prototype.pageName) ? currentComponent.prototype.pageName : "";
      var pageNames = (currentComponent && currentComponent.prototype && currentComponent.prototype.pageNames) ? currentComponent.prototype.pageNames : [];

      var namesToBePassed = Object.assign([], pageNames);

      if (pageName != "") {
          namesToBePassed.unshift(pageName);
      }

      //The below lines remove duplicate entries
      var namesToBePassedSet = new Set(namesToBePassed);
      var namesToBePassedOriginal: Array<string> = Array.from(namesToBePassedSet).map((name) => name as string );

      return this.utilService.getContents(namesToBePassedOriginal);

      //return this.utilService.getContent(route.data['contentId']);
  }
}
