import { Component, OnInit, Input, ViewChild, Injector, Output,EventEmitter } from '@angular/core';
//import { ModalModule, Modal } from 'ng2-modal';
//import 'rxjs/add/operator/map';
import {Observable,Subject} from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { QueryService } from "../../service/query.service";
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";




@Component({
    moduleId: module.id.toString(),
    selector: "delete-dialog-discussion",
    templateUrl: './delete-dialog-discussion.component.html',
    styleUrls:['./delete-dialog-discussion.component.scss']
})

export class DeleteDialogDiscussionComponent {
    discussionDetails: any = {};
   
    @ViewChild('deleteModal' , { static: true }) deleteModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
    deleteComments: String = "";
    source: number = 0;

    discussionTitle: string = '';
    deleteReason: string = '';

    @Input('discussionDetails')disussionDetails: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };


    constructor(private injector: Injector, private queryService: QueryService, private logTypes: LogTypes,
        private logService: LogService) {
        this.discussionDetails = this.injector.get('discussionDetails');
        this.source = this.discussionDetails.source;
    }

    doActionAPI(action: number) {

        let params: any = {};
        params.threadId = this.discussionDetails.id;
        params.action = action;
        params.actionComments = this.deleteComments;


        this.queryService.postDicusssionModerate(params).subscribe(
            (data: any) => {
                console.log("Post Discussion Response" + data);
                if (data.status) {
                    this.returnedValue = "deleted";
                    this.onModalClosed(null);
                    this.deleteModal.hide();
                }
                else console.log("Could not complete the request" + data.message);
            }
        );

        // Log the Discussion actions
        let log = new LogAction();
        log.action = this.logTypes.DISCUSSION_DEACTIVATED;
        log.threadId = this.discussionDetails.id;
        this.logService.logAction(log);
    }

    doAsnwerAPIModerate(action: number) {

        let params: any = {};
        params.postId = this.discussionDetails.id;
        params.action = action;
        params.actionComments = this.deleteComments;

        console.log(params);

        this.queryService.postAnswerModerate(params).subscribe(
            (data: any) => {
                console.log("Post Answer Response" + data);
                if (data.status) {
                    this.returnedValue = "deleted";
                    this.onModalClosed(null);
                    this.deleteModal.hide();
                }
                else console.log("Could not complete the request" + data.message);
            }
        );

        // Log the Answer delete actions
        let log = new LogAction();
        log.action = this.logTypes.ANSWER_DEACTIVATED;
        log.postId = this.discussionDetails.id;
        this.logService.logAction(log);
    }


    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }


    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.deleteModal.config = this.config;
        this.deleteModal.show();
        this.customizePopup();
        return this.observable;

        
    }

    closeDialog() {
           this.deleteModal.hide();
    }

    submitClicked() {
        if (this.source == 1) {
            this.doActionAPI(4);
        } else {
            this.doAsnwerAPIModerate(3);
        }

        
    }

    cancelClicked() {
        this.onModalClosed(null);
       // this.deleteModal.hide();
    }

    // source is 1 for discussions and 2 for answer. 
    customizePopup() {
        if (this.source == 1) {
            this.discussionTitle = "Delete Discussion";
            this.deleteReason = "Reason for deleting this thread";
        } else if (this.source == 2) {
            this.discussionTitle = "Delete Answer";
            this.deleteReason = "Reason for deleting this answer";
        }else {
            this.discussionTitle = "";
        }
    }

   
}