import { Component, OnInit, Injector, Input, ElementRef, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { Observable , Subject} from 'rxjs';

import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../component/base.component';
import { FormGroup } from '@angular/forms';
import { MediaUploadModel } from './media-upload-model';
import { VelocityService } from '../../service/velocity.service';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';


@Component({
    moduleId: module.id.toString(),
    selector: 'html-upload-dialog',
    templateUrl: './html-upload-dialog.component.html',
    styleUrls: ['./html-upload-dialog.component.scss']
})
export class HtmlUploadDialogComponent extends BaseComponent implements OnInit, OnChanges {


    observable: Observable<any>;
    subject: any;
    mediaType: number = 1;
    mediaUrl: string = '';
    mediaThumbnailUrl: string = '';
    mediaFullUrl: string = '';
    mediaThumbnailFullUrl: string;
    mediaContainer: string;
    @ViewChild('mediaUploadModal',{static:true}) mediaUploadModal: any;
    returnedValue: MediaUploadModel = new MediaUploadModel();
    returnedValueOriginal: string = JSON.stringify(this.returnedValue);
    uploadForm: FormData = new FormData();
    uploadItem: any;
    uploadFormVideo: FormData = new FormData();
    uploadVideo: any;
    currentProgress: number;
    showProgress: boolean;
    title = "";
    constructor(
        private utilService: UtilService,
        private router: Router,
        private injector: Injector,
        private elementRef: ElementRef,
        private velocityService: VelocityService
    ) {
        super(injector);
        this.title = this.injector.get("title");
    //    this.mediaUrl = this.injector.get("mediaUrl");
    //    this.mediaThumbnailUrl = this.injector.get("thumbnailUrl");

    //    this.mediaFullUrl = this.injector.get("mediaFullUrl");
    //    this.mediaThumbnailFullUrl = this.injector.get("mediaThumbnailFullUrl");
    //    this.mediaContainer = this.injector.get("mediaContainer");
    }


    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() {
    }

    onShown($ev) {
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }

    cancelClicked() {
        //reset to initial value
        this.returnedValue.isCancelled = true;
        this.closeDialog();
    }

    okayClicked() {
        let observables: any = [];
             if (!this.uploadItem) {
                this.utilService.showError("Please select an HTML file to proceed");
                return;
        }

        this.velocityService.uploadHTML(this.uploadForm).subscribe(
            (data: any) => {
                     if (data.status === 'C') {
                         this.returnedValue.mediaUrl = data.value;
                         this.returnedValue.success = true;
                         this.returnedValue.isCancelled = false;
                         this.closeDialog();
                     }
                 });

    }
    

    onFileChange(fileInput: any) {
        let self = this;
        if (fileInput.target.files && fileInput.target.files[0]) {

            var file = fileInput.target.files[0];

            var fileType: string = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);

            var allowedFileTypes = ["html"];
            
            var isAllowedExtension = allowedFileTypes.filter(x => x.toLowerCase() == fileType.toLowerCase());
            if (isAllowedExtension && isAllowedExtension.length && isAllowedExtension.length > 0) {

                    if (this.uploadItem) {
                        this.uploadForm.set('file1', fileInput.target.files[0]);
                        this.uploadItem = fileInput.target.files[0];
                    } else {
                        this.uploadForm.append('file1', fileInput.target.files[0]);
                        this.uploadItem = fileInput.target.files[0];

                    }
              
            }
            else {

                this.utilService.showError("Please upload a valid html file")
               
            }
        }
    }

    openDialog() {
        this.returnedValue.mediaUrl = this.mediaUrl;
        this.returnedValue.mediaThumbnailUrl = this.mediaThumbnailUrl;
        this.returnedValue.mediaFullUrl = this.mediaFullUrl;
        this.returnedValue.mediaThumbnailFullUrl = this.mediaThumbnailFullUrl;
        this.returnedValueOriginal = JSON.stringify(this.returnedValue);

        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.mediaUploadModal.config = this.config;
        this.mediaUploadModal.show();
        return this.observable;
    }

    private closeDialog() {
        this.mediaUploadModal.hide();
    }

    ngOnChanges(simpleChanges: SimpleChanges) {

        for (let prop in simpleChanges) {

        }
    }



}
