import { Component } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Component({
    moduleId: module.id.toString(),
    selector: "page-posting-overlay",
    templateUrl: './page-posting.component.html',
    styleUrls: ['./page-posting.component.scss']
})

export class PagePostingComponent {
    
    state = "inactive";
    constructor() {
    }

    ngOnInit() { }

    
}
