import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ModalDialogModule } from './modal-dialog-module/modal-dialog.module';
import { DynamicModalContainerComponent } from './modal-dialog-module/dynamic-modal-loader.component';
import { StartupService } from './service/startup.service';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './auth/service/user.service';
import { ServiceModule } from './service/service.module';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { ComponentModule } from './component/component.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//import { LoginComponent } from './login/login.component';

import { ProgressBarModule } from './progress-bar-module/progress-bar.module';
import { ProgressBarService } from './progress-bar-module/progres-bar.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { QuillModule } from 'ngx-quill';

//Code to load initial data - exporting as a function to remove AOT dependency
export function StartupDataLoader(startupService: StartupService) {
  //Note: this factory need to return a function (that return a promise)

  return () => startupService.loadInitialData();
}

@NgModule({
  declarations: [
    AppComponent,
        DynamicModalContainerComponent
      //  LoginComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ModalDialogModule,
      ServiceModule,
      ComponentModule,
      CommonModule,
      FormsModule,
      AngularMultiSelectModule,
      ProgressBarModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.AZURE_CLIENTID, 
        authority: environment.AZURE_TENANT, 
        redirectUri: window.location.origin + '/',
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin + '/general/logout',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), null, null),
      QuillModule.forRoot()

  ],
  providers: [
    StartupService,
    {
        provide: APP_INITIALIZER,
        useFactory: StartupDataLoader,
        deps: [StartupService],
        multi: true
      },
      MsalService,
      MsalRedirectComponent,
    ProgressBarService
    
  ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

