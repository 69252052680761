<div class="modal fade ey-dialog-modal delete-modal" bsModal #deleteModal="bs-modal" 
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    x&nbsp;close
                </div>
                <div class="row">
                <div class="col-md-12">
                    <div class="dialog_title"> {{discussionTitle}} </div>
                <div class="discussionTitle">
                    {{discussionDetails.title}}
                </div>
                <div class="discussionDescription">     {{discussionDetails.description}}     </div>
                <div class="thread-tags">
                    <div *ngFor="let tag of discussionDetails.tags">
                        <span class="thread-tag yellow-thread-btn" *ngIf="tag.isCommunityTag"> {{tag.name}}</span>
                        <span class="thread-tag" *ngIf="!tag.isCommunityTag"> {{tag.name}} </span>
                    </div>
                </div>
                    <div class="clear-fix"></div>
                <div class="commentTitle">  {{deleteReason}} </div>
                <textarea class="textAreaStyle" maxlength="400" rows="5"  [(ngModel)]='deleteComments'>  </textarea>

             </div>
             </div>
            </div>
                <div class="modal-footer">
                    <div class="row">
                            <div class="pull-right">
                            <button class="btn btn-ey-default buttonRight" [disabled]= "deleteComments.length == 0" (click)="submitClicked();" type="button">Submit</button>
                            <button class="btn btnWhite buttonRight" (click)="cancelClicked();" type="button">Cancel</button>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
</div>