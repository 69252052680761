import { Component, OnInit, Injector, Input } from "@angular/core";
import { BaseComponent } from '../../component/base.component';
import { CONTENTS } from "../../config/content.const";
import { Router, ActivatedRoute } from '@angular/router';
import { VelocityService } from '../../service/velocity.service';

@Component({
    selector: 'user-profile-component',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BaseComponent implements OnInit {
    profileId: String = "";
    profileData: any = [];

    @Input('userID') userID: any;

    constructor(private injector: Injector,
        private router: Router,
        private route: ActivatedRoute,
        private velocityService: VelocityService,
    ) {

        super(injector);
    }
    ngOnInit() {

        //if (this.route.snapshot.paramMap.get('userId') == null && this.userID) {
        //    this.profileId = this.userID;
        //}
        //else {
        //    this.profileId = this.route.snapshot.paramMap.get('userId');
        //}

        this.profileId = this.userID;
        console.log("Profile Id" + this.profileId);

        this.velocityService.getProfileDetails(this.profileId).subscribe(
            (data) => {

                this.profileData = data;
                console.log("Profile Data" + this.profileData);


            }
        );
    }

    initiateMail() {
        window.location.href = "mailto:" + this.profileData.userEmail;
    }


}