import { Component, OnInit, Injector, Input, ElementRef, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../../component/base.component';
import { PeoplePickerComponent } from '../../component/people-picker/people-picker.component';


@Component({
    moduleId: module.id.toString(),
    selector: 'people-picker-dialog',
    templateUrl: './people-picker-dialog.component.html',
    styleUrls: ['./people-picker-dialog.component.scss']
})
export class PeoplePickerDialogComponent extends BaseComponent implements OnInit, OnChanges {
    title: string = "";
    message: string = "";
    observable: Observable<any>;
    subject: any;
     selectedUsers: any[] = [];
    source: string = "AD";
    selectedUsersString: string = "[]";


    constructor(
        private utilService: UtilService,
        private router: Router,
        private injector: Injector,
        private elementRef:ElementRef
    ) {
        super(injector);
        this.source = this.injector.get("source");
        this.selectedUsers = this.injector.get("selectedUser");
        this.selectedUsersString = JSON.stringify(this.selectedUsers);
    }

    @ViewChild('peoplePickerModal',{static:true}) peoplePickerModal: any;
    @ViewChild('peoplePicker') peoplePicker: PeoplePickerComponent;
    returnedValue: any = [];

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() { 

    }

    onShown($ev) {
        this.peoplePicker.focusInput();
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }

    cancelClicked() {
        //reset to initial value
        this.selectedUsers = JSON.parse(this.selectedUsersString);
        this.returnedValue = this.selectedUsers;
        this.closeDialog();
    }

    okayClicked() {
        this.returnedValue = this.selectedUsers;
        this.closeDialog();
    }

    openDialog() {
        this.selectedUsersString = JSON.stringify(this.selectedUsers);
        this.returnedValue = this.selectedUsers;
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.peoplePickerModal.config = this.config;
        this.peoplePickerModal.show();
        return this.observable;
    }

    private closeDialog() {
        this.peoplePickerModal.hide();
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        
        for (let prop in simpleChanges) {
           
        }
    }

    onSelectionChange(ev: any) {
        this.selectedUsers = ev;
    }

}
