
<div class="velocity-page">
    <div class="velocity">
        <page-transition-progess></page-transition-progess>
        <page-posting-overlay></page-posting-overlay>
        <app-header *ngIf="currentUser!=null"></app-header>
        <!--display none given as the min height of a col is 1px and is showing a white line. Need to re-visit this-->
        <!--<div class="col-md-10 col-md-offset-1"><custom-alert></custom-alert></div>-->
        <div class="alert-container"><custom-alert></custom-alert></div>
        <div class="row" style="position: relative;">
            <div class="col-xs-12 col-md-12 col-lg-12">
                <app-navigation [user]="currentUser"></app-navigation>
            </div>

        </div>
        <!--<app-login *ngIf="currentUser==null && showLogin" (success)="loginSucess()"></app-login>-->
    </div>
    <dynamic-modal-container #dynamicModalContainer></dynamic-modal-container>
</div>

<timeout-handler></timeout-handler> 
