<div class="modal fade ey-info-modal ht-addedit-dialog" bsModal #infoModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-body">
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    x&nbsp;close
                </div>
                <div [ngSwitch]="dialogType" class="ht-add-popup">
                    <ng-template [ngTemplateOutlet]="htCommunityTemplate" *ngSwitchCase="types.HT_COMMUNITY">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="htDiscussionTemplate" *ngSwitchCase="types.HT_DISCUSSION">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="htEventTemplate" *ngSwitchCase="types.HT_EVENT">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="htActionCardTemplate" *ngSwitchCase="types.HT_ACTIONCARD">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="htSurveyTemplate" *ngSwitchCase="types.HT_SURVEY">

                    </ng-template>

                    <ng-template [ngTemplateOutlet]="htToolsTemplate" *ngSwitchCase="types.HT_TOOLS">
                    </ng-template>

                    <ng-template [ngTemplateOutlet]="htInsightsTemplate" *ngSwitchCase="types.HT_INSIGHT">
                    </ng-template>

                    <ng-template [ngTemplateOutlet]="htStartupTemplate" *ngSwitchCase="types.HT_CHAMPION">
                    </ng-template>

                    <ng-template [ngTemplateOutlet]="communityStartupTemplate" *ngSwitchCase="types.COMMUNITY_CHAMPION">
                    </ng-template>

                    <ng-template [ngTemplateOutlet]="communityEventTemplate" *ngSwitchCase="types.COMMUNITY_EVENTS">

                    </ng-template>

                    <ng-template [ngTemplateOutlet]="driverActionCardTemplate" *ngSwitchCase="types.DRIVER_ACTIONCARD">

                    </ng-template>

                    <ng-template [ngTemplateOutlet]="fopActionCardTemplate" *ngSwitchCase="types.FOP_ACTIONCARD">

                    </ng-template>

                    <ng-template [ngTemplateOutlet]="driverInsightsTemplate" *ngSwitchCase="types.DRIVER_INSIGHT">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="addTagTemplate" *ngSwitchCase="types.ADD_TAG">

                    </ng-template>

                    <ng-template [ngTemplateOutlet]="addCategoryTemplate" *ngSwitchCase="types.ADD_CATEGORY">

                    </ng-template>

                    <ng-template [ngTemplateOutlet]="addToolsTemplate" *ngSwitchCase="types.ADD_TOOLS_TEMPLATE">
                    </ng-template>

                    <ng-template [ngTemplateOutlet]="subDriverActionCardTemplate" *ngSwitchCase="types.SUBDRIVER_ACTIONCARD">
                    </ng-template>



                </div>
                <div *ngIf="dialogType != types.ADD_TOOLS_TEMPLATE || (dialogType == types.ADD_TOOLS_TEMPLATE && filterTools.length != 0)">
                    <button type="button" class="btn btn-ey-default" [disabled]="(dialogType == types.FOP_ACTIONCARD) && !actionSelected" (click)="okayClicked()">Done</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-ey-default-gray btn-font-16" (click)="cancelClicked()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #htCommunityTemplate>
    <div class="htcommunity-addedit-dialog">
        <div class="row">
            <div class="col-md-6">
                <span class="htcommunitypopup-title">Select Community</span>
            </div>
            <div class="col-md-6">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="communitySearchStr" (keyup.enter)="getAllCommunities()" />
                    <button class="btn btn-search btn-white" (click)="getAllCommunities()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="htCommunitiyList.length>0">
            <div class="col-md-12 htcomunity-card-container">
                <div class="htcommunity-card" *ngFor="let community of htCommunitiyList; let idx = index">
                    <div class="row">
                        <div class="col-md-12   htcommunity-action-links">
                            <input class="htcommunity-radio" type="radio" name="radiogroup" [checked]="idx==0" [value]="community.id" (change)="onSelectionChange(community)">                            &nbsp;
                            <div class="htcommunity-title">{{community.title}}</div>

                            <div class="community-action-link pull-right text-right">
                                <span><i class="fa fa-user-o "></i>{{community.membersCount}} Members</span>&nbsp;&nbsp;&nbsp;
                                <span><i class="fa fa-commenting-o "></i>{{community.discussionsCount}} Discussions</span>&nbsp;&nbsp;&nbsp;
                                <span>Last Activity &nbsp;&nbsp;<span class="community-last-date">{{community.lastActivityDate | date: 'dd-MMM-yyyy hh'}}:{{getMinute(community.lastActivityDate)}}&nbsp;{{community.lastActivityDate | date: 'a'}}</span></span>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="htcommunity-description left-padding">{{community.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #htDiscussionTemplate>
    <div class="htdiscussion-addedit-dialog">
        <div class="row">
            <div class="col-md-6">
                <span class="htdiscussion-popup-title">Select Discussions</span>
            </div>
            <div class="col-md-6">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="discussionSearchStr" (keyup.enter)="filterDiscussion()" />
                    <button class="btn btn-search btn-white" (click)="filterDiscussion()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="htDiscussionList.length>0">
            <div class="col-md-12 htdiscussion-card-container">
                <div class="htdiscussion-card">
                    <div [ngClass]="{'htdiscussion-divider':i>0}" class="htdiscussion-padding" *ngFor="let thread of filterDiscussions, let i=index">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkbox">
                                    <label class="lblcheck">
                                        <input type="checkbox"
                                               (change)="onDiscussionSelectionChange($event, thread)"
                                               [(ngModel)]="thread.isSelected">
                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                    </label>
                                </div>

                                <div class="thread-image-container">
                                    <img class="thread-owner-thumbnail" [src]="thread.owner.profileUrl" />
                                </div>

                                <div style="padding-left: 87px">
                                    <div class="thread-description">{{thread.title}}</div>
                                    <div class="thread-author-name">{{thread.owner.name}}, {{thread.owner.company}} &nbsp;&nbsp;&nbsp;{{thread.lastCommented}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12  thread-body-container">
                                <span class="link thread-action"><i class="fa fa-commenting-o"></i>&nbsp;<span class="answers">{{thread.answers}} Answers</span></span>
                                <span class="thread-action">
                                    <span class="upvoted"><i class="fa fa-thumbs-up" aria-hidden="true"></i></span>
                                    <span class="upvotes">Upvotes - {{thread.upvote}}</span>
                                </span>
                                <span class="thread-action">
                                    <span class="downvoted"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span>
                                    <span class="downvotes">Downvotes - {{thread.downvote}}</span>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>
</ng-template>

<ng-template #htEventTemplate>
    <div class="htevent-addedit-dialog">
        <div class="row">
            <div class="col-md-6">
                <span class="htevent-popup-title">Select Events</span>
            </div>
            <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <input class="searchtext" style="width: 100%;" placeholder="Keywords" [(ngModel)]="eventSearchStr" (keyup.enter)="searchCommunityEventsByDateAndKeyWord()" />
                        </div>
                        <div class="col-md-5">
                            <my-date-picker  [options]="myDatePickerOptions" [(ngModel)]="eventSearchDate"
                            ></my-date-picker>
                            <!-- <my-date-picker class="date-picker-style" [placeholder]="'Select date'" [options]="myDatePickerOptions" [(ngModel)]="eventSearchDate"
                                            (keyup.enter)="searchCommunityEventsByDateAndKeyWord()"></my-date-picker> -->
                                            <!-- <my-date-picker [options]="myDatePickerOptions" [(ngModel)]="meetingDate" ></my-date-picker> -->
                                        </div>
                        <div class="col-md-3" style="padding-left:0">
                            <button class="btn btn-search btn-white" (click)="searchCommunityEventsByDateAndKeyWord()">
                                <span class="fa fa-search search-icon searchIconWhite"></span>
                                <span> Search</span>
                            </button>
                        </div>
                    </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pe-3">
                <div class="sort-container pull-right">
                    <span>Sort by &nbsp;</span>
                    <div class="pull-right">
                        <select class="form-control" [(ngModel)]="eventSortBy" (change)="getAllEvents()">
                            <option value="1">Date wise</option>
                            <option value="2">Location wise</option>
                        </select>
                    </div>

                </div>

            </div>
        </div>
        <div class="row" *ngIf="htEventList.length>0">
            <div class="col-md-12 htevent-card-container">
                <div class="htevent-card">
                    <div [ngClass]="{'htevent-divider':i>0}" class="htevent-padding" *ngFor="let event of filterEvents, let i=index">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="checkbox">
                                    <label class="lblcheck">
                                        <input type="checkbox"
                                               (change)="onEventSelectionChange($event, event)"
                                               [(ngModel)]="event.isSelected">
                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                    </label>
                                </div>

                                <div class="event-date-container">
                                    <p class="event-day">{{event.eventStartDate | date:'dd'}}</p>
                                    <p class="event-month">{{event.eventStartDate | date:'MMMM'}}</p>
                                    <p class="event-year">{{event.eventStartDate | date:'yyyy'}}</p>
                                    <p class="event-onwards" *ngIf="hasMoreThanOneDay(event.eventStartDate,event.eventEndDate)"> onwards</p>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div>
                                    <div class="htevent-title">{{event.title}}</div>
                                    <div class="htevent-venue">{{event.location}}</div>
                                    <p class="event-eventdates" *ngIf="hasMoreThanOneDay(event.eventStartDate,event.eventEndDate)">20 April 2018 - 22 April 2018</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</ng-template>

<ng-template #htActionCardTemplate>
    <div class="add-actionCard">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Action Card</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="actionSearchStr" (keyup.enter)="actionCardSearch()" />
                    <select class="form-control" [(ngModel)]="actionCardDriver">
                        <option value='0'>Select Pillar</option>
                        <option *ngFor="let driver of driverList" [ngValue]="driver.id">{{ driver.driverTag }}</option>
                    </select>
                    <button class="btn btn-search btn-white" (click)="actionCardSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row cards">
            <div class="col-md-4" *ngFor="let card of filterCards">
                <div class="card d-flex flex-row">
                    <div class="radio">
                        <button class="radio-btn" (click)="changeCard(card.cardId,card.title)">
                            <i class="fa" [ngClass]='card.cardId == selectedCard ? "fa-check-circle": "fa-circle-thin"'></i>
                        </button>
                        <div class="actionNumber">{{card.actionCardNumber}}</div>
                    </div>
                    <div class="title">{{card.title}}</div>

                </div>
            </div>

        </div>

    </div>
</ng-template>
<ng-template #htSurveyTemplate>
    <div class="ht_add_survey" #surveyAccordion>
        <div class="row">
            <div class="col-md-6">
                <span class="ht-title">Select Surveys</span>
            </div>
            <div class="col-md-6">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="surveySearchStr" (keyup.enter)="surveySearch()" />
                    <button class="btn btn-search btn-white" (click)="surveySearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="surveys">
            <div class="row survey" *ngFor="let survey of filterSurveys" [attr.id]="'survey_' + survey.id">
                <div class="col-md-12 pe-0 ps-0">
                    <div class="row">
                        <div class="col-md-1">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           (change)="changeSurvey(survey.id)"
                                           [checked]="isChkedSurvey(survey.id)">
                                    <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-10 title no-padding-column">
                            {{survey.question}}<br />
                            <span class="sub-title">Type - {{survey.answerType}}</span>
                        </div>
                        <div class="col-md-1 arrow" (click)="openSurvey(survey.id)">
    
                        </div>
                    </div>
                    <div class="row choices">
                        <div class="col-md-10 col-md-offset-1">
                            <ul>
                                <li *ngFor="let choice of survey.options"><span>{{choice.answerText}}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #htToolsTemplate>

    <div class="httool-addedit-dialog">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Tools &amp; Resources</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="toolsSearchStr" (keyup.enter)="toolsSearch()" />
                    <select class="form-select" style="font-size: 14px;" [(ngModel)]="selectedDriver">
                        <option value='0'>Select Pillar</option>
                        <option *ngFor="let driver of driverList" [ngValue]="driver.id">{{ driver.driverTag }}</option>
                    </select>
                    <button class="btn btn-search btn-white" (click)="toolsSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>       
        <div class="row tools">
            <ng-container *ngFor="let item of filterTools;let i=index">
                <div class="col-md-4">

                    <div class="tools-list">
                        <div class="top-content">

                            <div class="row">
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" [(ngModel)]="item.isSelected">
                                            <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <p class="tools-title" [innerHtml]="item.title"></p>
                                    <p class="tools-desc" [innerHtml]="item.description"> </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="clearfix" *ngIf="(i+1)%3==0"></div>
            </ng-container>
        </div>

    </div>

</ng-template>

<ng-template #htInsightsTemplate>

    <div class="htInsights-addedit-dialog">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Insights</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="insightsSearchStr" (keyup.enter)="insightsSearch()" />
                    <select class="form-select" style="font-size: 14px;" [(ngModel)]="selectedDriver">
                        <option value='0'>Select Pillar</option>
                        <option *ngFor="let driver of driverList" [ngValue]="driver.id">{{ driver.driverTag }}</option>
                    </select>
                    <button class="btn btn-search btn-white" (click)="insightsSearch()">
                        <span class="fa fa-search  search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row insights">
            <div class="col-md-4" *ngFor="let item of filterInsights">

                <div class="insights-list">
                    <div class="top-content">

                        <div class="row">
                            <div class="col-md-2">
                                <div class="checkbox">
                                    <label style="margin-left: 20px;">
                                        <input type="checkbox" [(ngModel)]="item.isSelected">
                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                                    </label>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <p class="insights-title" [innerHtml]="item.title || item.bodyFormatted"></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</ng-template>

<ng-template #htStartupTemplate>

    <div class="htStartup-addedit-dialog">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Velocity Champion</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="championsSearchStr" (keyup.enter)="championsSearch()" />

                    <button class="btn btn-search btn-white" (click)="championsSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row startup" myMatchHeight="startup-main">


            <div class="col-md-3" *ngFor="let item of filterChampions">
                <div class="startup-main">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="item.isSelected">
                            <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                        </label>
                    </div>
                    <div class="startup-list">

                        <img class="avatar" [src]="item.profileUrl" alt="User Profile">
                        <div class="user-name">{{item.name}} </div>
                        <div class="user-email">EY Velocity Champion </div>
                        <div class="user-comapny">{{item.location}} </div>


                    </div>
                </div>
            </div>

            <!--<div class="col-md-3" *ngFor="let item of filterChampions">

                <div class="startup-main">
                    <div class="top-content">

                        <div class="row">
                            <div class="col-md-2">
                                <div class="checkbox">
                                    <label>
                                     <input type="checkbox" >
                                     <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                                     </label>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="startup-list">
                                    <img class="avatar" [src]="item.profileUrl" alt="User Profile">
                                    <div class="user-name">{{item.name}} </div>
                                    <div class="user-email">EY Velocity Champion </div>
                                    <div class="user-comapny">{{item.location}} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>-->
        </div>

    </div>

</ng-template>


<ng-template #communityStartupTemplate>

    <div class="htStartup-addedit-dialog">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Velocity Champion</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="championsSearchStr" (keyup.enter)="communityChampionsSearch()" />

                    <button class="btn btn-search btn-white" (click)="communityChampionsSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row startup" myMatchHeight="startup-main">


            <div class="col-md-3" *ngFor="let item of filterChampions">
                <div class="startup-main">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="item.isSelected">
                            <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                        </label>
                    </div>
                    <div class="startup-list">

                        <img class="avatar" [src]="item.profileUrl" alt="User Profile">
                        <div class="user-name">{{item.firstName}} </div>
                        <div class="user-email">EY Velocity Champion </div>
                        <div class="user-comapny">{{item.location}} </div>


                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-template>

<ng-template #communityEventTemplate>
    <div class="htevent-addedit-dialog">
        <div class="row">
            <div class="col-md-6">
                <span class="htevent-popup-title">Select Events</span>
            </div>
            <div class="col-md-6">
                <div class="pull-right">
                    <div class="row p-r-15">
                        <div class="col-md-4">
                            <input class="searchtext" style="width: 100%;" placeholder="Keywords" [(ngModel)]="eventSearchStr" (keyup.enter)="searchCommunityEventsByDateAndKeyWord()" />
                        </div>
                        <div class="col-md-5">
                            <my-date-picker class="date-picker-style" [placeholder]="'Select date'" [options]="myDatePickerOptions" [(ngModel)]="eventSearchDate"
                                            (keyup.enter)="searchCommunityEventsByDateAndKeyWord()"></my-date-picker>
                        </div>
                        <div class="col-md-3" style="padding-left:0">
                            <button class="btn btn-search btn-white" (click)="searchCommunityEventsByDateAndKeyWord()">
                                <span class="fa fa-search search-icon searchIconWhite"></span>
                                <span> Search</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="sort-container pull-right">
                    <span>Sort by &nbsp;</span>
                    <div class="pull-right">
                        <select class="form-control" [(ngModel)]="eventSortBy" (change)="sortCommunityEvents()">
                            <option value="1">Date wise</option>
                            <option value="2">Location wise</option>
                        </select>
                    </div>

                </div>

            </div>
        </div>
        <div class="row" *ngIf="htEventList.length>0">
            <div class="col-md-12 htevent-card-container">
                <div class="htevent-card">
                    <div [ngClass]="{'htevent-divider':i>0}" class="htevent-padding" *ngFor="let event of filterEvents, let i=index">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="checkbox">
                                    <label class="lblcheck">
                                        <input type="checkbox"
                                               (change)="onEventSelectionChange($event, event)"
                                               [(ngModel)]="event.isSelected">
                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                    </label>
                                </div>

                                <div class="event-date-container">
                                    <p class="event-day">{{event.eventStartDate | date:'dd'}}</p>
                                    <p class="event-month">{{event.eventStartDate | date:'MMMM'}}</p>
                                    <p class="event-year">{{event.eventStartDate | date:'yyyy'}}</p>
                                    <p class="event-onwards" *ngIf="hasMoreThanOneDay(event.eventStartDate,event.eventEndDate)"> onwards</p>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div>
                                    <div class="htevent-title">{{event.title}}</div>
                                    <div class="htevent-venue">{{event.location}}</div>
                                    <p class="event-eventdates" *ngIf="hasMoreThanOneDay(event.eventStartDate,event.eventEndDate)">{{event.eventStartDate | date:'dd MMMM yyyy'}} - {{event.eventEndDate | date:'dd MMMM yyyy'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</ng-template>

<ng-template #driverActionCardTemplate>
    <div class="add-actionCard-driver">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Action Card</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="actionSearchStr" (keyup.enter)="actionCardSearch()" />
                    <select class="form-control" [(ngModel)]="actionCardDriver">
                        <option value='0'>Select Pillar</option>
                        <option *ngFor="let driver of driverList" [ngValue]="driver.id">{{ driver.driverTag }}</option>
                    </select>
                    <button class="btn btn-search btn-white" (click)="actionCardSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row cards">
            <div class="col-md-4" *ngFor="let card of filterCards">
                <div class="row card-n">
                    <div class="col-md-3">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" (change)="onActionCardSelected($event, card)" [(ngModel)]="card.isSelected">
                                <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                            </label>
                        </div>
                        <div class="actionNumber">{{card.actionCardNumber}}</div>


                    </div>
                    <div class="col-md-9">

                        <div class="title">{{card.title}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-template>

<ng-template #fopActionCardTemplate>
    <div class="add-actionCard-driver">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Action Card</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" [maxlength]="250" placeholder="Keywords" [(ngModel)]="actionSearchStr" (keyup.enter)="actionCardFopSearch()" />
                    <button class="btn btn-search btn-white" (click)="actionCardFopSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row cards">
            <div class="col-md-4" *ngFor="let card of filterCards">
                <div class="row card-n">
                    <div class="col-md-3">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" (change)="onActionCardSelected($event, card)" [(ngModel)]="card.isSelected">
                                <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                            </label>
                        </div>
                        <div class="actionNumber">{{card.actionCardNumber}}</div>


                    </div>
                    <div class="col-md-9">

                        <div class="title">{{card.title}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-template>

<ng-template #driverInsightsTemplate>

    <div class="htInsights-addedit-dialog">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Insights</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="insightsSearchStr" (keyup.enter)="insightsSearchDriver()" />
                    <!--<select class="form-control" [(ngModel)]="selectedDriver">
                        <option value='0'>Select driver</option>
                        <option *ngFor="let driver of driverList" [ngValue]="driver.id">{{ driver.driverTag }}</option>
                    </select>-->
                    <button class="btn btn-search btn-white" (click)="insightsSearchDriver()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row insights">
            <div class="col-md-4" *ngFor="let item of filterInsightsDriver">

                <div class="insights-list">
                    <div class="top-content">

                        <div class="row">
                            <div class="col-md-2">
                                <div class="checkbox">
                                    <label style="margin-left: 20px;">
                                        <input type="checkbox" [(ngModel)]="item.isSelected">
                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                                    </label>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <p class="insights-title" [innerHtml]="item.bodyFormatted"></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</ng-template>
<ng-template #addTagTemplate>
    <div class="addTag">
        <span class="ht-title">{{editTagCategoryId?'Edit':'Add'}} tags</span>
        <div class="tag-label" [ngClass]="{'editTagLabel': editTagCategoryId}">Category</div>
        <div class="tagInput" *ngIf="editTagCategoryId">{{editTagCategoryName}}</div>
        <select *ngIf="!editTagCategoryId" class="form-select" [(ngModel)]="categoryName" (change)="noCategory = false">
            <option *ngFor="let cat of category" [ngValue]="cat.categoryId">
                {{ cat.categoryName }}
            </option>
        </select>
        <div *ngIf="noCategory"
             class="alert alert-danger">
            Please select a category.
        </div>
        <div class="tag-label">Tag<span class="note" *ngIf="!editTagCategoryId"> [ Seperate entries by comma or newline for creating multiple tags ]</span></div>
        <textarea *ngIf="!editTagCategoryId" class="form-control" type="text" [(ngModel)]="tagName" (ngModelChange)="alreadyTagExists = false;"></textarea>
        <input *ngIf="editTagCategoryId" class="form-control editTagInput" [(ngModel)]="tagName" (ngModelChange)="alreadyTagExists = false;" />
        <div *ngIf="noTag"
             class="alert alert-danger">
            Please enter a tag name.
        </div>
        <div *ngIf="alreadyTagExists"
             class="alert alert-danger"><span *ngFor="let tag of duplicateTags;let last = last">"{{tag}}"<span *ngIf="!last">{{','}}</span></span>{{this.warningMsg}}</div>
    </div>
</ng-template>
<ng-template #addCategoryTemplate>
    <div class="addCategory">
        <span class="ht-title">{{editCategoryId?'Edit':'Add'}} category</span>
        <div class="category-label">Category</div>
        <input class="form-control" [(ngModel)]="categoryName" (ngModelChange)="alreadyExist = false;" />
        <div *ngIf="alreadyExist"
             class="alert alert-danger">
            {{catWarning}}
        </div>
    </div>
</ng-template>


<ng-template #addToolsTemplate>

    <div class="httool-addedit-dialog">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Tools &amp; Resources</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="toolsSearchStr" (keyup.enter)="toolsSearch()" />
                    <select class="form-select" style="font-size: 14px;" [(ngModel)]="selectedDriver">
                        <option value='0'>Select Pillar</option>
                        <option *ngFor="let driver of driverList" [ngValue]="driver.id">{{ driver.driverTag }}</option>
                    </select>
                    <button class="btn btn-search btn-white" (click)="toolsSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="no-tools" *ngIf="filterTools.length == 0">
            0 result(s) found
        </div>
        <div class="row tools">
            <ng-container *ngFor="let item of filterTools;let i=index">
                <div class="col-md-4">

                    <div class="tools-list">
                        <div class="top-content">

                            <div class="row">
                                <div class="col-md-2">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" [(ngModel)]="item.isSelected">
                                            <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <p class="tools-title" [innerHtml]="item.title"></p>
                                    <p class="tools-desc-no-clamp" [innerHtml]="item.description"> </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="clearfix" *ngIf="(i+1)%3==0"></div>
            </ng-container>
        </div>

    </div>

</ng-template>


<ng-template #subDriverActionCardTemplate>
    <div class="add-actionCard-driver">
        <div class="row">
            <div class="col-md-4">
                <span class="ht-title">Select Action Card</span>
            </div>
            <div class="col-md-8">
                <div class="pull-right">
                    <input class="searchtext" placeholder="Keywords" [(ngModel)]="actionSearchStr" (keyup.enter)="subDriverActionCardSearch()" />
                     <button class="btn btn-search btn-white" (click)="subDriverActionCardSearch()">
                        <span class="fa fa-search search-icon searchIconWhite"></span>
                        <span> Search</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row cards">
            <div class="col-md-4" *ngFor="let card of filterCards">
                <div class="row card-n">
                    <div class="col-md-3">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" (change)="onActionCardSelected($event, card)" [(ngModel)]="card.isSelected">
                                <span class="cr"><i class="cr-icon fa fa-check"></i></span>

                            </label>
                        </div>
                        <div class="actionNumber">{{card.actionCardNumber}}</div>


                    </div>
                    <div class="col-md-9">

                        <div class="title">{{card.title}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-template>