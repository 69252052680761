export class Constants {
    APP: any = {
        TITLE: 'Velocity',
        ERROR: 'Error',
        INFO: 'Info',
        SUCCESS: 'Success',
        WARN: 'Warning',
        LOGIN_DEV:false
    };
    FOCUS: any = {
        SEVEN_DRIVERS_API: 'growthDrivers',
    };
    AUTH: any = {
        API_LOGIN: 'user/authenticate',
        API_VERIFY_EMAIL: 'user/IsRegisteredUser',
        API_CHECK_AUTH: 'user/CheckAuth',
        API_REFRESH_TOKEN: 'user/RefreshToken',
        AUTO_LOGOUT_API_URL: 'user/Logout',//edit
    }
    ACT: any = {

    }
    CONNECT: any = {
        GET_PROFILE_DETAILS: 'user/getProfile',
        GET_TOPICS: 'community/getTopics',
        GET_ALL_COMMUNITIES: 'Community/AllCommunities',
        GET_MANAGE_COMMUNITY_LIST: 'admin/GetAllCommunitiesForAdmin',
        COMMUNITY_APPROVE_DELETE_ACTION: 'admin/SetCommunityAdminAction',
        GET_ALL_AUTHORISED_COMMUNITIES:'Community/AllCommunities/Authorised'
    }
    GENERAL: any = {
        PATTERN_CHARACTERS: /^[a-zA-Z\s]+$/,
        PATTERN_EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
        GET_LOOK_UP_DATA: "lookupData",
        PATTERN_NON_NUMBER: /^([^0-9]*)$/,
        PATTERN_CHARACTERS_SLASH: /^[a-zA-z \/]+$/,
        PATTERN_MOBILE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
        PATTERN_CHARACTERS_SPECIAL: /^[a-zA-Z0-9\s@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/,
        PATTERN_ALPHANUMERIC: /^[a-zA-Z0-9\s]+$/,
        PATTERN_CHARACTER_SOME_SPECIALCHARACTER: /^[a-zA-Z0-9\s!@#$&*()_;:?.,-\\'�]*$/,
        PATTERN_URL: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
        PATTERN_URL_SOCIAL_MEDIA: /^(http:\/\/|https:\/\/)([\da-z\.-]+)\.()/i ,
        LABEL_MENU: "label-menu"

    }
    DASHBOARD: any = {

    };
    USER: any = {
        GET_MEMBER_LIST: 'admin/members',
        GET_Role_LIST: 'admin/roles',
        GET_MEMBERSHIP_LIST: 'admin/memberships',
        GET_USERTYPE_LIST: 'admin/usertypes',
        ASSIGN_ROLE_MEMBERSHIP:'admin/AssignRoleMembership',
        MEMBER_ACTION: 'admin/memberAction',
        PROFILE: 'user/myprofile', 
        
        USER_ACTION: 'admin/systemUserAction',
        MEMBER_BULK_ACTION: 'admin/MemberBulkAction',
        USER_BULK_ACTION: 'admin/UsersbulkAction',
        ACCOUNT_DELETION: 'user/ApproveRejectDeleteRequest',
        GET_IMPACTED_COMMUNITIES: 'admin/GetImpactedCommunityDetailsForUser'
    };

    NOTIFICATION: any = {

    };

    CONTENT: any = {
        GET_HOT_TOPIC_SECTIOn_COUNT: "admin/getHotTopicSectionCount",
        GET_ALL_COMMUNITIES: "Community/GetAllCommunitiesForHT",
        ADD_COMMUNITY_HT: "Community/AddCommunityToHT",
        DELETE_COMMUNITY_HT: "Community/DeleteCommunityFromHT",
        GET_HT_COMMUNITIES: "Community/GetHTCommunities",
        GET_ALL_DISCUSSIONS: "Discussions/GetAllDiscussionsForHT",
        ADD_DISCUSSION_HT: "Discussions/AddDiscussionsToHT",
        DELETE_DISCUSSION_HT: "Discussions/DeleteDiscussionFromHT",
        GET_HT_DISCUSSIONS: "Discussions/GetHTDiscussions",
        GET_ALL_EVENTS: "Events/GetAllEventsForHT",
        ADD_EVENT_HT: "Events/AddEventsToHT",
        DELETE_EVENT_HT: "Events/DeleteEventFromHT",
        GET_HT_EVENTS: "Events/GetHTEvents",
        GET_HT_SUBTOPICS: "admin/getSubTopics",
        GET_HT_ACTIONCARDS: "admin/actionCards",
        SAVE_HT_SUBTOPICS: "admin/SaveOrUpdateSubTopic",
        DELETE_HT_SUBTOPICS: "admin/DeleteSubTopic",
        GET_HT_SUBTOPIC_CONSIDERATION: "admin/getSubTopicConsiderations",
        SAVE_HT_SUBTOPIC_CONSIDERATION: "admin/subtopicConsideration",
        GET_HOT_TOPICS_LIST: 'admin/topics',
        GET_HT_TOOLS_RESOURCES: 'admin/topicTools',
        GET_HT_INSIGHTS: 'admin/topicInsights',
        GET_HT_CHAMPIONS: 'admin/topicChampions',
        ADD_HT_INSIGHTS: 'admin/saveTopicInsights',
        GET_SURVEYS: 'admin/topicSurveys',
        SAVE_SURVEYS: 'admin/saveTopicSurveys',
        REORDER_SURVEYS: 'admin/swapSurveyOrder',
        UPLOAD_MEDIA: 'admin/uploadMedia',
        UPLOAD_HTML:'Content/UploadPlatformContent',
        ADD_HT_TOOLS: 'admin/saveTopicTools',
        ADD_HT_CHAMPIONS: 'admin/saveTopicChampions',
        DELETE_HT_CARD: 'admin/DeleteCardFromHT',
        GET_HT_DETAILS: 'admin/getHotTopicDetails',
        SAVE_HOT_TOPICS: 'admin/saveHotTopic',
        UPDATE_HT_DETAILS: 'admin/updateHotTopic',

        GET_DRIVER_DETAILS: 'GetDriverSubDriverThemeDetailsForAdmin',
        GET_ALL_INSIGHTS: 'admin/GetAllInsights',
        UPDATE_DRIVER_DETAILS: 'SaveDriverSubDriverThemeDetails',
        GET_SUB_DRIVER_DETAILS: 'GetDriverSubDriverThemeDetailsForAdmin',
        UPDATE_SUB_DRIVER_DETAILS: 'admin/updateSubDriverDetails',
        GET_THEME_DETAILS: 'GetDriverSubDriverThemeDetailsForAdmin',
        UPDATE_THEME_DETAILS: 'admin/updateThemeDetails',
        DELETE_DRIVER: 'admin/driver/delete',
        DELETE_SUB_DRIVER: 'DeleteSubDriverTheme',
        DELETE_THEME: 'admin/theme/delete',
        DELETE_INSIGHT: 'admin/insight/delete',
        GET_7DRIVER_DETAILS: 'appcontent/Get7driverLandingContent',
        SAVE_7DRIVER_DETAILS: 'appcontent/Save7driverLandingContent',
        GET_7DRIVERLIST: 'GetDriverSubDriverThemeList',
        GET_SUBDRIVER_THEME_DETAILS: 'GetDriverSubDriverThemeDetailsForAdmin',
        SAVE_SUBDRIVER_THEME_DETAILS: 'SaveDriverSubDriverThemeDetails',
        GET_ACTIONCARDS_SUBDRIVER: 'admin/GetActionCardsforSubDriver',
        DELETE_ACTIONCARDS: 'admin/DeleteActionCardsfromSubDriver',
        GET_HELP_TREE: 'help/TreeList',
        GET_HELP_CONTENT: 'help/HelpContentDetails',
        SET_HELP_ACTION: 'help/SetAction',
        SAVE_HELP_CONTENT: 'help/SaveHelpContent',
        GET_PLATFORM_DETAILS: 'Content/GetPlatformContentDetail',
        GET_PLATFORM_FILE_CONTENT:'Content/GetPlatformFileContent',
        SAVE_PLATFORM_DATA: 'Content/SavePlatformContent',
        GET_TC_LIST: 'Content/GetTCList',
        SET_TC_ACTION: 'Content/SetTCAction',
        GET_COPY_RIGHT_DETAILS: 'Content/GetCopyright',
        SAVE_COPY_RIGHT_DETAILS: 'Content/SaveCopyright',
        GET_PROFILE_CHANGE_COLUMN: 'Admin/UserProfileSearchFields',
        GET_USER_COMMUNITY_DETAILS: 'admin/GetUserCommunityDetails',
        GET_COOKIE_DETAILS: 'Content/GetCookiePolicy',
        SAVE_COOKIE_DETAILS: 'Content/SaveCookiePolicy',
        GET_ANNOUNCEMENT_DETAILS: 'admin/getAnnouncementDetails',
        SAVE_ANNOUNCEMENT_DETAILS: 'admin/saveAnnouncement',
        GET_ANNOUNCEMENTS_FOR_ADMIN: 'admin/getAnnouncementsForAdmin',
        SAVE_ANNOUNCEMENT: 'admin/saveAnnouncement',
        UPDATE_ANNOUNCEMENT_ORDER: 'admin/updateAnnouncementOrder',
        UPDATE_ANNOUNCEMENT: 'admin/updateAnnouncement',
        DELETE_ANNOUNCEMENT: 'admin/deleteAnnouncement',
        GET_QUICK_LINK_DETAILS: 'admin/getQuickLinkDetails',
        SAVE_QUICK_LINK: 'admin/saveQuickLink',
        GET_QUICK_LINK_FOR_ADMIN: 'admin/getQuickLinkForAdmin',
        UPDATE_QUICK_LINK_ORDER: 'admin/updateQuickLinkOrder',
        UPDATE_QUICK_LINK_STATUS: 'admin/UpdateQuickLinkStatus',
        UPDATE_QUICK_LINK: 'admin/updateQuickLink',
        UPLOAD_QUICK_LINK_MEDIA: 'admin/uploadQuickLinkMedia'
    };

    REFERENCE: any = {
        GET_API_REF_INSIGHTS: 'admin/insights',
        GET_API_REF_INSIGHTS_DETAILS: 'admin/getInsightDetails',
        GET_EVENTS: 'admin/events',
        SAVE_EVENT: 'admin/saveEvent',
        GET_EVENT_DETAILS: 'event/details',
        UPLOAD_EVENT_MEDIA: 'admin/uploadEventMedia',
        UPLOAD_INSIGHT_FILE: 'admin/uploadInsightMedia',
        POST_API_CARD_ACTION: 'admin/cardActions',
        SAVE_API_INSIGHT_DETAILS: 'admin/SaveInsight',
        GET_ECOSYSTEM: 'admin/ecoSystemCards',
        APPROVE_CARD: 'admin/approveCard',
        GET_ECOSYSTEM_CARD_DETAILS: 'cards/EcosystemCardDetails',
        GET_ECOSYSTEM_DETAILS: 'admin/EcosystemCardDetails',
        SAVE_ECOSYSTEM_CARD: 'admin/saveEcoSystemCard',
        SAVE_ECOSYSTEM_MEDIA: 'admin/uploadEcoMedia',
        GET_TAGS_LIST: 'lookup/tags',
        GET_API_REF_CHAMPION_LIST: 'admin/GetAllEYChampions',
        UPDATE_CHAMPION_ACTION: 'admin/SetAdminChampionActions',
        GET_API_REF_CHAMPION_DETAILS: 'admin/GetAdminChampionDetails',
        SAVE_CHAMPION: 'admin/SaveVelocityChampion',
        GET_ACTIONCARD_LIST: 'admin/getActionCards',
        EDIT_ADD_ACTIONCARD: 'admin/saveActionCard',
        GET_ACTIONCARD_DETAILS: 'admin/getActionCardDetails',
        GET_TOOLS_AND_RESOURCES_LIST: 'admin/GetToolsAndResource',
        GET_TOOL_DETAILS: 'admin/toolDetails',
        SAVE_TOOL: 'admin/saveTool',
        GET_AVAILABLE_USERS: 'admin/GetAdminUserUserLookup',
        UPLOAD_CHAMPION_PROFILE: 'upload/UploadChampionProfileImage',
        GET_REF_SURVEY_LIST: 'admin/GetSurveyListForAdmin',
        GET_REF_SURVEY_DETAILS: 'admin/GetSurveyDetails',
        UPDATE_SURVEY: 'admin/SaveSurvey',
        UPDATE_SURVEY_ACTION: 'admin/SurveyAction',
        DELETE_TAG: 'admin/DeleteTag',
        DELETE_TAG_CATEGORY: 'admin/DeleteTagCategory',
        ADD_EDIT_TAG_CATEGORY: 'admin/SaveTagCategoty',
        ADD_EDIT_TAG: 'admin/SaveTag',
        //GET_ACTION_CARDS: 'admin/GetToolsRelatedActionCards',
        GET_RELATED_TOOLS: 'admin/GetToolsRelatedToolCards',
       // ADD_RELATED_CARDS: 'admin/AddRelatedCardsToTool',
        DELETE_RELATED_CARDS: 'admin/DeleteRelatedCardFromTool',
        GET_TOOL_SECTION_COUNT: 'admin/GetToolsCardCount',
        UPLOAD_RESOURCE_PDF: 'admin/uploadResource',
        SAVE_SHOWCASE_VIDEO: 'admin/SaveShowcaseVideos',
        GET_SHOWCASE_VIDEO: 'admin/GetShowcaseVideo',
        UPDATE_SHOWCASE_VIDEO_ACTION: 'admin/SetShowcaseVideosAction',
        GET_SHOWCASE_VIDEO_LIST: 'admin/GetShowcaseVideosList',
        GET_LABELS: 'appcontent/items',
        SAVE_LABELS: 'appcontent/saveItems',
        SAVE_LABEL_MEDIA: 'appcontent/saveItems',
        SAVE_MEDIA_STATUS: 'appcontent/saveMediaStatus',
        GET_LABEL_MENU: 'appcontent/sections',
        GET_RECOMMENDED_LIST: 'admin/GetRecommendedToolsList',
        ADD_RECOMMENDED_LIST: 'admin/AddRecommendedTool',
        DELETE_RECOMMENDED_LIST: 'admin/DeleteRecommendedTool',
        GET_MEMBERSHIP_CODES: 'Admin/GetMembershipList',
        GET_MEMBERSHIP_CODE_DETAILS: 'Admin/GetMembershipDetail',
        ADD_EDIT_MEMBERSHIP_CODE: 'Admin/SaveMembership',
        DEACTIVATE_MEMEBRSHIP_CODE: 'Admin/SetMembershipAction',
        GET_TOOLS_POPUP_DETAILS: 'admin/GetToolsPopUpDetails',
        GET_SURVEY_POPUP_DETAILS: 'admin/GetMembershipClientTypeChangeForSurveyPopUp',
        GET_EVENTS_POPUP_DETAILS: 'admin/GetEventUnlinkCount',
        GET_MEMEBRSHIP_ASSIGNED_COUNT: 'Admin/GetMembershipAssignedCounts',
        DELETE_QUICK_LINK:'admin/deleteQuickLink'
    }
    COMMUNITY: any = {
        GET_DISCUSSION_LIST: 'discussions/admin',
        DISCUSSION_DETAILS: 'discussions/admin/discussionDetails ',
        DISCUSSION_ANSWERD: 'discussions/admin/answers',
        DISCUSSION_MODERATE: 'discussions/admin/moderate',
        BAN_TRUST_USER: 'User/UpdateStatus',
        GET_ANSWERS_LIST: 'answers/admin',
        GET_ANSWER_DETAILS: 'discussions/admin/answerDetails',
        ANSWER_MODERATE: 'answer/admin/moderate',
        GET_MODERATOR_LIST: 'community/GetAdminUserAsModerator',
        SAVE_COMMUNITY_OVERVIEW: 'admin/SaveCommunity',
        GET_COMMUNITY_OVERVIEW: 'admin/GetCommunity',
        GET_COMMUNITY_SECTION_COUNT: "community/GetCommunitySectionCount",
        UPDATE_COMMUNITY_OVERVIEW: "admin/UpdateCommunity",
        GET_COMMUNITY_CHAMPIONS: 'community/GetAdminCommunityChampions',
        GET_COMMUNITY_EVENTS: 'community/GetAdminCommunityEvents',
        ADD_DELETE_COMMUNITY_CHAMPIONS: 'community/AddDeleteCommunityChampion',
        GET_MEMBER_COMMUNITY: 'community/GetCommunityMembers',
        SAVE_COMMUNITY_MEMBER_ACTION: 'community/SetCommunityMemberActions',
        ADD_DELETE_COMMUNITY_EVENTS: 'community/AddDeleteCommunityEvent',
        LINKED_HOT_TOPICS: 'community/GetCommunityPopupCount',
        SEARCH_COMMUNITY_OWNER: 'community/GetCommunityOwnerList',
        SEARCH_COMMUNITY_MODERATOR: 'community/SearchModerator'
    };
    ENQUIRIES: any = {
        GET_ENQUIRIES_LIST: 'Marketing/GetContactUsList',
        GET_CONTACTS_DETAILS: 'Marketing/GetMarketingContactDetail',
        DELETE_CONTACT: 'Marketing/DeleteMarketingContact',
        GET_MEMBER_CONTACT_LIST: 'GetMemberContactUsList',
        GET_MEMBER_CONTACT_DETAILS: 'GetMemberContactUsDetail',
        SAVE_MEMBER_CONTACT_ACTION: 'SaveMemberContactUsAction'
    };

    SYSTEM: any = {
        GET_USER_LIST: 'admin/systemUsers',
        GET_PROFILE_HISTORY: 'admin/UserProfileHistoryList',
        GET_PROFILE_CHANGE_DETAILS: 'admin/UserProfileHistoryDetail',
        APPROVE_USER: 'admin/approveUser',
        GET_ROLE_LIST: 'User/internalRoles',
        GET_ROLE_DETAILS: 'admin/rolePermissions',
        GET_ROLE_EDIT: 'admin/saveRolePermissions',
        APPOVE_OR_DELETE_USER_ROLE: 'admin/approveUserRole',
        SAVE_SYSTEM_USER: 'admin/saveSystemUsers',
        GET_INTERNAL_ROLES: 'User/activeInternalRoles',
        GET_USER_DETAILS: 'admin/systemUserDetails',
        GET_SOCIAL_MEDIA_LINKS: 'Content/SocialMediaLinks',
        SAVE_SOCIAL_MEDIA_LINKS: 'Content/SetSocialMediaLinks',
        NOTIFICATION_FREQUENCY: 'notification/GetNotificationFrequency',
        NOTIFICATION_SETTING_LIST: 'notification/GetNotificationTypesForAdmin',
        SAVE_NOTIFICATION_SETTING:'notification/SetNotificationTypes',
        GET_LANGUAGE_LIST: 'languages',
        GET_INACTIVE_LANGUAGES: 'admin/languages',
        SAVE_LANGUAGE: 'saveLanguage',
        GET_LANGUAGE: 'admin/getlanguagedetails',
        UPLOAD_LANGUAGE: 'admin/uploadLanguageTemplate',
        GET_ACTION_SETTINGS: 'Content/GrowthPlanActionStatusSettings',
        SAVE_ACTION_SETTINGS: 'Content/SetGrowthPlanActionStatusSettings',
        GET_INDUSTRIES: 'admin/getIndustriesForAdmin',
        GET_INDUSTRY: 'admin/getIndustryDetails',
        UPDATE_INDUSTRY_ORDER: 'admin/updateIndustryOrder',
        UPDATE_SHOW_INDUSTRY_ON_PORTAL: 'admin/showIndustryOnPortal',
        SAVE_INDUSTRY: 'admin/saveIndustry',
        DELETE_INDUSTRY: 'admin/deleteIndustry',
        GET_ABOUT_VELOCITY_LIST: 'admin/getHowDidUserHearAnswersForAdmin',
        GET_ABOUT_VELOCITY: 'admin/getHowDidUserHearDetails',
        UPDATE_ABOUT_VELOCITY_ORDER: 'admin/updateAnswersOrder',
        UPDATE_SHOW_ABOUT_VELOCITY_ON_PORTAL: 'admin/showAnswerOnPortal',
        SAVE_ABOUT_VELOCITY: 'admin/saveHowDidUserHearAnswer',
        DELETE_ABOUT_VELOCITY: 'admin/deleteAnswer',
        GET_COVER_IMAGES: 'growthPlan/viewReportCover',
        UPLOAD_COVER_IMAGE: 'upload/coverImage',
        UPDATE_REPORT_COVER: 'growthPlan/updateReportCover',
        GET_TEMPLATES: 'growthSummary/GetCustomReportTemplates',
        GET_TEMPLATE: 'growthSummary/GetCustomReportTemplateDetails',
        SAVE_TEMPLATE_dDETAILS: 'growthSummary/saveTemplateDetails',
        GET_LABEL_SECTIONS: 'appcontent/sections/true',
        GET_SECTION_LABELS: 'appcontent/items',
        GET_BOILER_PLATE: 'growthPlan/boilerplate',
        SAVE_BOILER_PLATE_MEDIA: 'growthPlan/updateBoilerPlate',
    };

    LOGGING: any = {
        LOG_ACTIONS: 'eventLog',
    }

    PATTERN: any = {
        PATTERN_ALPHANUMERIC_DOT: /^[\w\-\.\_\s]+$/,
        PATTERN_ALPHANUMERIC_SPACE: /^[\w\s]+$/,
        PATTERN_ALPHANUMERIC_QUESTION: /^[\w\-\.,?\_\s]+$/,
        PATTERN_CHARECTERS: /^[a-zA-Z\s]+$/,
        PATTERN_NUMERIC: /^[0-9+\-]+$/,
        PATTERN_EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        PATTERN_ALPHANUMERIC_SPECIAL_CHARECTERS: /^[\w\#\$\%\&\*\@\!\-\'\"\,\.\/\s]+$/,
        PATTERN_ALPHANUMERIC_HTML_CHARECTERS: /^[\w\#\$\%\&\*\@\?\(\)\+\!\-\'\"\,\.\/\<\>\\/\s\^\;\:\=\~]+$/,
        EXCLUDE_CHAR_LIST: /^[^\@%|={}&~^\\]+$/

    };

    USER_LOOKUP: any = {
        AD_SEARCH: 'UserLookup/searchADUsers'
    };

    TIMEOUT_HANDLER_OPTIONS: any = {
        //in seconds
        WARNING_DURATION: 1500,//25 minutes
        LOGOUT_DURATION: 1740, // 29 minutes
        FORCED_LOGOUT_DURATION:36000//10 hours

        //WARNING_DURATION: 420,
        //LOGOUT_DURATION: 600,
        //FORCED_LOGOUT_DURATION: 1500

        //WARNING_DURATION: 60,
        //LOGOUT_DURATION: 90,
        //FORCED_LOGOUT_DURATION: 180
    }

    REPORT: any = {
        AUDIT_REPORT: 'report/AuditReport',
        LANGUAGE_USAGE_REPORT: 'report/languageUsageReport',
        ANALYTICS_REPORT: 'report/AnalyticsReport',
        USER_ACCESS_REPORT: 'report/UserAccessReport',
        USER_REPORT: 'report/UserReport',
        REFERENCE_REPORT: 'report/ReferenceDataReport',
        ENQUIRY_REPORT: 'report/EnquiryReport',
        SEVEN_DRIVER_SESSION_REPORT:'report/sessionReport',
        TRANSLATION_REPORT:'admin/languagetranslation'
    }

  DATAIMPORT:any = {
     GET_GP:'growthPlan/FacilitatedGrowthPlans',
     GET_GROWTH_PLAN_DETAILS:'admin/getGrowthPlanDetailsForAdmin',
     SEARCH_MEMBER_SHARE:'admin/user/share',
     SEARCH_FACILITATOR:'admin/GetAllFacilitators',
     SAVE_GP:'admin/growthplan/update'
   }

    SEVEN_DRIVER: any = {
        GET_FOPS: 'admin/fieldsOfPlay',
        SAVE_FOP: 'admin/setFieldsOfPlay',
        SAVE_FOP_MEDIA: 'admin/UploadFieldsOfPlayMedia',
        GET_ACTIONS: 'admin/getAllActions',
        GET_FOP: 'admin/getFieldsOfPlay',
        MAP_ACTIONS_TO_FOP: 'admin/mapFieldsOfPlayAction',
        DEACTIVATE_FOP: 'admin/deActivateFieldsOfPlay',
        GET_NOTIFICATIONS: 'admin/getGrowthPlanFieldsOfPlay',
        DOWNLOAD_TRANSLATION: 'fieldsOfPlayReport',
        MARK_COMPLETE: 'admin/MarkAsCompleteGrowthPlan',
        SAVE_SOLUTION: 'admin/saveSolution',
        GET_SOLUTIONS: 'admin/getSolutionList',
        GET_SOLUTION_DETAILS: 'admin/getSolutionDetails',
        DEACTIVATE_SOLUTION: 'admin/deActivateSolution',
        MAP_ACTIONS_TO_SOLUTION: 'admin/mapSolutionAction',
        GET_SOLUTION_NAME: 'admin/getSolutionName'
    } 

    constructor() { }
}
