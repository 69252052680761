import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VelocityService } from '../../service/velocity.service';
import { BaseComponent } from '../../component/base.component';
import { LogAction, LogTypes } from 'src/app/service/model/log-models';
import { LogService } from 'src/app/service/log.service';

@Component({
    moduleId: module.id.toString(),
    selector: "app-deactivate",
    templateUrl: './deactivate.component.html',
    styleUrls: ['./deactivate.component.scss']
})

export class DeactivateComponent extends BaseComponent implements OnInit {
    returnedValue: any = 'closed';
    @ViewChild('infoModal',{static:true}) infoModal: any;

    actionCards = [];
    filterCards = [];
    actionSearchStr = '';
    toItemId = 0;
    dialogData: any = {};
    subject: any;
    observable: Observable<any>;
    data:any = [];
    public config = {
        animated: false,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };
    actionSelected: boolean = false;str
    pageType = "Fields of Play";

    constructor(private injector: Injector,
        private velocityService: VelocityService,
        private logTypes: LogTypes,
        private logService: LogService) {
        super(injector);
        //this.dialogType = this.injector.get('dialogType');
        this.dialogData = this.injector.get('dialogData');
    }

    ngOnInit() {
        switch (this.dialogData.type) {
            case 'fop': {
                this.getAllFoPActionCard();
                this.getFOPS();
                this.pageType = "Fields of Play";
                break;
            }

            case 'solution' : {
                this.getAllSolutionActionCard();
                this.getSolutions();
                this.pageType = "Solution";
                break;
            }

            default : 
                break;
        }
    }
    
    private closeDialog() {
        this.infoModal.hide();
    }

    getAllFoPActionCard() {
        this.velocityService.getMappedActions(this.dialogData.data.id).subscribe((data: any) => {
            this.actionCards = data.actionCards;
            this.filterCards = data.actionCards;
        });
    }

    getAllSolutionActionCard() {
        this.velocityService.getMappedActionsForSolution(this.dialogData.data.id).subscribe((data: any) => {
            this.actionCards = data.actioncards;
            this.filterCards = data.actioncards;
        });
    }

    getFOPS() {
        this.velocityService.getFops().subscribe((data: any) => {
            this.data = data;
        });
    }

    getSolutions() {
        this.velocityService.getSolutionName().subscribe((data: any) => {
            this.data = data.map(dataItem => {
                dataItem.isActive = true;
                return dataItem;
            });
        });
    }

    actionCardSearch() {
        this.filterCards = [];
        if (this.actionSearchStr != '') {
            var filter = this.actionSearchStr.toUpperCase();

            this.actionCards.forEach((obj) => {

                if (obj.title.toUpperCase().indexOf(filter) > -1) {
                    this.filterCards.push(obj);
                }
                let number = obj.actionCardNumber.toString();
                if (number.indexOf(+this.actionSearchStr) > -1) {
                    this.filterCards.push(obj);
                }
            });
        } else {
            this.filterCards = this.actionCards;
        }
        console.log(this.filterCards);
    }

    openDialog() {
        this.returnedValue = "Closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.infoModal.config = this.config;
        this.infoModal.show();
        return this.observable;
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }

    okayClicked() {
        switch (this.dialogData.type) {
            case 'fop': {
                this.velocityService.deactivateFop(this.dialogData.data.id).subscribe((data: any) => {
                    if (data) {
                        this.returnedValue = { status: "Success" };
                        this.closeDialog();
                    }
                });

                break;
            }

            case 'solution' : {
                this.velocityService.deactivateSolution(this.dialogData.data.id).subscribe((data: any) => {
                    if (data) {
                        this.returnedValue = { status: "Success" };
                        this.insertIntoLogSolution(this.dialogData.data.id, this.logTypes.DEACTIVATED_TRANSFORMATIVE_SOLUTION);
                        this.closeDialog();
                    }
                });

                break;
            }

            default : 
                break;
        }
    }

    //onModalClosed($event: any) {
    //    this.subject.next(this.returnedValue);
    //}


    mapAction() {
        switch (this.dialogData.type) {
            case 'fop': {
                let params = {
                    'fromFieldsOfPlayId': this.dialogData.data.id,
                    'toFieldsOfPlayId': this.toItemId
                };
                let selectedActions = [];
                this.actionCards.map(Item => {
                    if (Item.isSelected) {
                        selectedActions.push(Item.cardId);
                    }
                });
        
                params['actionIds'] = selectedActions;
        
                this.velocityService.mapActionsToFop(params).subscribe((data: any) => {
                    if (data) {
                        console.log(data);
                        this.toItemId = 0;
                        this.getAllFoPActionCard();
                    }
                });

                break;
            }

            case 'solution' : {
                let params = {
                    'fromSolutionId': this.dialogData.data.id,
                    'toSolutionId': this.toItemId
                };
                let selectedActions = [];
                this.actionCards.map(Item => {
                    if (Item.isSelected) {
                        selectedActions.push(Item.cardId);
                    }
                });
        
                params['actionIds'] = selectedActions;
        
                this.velocityService.mapActionsToSolution(params).subscribe((data: any) => {
                    if (data) {
                        this.toItemId = 0;
                        this.getAllSolutionActionCard();
                    }
                });

                break;
            }

            default : 
                break;
        }
    }

    cancelClicked() {
        this.returnedValue = { status: "Success" };
        this.subject.next(this.returnedValue);
        this.closeDialog();
    }

    onActionCardSelected(e, card) {
        card.isSelected = e.target.checked;

        let actionSelected = false;
        this.actionCards.map(Item => {
            if (Item.isSelected) {
                actionSelected = true;
            }
        });
        if (actionSelected) {
            this.actionSelected = true;
        } else {
            this.actionSelected = false;
        }
    }

    insertIntoLogSolution(id : number ,logType : string) {
        let log = new LogAction();
        log.solutionId = id;
        log.action = logType;
        this.logService.logAction(log);
    }
}