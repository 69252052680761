import { Component, OnInit, Injector, Input, ElementRef, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { combineLatest } from 'rxjs';

import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../../component/base.component';
import { FormGroup } from '@angular/forms';
import { MediaUploadModel } from './media-upload-model';
import { VelocityService } from '../../service/velocity.service';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';


@Component({
    moduleId: module.id.toString(),
    selector: 'media-upload-dialog',
    templateUrl: './media-upload-dialog.component.html',
    styleUrls: ['./media-upload-dialog.component.scss']
})
export class MediaUploadDialogComponent extends BaseComponent implements OnInit, OnChanges {


    observable: Observable<any>;
    subject: any;
    mediaType: number = 1;
    mediaUrl: string = '';
    mediaThumbnailUrl: string = '';
    mediaFullUrl: string = '';
    mediaThumbnailFullUrl: string;
    mediaContainer: string;
    @ViewChild('mediaUploadModal', { static: true }) mediaUploadModal: any;
    returnedValue: MediaUploadModel = new MediaUploadModel();
    returnedValueOriginal: string = JSON.stringify(this.returnedValue);
    uploadForm: FormData = new FormData();
    uploadItem: any;
    uploadFormVideo: FormData = new FormData();
    uploadVideo: any;
    currentProgress: number;
    showProgress: boolean;

    constructor(
        private utilService: UtilService,
        private router: Router,
        private injector: Injector,
        private elementRef: ElementRef,
        private velocityService: VelocityService
    ) {
        super(injector);
        this.mediaType = this.injector.get("mediaType");
        this.mediaUrl = this.injector.get("mediaUrl");
        this.mediaThumbnailUrl = this.injector.get("thumbnailUrl");

        this.mediaFullUrl = this.injector.get("mediaFullUrl");
        this.mediaThumbnailFullUrl = this.injector.get("mediaThumbnailFullUrl");
        this.mediaContainer = this.injector.get("mediaContainer");
    }


    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() {
    }

    onShown($ev) {
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
    }

    cancelClicked() {
        //reset to initial value
        this.returnedValue = JSON.parse(this.returnedValueOriginal);
        this.closeDialog();
    }

    okayClicked() {
        let observables: any = [];
        if (this.mediaType == 1) {
            if (!this.uploadItem) {
                this.utilService.showError("Please select an image to proceed");
                return;
            }

            observables.push(this.velocityService.uploadMedia(1, this.mediaContainer, this.uploadForm));
        }
        else if (this.mediaType == 2) {
            if (!this.uploadItem && !this.uploadVideo) {
                this.utilService.showError("Please select an image / video to proceed");
                return;
            } else if (!this.uploadItem && this.mediaThumbnailUrl == '') {
                this.utilService.showError("Please select a thumbnail image to proceed");
                return;
            }
            else if (!this.uploadVideo && this.mediaUrl == '') {
                this.utilService.showError("Please select a video to proceed");
                return;
            }
            else {
                this.showProgress = true;
                this.currentProgress = 0;
                if (this.uploadItem) {
                    observables.push(this.velocityService.uploadMedia(1, this.mediaContainer, this.uploadForm));
                }
                if (this.uploadVideo) {
                    observables.push(this.velocityService.uploadMedia(2, this.mediaContainer, this.uploadFormVideo, null, true));
                }
            }

        } else if (this.mediaType == 3) {
            if (!this.uploadVideo && this.mediaUrl == '') {
                this.utilService.showError("Please select a video to proceed");
                return;
            }
            else {
                this.showProgress = true;
                this.currentProgress = 0;
                if (this.uploadVideo) {
                    observables.push(this.velocityService.uploadMedia(2, this.mediaContainer, this.uploadFormVideo, null, true));
                }
            }

        }
        combineLatest(observables).subscribe((uploadResult: any) => {
            let [imageUrl, videoUrl] = uploadResult;
            if (this.mediaType == 1) {
                if (imageUrl.status === 'C') {
                    const image = imageUrl.value;
                    this.returnedValue.mediaUrl = image[0];
                    this.returnedValue.mediaFullUrl = image[1];
                    this.returnedValue.success = true;
                    this.closeDialog();
                }
            } else {
                if (this.mediaType == 3) {
                    videoUrl = imageUrl;
                }
                if (videoUrl) {
                    if (videoUrl.status === 'I') {
                        this.currentProgress = videoUrl.value;
                    } else {
                        this.currentProgress = 0;
                        this.showProgress = false;
                        const image = imageUrl.value, video = videoUrl.value;
                        if (this.uploadItem && this.uploadVideo) {
                            this.returnedValue.mediaThumbnailUrl = image[0];
                            this.returnedValue.mediaUrl = video[0];
                            this.returnedValue.mediaThumbnailFullUrl = image[1];
                            this.returnedValue.mediaFullUrl = video[1];
                        }
                        else if (this.uploadItem) {
                            this.returnedValue.mediaThumbnailUrl = image[0];
                            this.returnedValue.mediaThumbnailFullUrl = image[1];
                        } else if (this.uploadVideo) {
                            this.returnedValue.mediaUrl = video[0];
                            this.returnedValue.mediaFullUrl = video[1];
                        }
                        this.returnedValue.success = true;
                        this.closeDialog();
                    }
                }
            }
        });

        // this.velocityService.uploadMedia(2, this.mediaContainer, this.uploadFormVideo, ProgressBarHelper.DefaultPost, true).subscribe((result: any) => {
        //     if (result.status === 'I') {
        //         this.currentProgress = result.value;
        //     } else if (status === 'C') {
        //         this.currentProgress = 0;
        //         console.log(result.value);
        //     } else {
        //         console.log(result);
        //     }
        // });

    }

    onFileChange(fileInput: any, type: number) {
        let self = this;
        if (fileInput.target.files && fileInput.target.files[0]) {

            var file = fileInput.target.files[0];

            var fileType: string = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);

            var allowedFileTypes = [];
            if (type == 1) {
                allowedFileTypes = ["jpg", "jpeg", "png", "svg"];
            }
            else {
                allowedFileTypes = ["mpeg", "avi", "mp4"];
            }

            var isAllowedExtension = allowedFileTypes.filter(x => x.toLowerCase() == fileType.toLowerCase());
            if (isAllowedExtension && isAllowedExtension.length && isAllowedExtension.length > 0) {

                if (type == 1) {
                    if (this.uploadItem) {
                        this.uploadForm.set('file1', fileInput.target.files[0]);
                        this.uploadItem = fileInput.target.files[0];
                    } else {
                        this.uploadForm.append('file1', fileInput.target.files[0]);
                        this.uploadItem = fileInput.target.files[0];

                    }
                }
                else if (type == 2) {
                    if (this.uploadVideo) {
                        this.uploadFormVideo.set('file2', fileInput.target.files[0]);
                        this.uploadVideo = fileInput.target.files[0];
                    } else {
                        this.uploadFormVideo.append('file2', fileInput.target.files[0]);
                        this.uploadVideo = fileInput.target.files[0];

                    }
                }
                //let formData: FormData = new FormData();
                //formData.append('file', fileInput.target.files[0])
                //this.velocityService.setUserProfileImage(formData).subscribe(
                //    (data) => {
                //        self.userImageUrl = data.profileUrl;
                //        this.userService.setUserProfileImage(data.profileUrl);
                //    }
                //);
            }
            else {
                if (type == 1) {
                    this.utilService.showError("Please upload a valid image file")
                }
                else if (type == 2) {
                    this.utilService.showError("Please upload a valid video file")

                }
            }
        }
    }

    openDialog() {
        this.returnedValue.mediaUrl = this.mediaUrl;
        this.returnedValue.mediaThumbnailUrl = this.mediaThumbnailUrl;
        this.returnedValue.mediaFullUrl = this.mediaFullUrl;
        this.returnedValue.mediaThumbnailFullUrl = this.mediaThumbnailFullUrl;
        this.returnedValueOriginal = JSON.stringify(this.returnedValue);

        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.mediaUploadModal.config = this.config;
        this.mediaUploadModal.show();
        return this.observable;
    }

    private closeDialog() {
        this.mediaUploadModal.hide();
    }

    ngOnChanges(simpleChanges: SimpleChanges) {

        for (let prop in simpleChanges) {

        }
    }



}
