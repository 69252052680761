import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('src/app/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'community', loadChildren: () => import ('src/app/community/community.module').then(m=>m.CommunityModule) },
  { path: 'reference', loadChildren: () => import ('src/app/reference/reference.module').then(m=>m.ReferenceModule) },
  { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },
  { path: 'content-management', loadChildren: () => import('./content-management/content-management.module').then(m => m.ContentManagementModule) },
  { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
  { path: 'contact-us', loadChildren: () => import('src/app/enquiries/enquiries.module').then(m => m.EnquiriesModule) },
  { path: 'users', loadChildren: () => import('src/app/users/users.module').then(m => m.UsersModule) },
  { path: 'general', loadChildren: () => import('src/app/general/general.module').then(m => m.GeneralModule) },
  { path: 'data-import', loadChildren: () => import('src/app/data-import/data-import.module').then(m => m.DataImportModule) },
  { path: 'ey.aimaturitymodel', loadChildren: () => import('src/app/seven-driver/seven-driver.module').then(m => m.SevenDriverModule) },
   { path: '**', redirectTo: '/general/error-404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
