<!--<modal #okCancelModal modalClass="eyb-dialog-modal" [closeOnEscape]='false' [closeOnOutsideClick]='false' (onClose)="onModalClosed();">
    <modal-header>
        <h1>{{title}}</h1>
    </modal-header>
    <modal-content>
        <div class="row">
            <div class="col-md-12">{{message}}</div>
        </div>
    </modal-content>
    <modal-footer>
        <div class="row">
            <div class="col-md-6">
                <button class="btn" (click)="cancelClicked();" type="button">Cancel</button>
                <button class="btn btn-eyb-default" (click)="okayClicked();" type="button">Ok</button>
            </div>
        </div>
    </modal-footer>
</modal>-->


<div class="modal fade ey-dialog-modal" 
[ngClass]='{
    "location-modal": (customize.type === "LOCATION_DELETE"),
    "register-confirm-modal": (customize.type === "REGISTER_CONFIRM"),
    "logout-modal": (customize.type === "LOGOUT")
    }' 
bsModal #okCancelModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{title}}</h4>
            </div>
            <div class="modal-body">
                {{message}}
                <div class="checkbox mt-2">
                    <!-- <label>
                        <input type="checkbox"
                               (change)="changeCheckbox()" [checked]="isChecked">
                        <span class="cr"><i class="fa fa-check"></i></span>
                    </label> -->

                    <label>
                        <input type="checkbox"
                               (change)="changeCheckbox()"
                               [checked]="isChecked">
                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                    </label>
                    <span style="vertical-align:super;">
                        I acknowledge and agree on all implications of the selected action
                    </span>
                </div>
                <div class="redText" *ngIf="showWarning"> Please acknowledge before you proceed</div>
                <!--<div class="checkBoxAgree">
                    <p><input type="checkbox" required name="terms"></p>
                </div>-->
            </div>

            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="action-buttons">
                            <button class="btn btn-cancel me-2" (click)="cancelClicked();" type="button"
                                    [innerHTML]="customize.action.cancel || 'CANCEL'"></button>
                            <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button"
                                    [innerHTML]="customize.action.ok || 'OK'"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>