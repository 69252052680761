<div class="flex-container">
    <div id="mySidenav" class="column sidenav" [style.width.px]="sideMenuWidth" *ngIf="user!=null">
        <div [ngClass]='{"hide-menu":showMenu}'>
            <div class="openbtn" (click)="openNav()"><i class="fa fa-bars"></i></div><br />
            <div *ngFor="let menu of menuList" [id]="'ico'+ menu.menuName" class="icon-container"><i aria-hidden="true" class="fa collapse-menu-icons" [ngClass]="menu.iconClass"></i></div>
            
        </div>

        <div [ngClass]='{"hide-menu":!showMenu}'>

            <span class="closebtn" (click)="closeNav()"><i class="fa fa-bars"></i></span>

            <div *ngFor="let menu of menuList">
                <button *ngIf="!menu.hasChild" class="accordion no-submenu" [id]="'btn'+ menu.menuName" (click)="navigationFromMainMenu($event,menu.routes[0])"><i aria-hidden="true" class="fa menu-icon" [ngClass]="menu.iconClass"></i>{{menu.title}}</button>
                
                <button *ngIf="menu.hasChild" class="accordion" [id]="'btn'+ menu.menuName" (click)="showAccordianMenu($event)"><i aria-hidden="true" class="fa menu-icon" [ngClass]="menu.iconClass"></i>{{menu.title}}</button>
                <div *ngIf="menu.hasChild" class="panel" [id]="'pnl'+ menu.menuName">
                    <div class="sidenav-submenu-container">
                        <p class="sidenav-submenu" [id]="'sub'+ submenu.menuName"  (click)="navigationFromSubMenu($event,submenu.routes[0])" *ngFor="let submenu of menu.children">
                            <span class="fa fa-circle submenu-circle"></span>{{submenu.title}}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div id="main" class="column" [style.marginLeft.px]="sideMenuWidth">

        <router-outlet>
            <!--Load the pages here-->
        </router-outlet>
    </div>
</div>