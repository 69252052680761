import { Component, Input, ViewChild, Injector, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable,Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';

@Component({
    moduleId: module.id.toString(),
    selector: "user-profile-dialog",
    templateUrl: './user-profile-dialog-component.html',
    styleUrls: ['./user-profile-dialog-component.css']
})

export class UserProfileDialogComponent {

    userID: any;

    @ViewChild('userProfileModal',{ static: true }) userProfileModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
    @Output("closeEvent") componentEvent: any = new EventEmitter();

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(
        private injector: Injector) {
        this.userID = this.injector.get('userID');
    }

    ngOnInit() {

    }



    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
    }


    openDialog() {
        this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.userProfileModal.config = this.config;
        this.userProfileModal.show();
        return this.observable;
    }

    closeDialog() {
        this.userProfileModal.hide();
    }

}