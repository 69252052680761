<div class="modal fade media-upload-modal" bsModal #mediaUploadModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)"
    (onShown)="onShown($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    Upload media
                </h4>
            </div>
            <div class="modal-body">

                <div *ngIf="mediaType==1">
                    <div class="row form-row">
                        <div class="col-md-2 add-label">
                            <span>Image</span>
                        </div>
                        <div class="col-md-7">
                            <label for="files" class="btn btn-ey-default-gray btn-browse">Browse</label>
                            <!--<label *ngIf="mediaForm.get('mediaImage').value"
            class="">{{mediaForm.get('mediaImage').value.name || mediaForm.get('mediaImage').value}}</label>-->
                            <input type="file" id="files" style="visibility:hidden;" (change)="onFileChange($event,1)" accept="image/jpeg,image/png">
                            <!--<div *ngIf="!mediaForm.get('mediaImage').valid && mediaForm.get('mediaImage').touched"
                 class="alert alert-danger">
                Enter valid input
            </div>-->
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-7 offset-md-2">
                            {{uploadItem ? uploadItem.name:''}}
                            <a [href]="mediaFullUrl" target="_blank" *ngIf="mediaFullUrl!=''">View original media</a>
                        </div>
                    </div>
                </div>

                <div *ngIf="mediaType==2">
                    <div class="row form-row">
                        <div class="col-md-2 add-label">
                            <span>Thumbnail Image</span>
                        </div>
                        <div class="col-md-7">
                            <label for="filethumb" class="btn btn-ey-default-gray btn-browse">Browse</label>

                            <input type="file" id="filethumb" style="visibility:hidden;" (change)="onFileChange($event,1)" accept="image/jpeg,image/png">

                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <div class="row">
                        <div class="col-md-7 offset-md-2">
                            {{uploadItem ? uploadItem.name:''}}

                            <a [href]="mediaThumbnailFullUrl" target="_blank" *ngIf="mediaThumbnailFullUrl!=''">View original media</a>
                        </div>
                    </div>

                    <div class="row form-row">
                        <div class="col-md-2 add-label">
                            <span>Video</span>
                        </div>
                        <div class="col-md-7">
                            <label for="fileVideo" class="btn btn-ey-default-gray btn-browse">Browse</label>

                            <input type="file" id="fileVideo" style="visibility:hidden;" (change)="onFileChange($event,2)" accept="video/*">
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-7 offset-md-2">
                            <span *ngIf="showProgress">Uploading: {{currentProgress}}%</span>
                            <!-- <img *ngIf="showProgress" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            /> -->
                            <span *ngIf="!showProgress">{{uploadVideo?uploadVideo.name:''}}</span>
                            <a [href]="mediaFullUrl" target="_blank" *ngIf="mediaFullUrl!='' && !showProgress">View original media</a>
                        </div>
                    </div>
                    <div class="row" *ngIf="showProgress">
                        <div class="col-md-7 offset-md-2">
                        </div>
                    </div>
                </div>

                <div *ngIf="mediaType==3">
                    <div class="row form-row">
                        <div class="col-md-2 add-label">
                            <span>Video</span>
                        </div>
                        <div class="col-md-7">
                            <label for="fileVideo" class="btn btn-ey-default-gray btn-browse">Browse</label>

                            <input type="file" id="fileVideo" style="visibility:hidden;" (change)="onFileChange($event,2)" accept="video/*">
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-7 offset-md-2">
                            <span *ngIf="showProgress">Uploading: {{currentProgress}}%</span>
                            <!-- <img *ngIf="showProgress" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            /> -->
                            <span *ngIf="!showProgress">{{uploadVideo?uploadVideo.name:''}}</span>
                            <a [href]="mediaFullUrl" target="_blank" *ngIf="mediaFullUrl!='' && !showProgress">View original media</a>
                        </div>
                    </div>
                    <div class="row" *ngIf="showProgress">
                        <div class="col-md-7 offset-md-2">
                        </div>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="action-buttons">
                            <button class="btn btn-cancel me-2" (click)="cancelClicked();" type="button">Cancel</button>
                            <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button">Upload</button>
                            <!--[disabled]="this.selectedUsers.length==0"-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>