import { Injectable } from '@angular/core';
import { Observable,timer } from 'rxjs';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { Constants } from './constant.service';
import { StorageHelperService } from './storage-helper.service';

@Injectable()
export class TimerService {

    timeOutWarningTimer: Observable<number> = timer(0);
    timeoutSubscribers: Array<Subscription> = [];
    count: number = 0;
    alertShown: boolean = false;

    public timeoutAction: Observable<string>;
    timeOutActionSubject: BehaviorSubject<string>;


    constructor(private constants: Constants,
        private storageHelper: StorageHelperService
    ) {
        this.timeOutActionSubject = new BehaviorSubject("init");
        this.timeoutAction = this.timeOutActionSubject.asObservable();
    }

    start() {
       // console.log("start");
        this.timeOutActionSubject.next("start");
        this.count = 0;
        this.timeOutWarningTimer = timer(0, 1000);

        var timeoutSubscriber = this.timeOutWarningTimer.subscribe(tick => {
            //console.log(tick);
            this.count = tick;

            // console.log(this.count)

            var elapsedSeconds = this.checkElapsedTime();
           // console.log("elapsed", elapsedSeconds);
            if (elapsedSeconds >= this.constants.TIMEOUT_HANDLER_OPTIONS.FORCED_LOGOUT_DURATION) {
                this.alertShown = false;
                this.timeOutActionSubject.next("logout");
               //  console.log("elapsedSeconds >= this.constants.TIMEOUT_HANDLER_OPTIONS.FORCED_LOGOUT_DURATION");
            }

            else if ((elapsedSeconds >= (this.constants.TIMEOUT_HANDLER_OPTIONS.FORCED_LOGOUT_DURATION - this.constants.TIMEOUT_HANDLER_OPTIONS.LOGOUT_DURATION + this.constants.TIMEOUT_HANDLER_OPTIONS.WARNING_DURATION)) && (elapsedSeconds < this.constants.TIMEOUT_HANDLER_OPTIONS.FORCED_LOGOUT_DURATION)) {
                if (this.alertShown != true) {
                    this.alertShown = true;
                    this.timeOutActionSubject.next("showdialoglast");
                }
                //  console.log("showdialoglast");
            }

            else if (this.count < this.constants.TIMEOUT_HANDLER_OPTIONS.WARNING_DURATION) {
                this.alertShown = false;
                // console.log("this.count < this.constants.TIMEOUT_HANDLER_OPTIONS.WARNING_DURATION");
            }
            else if (this.count >= this.constants.TIMEOUT_HANDLER_OPTIONS.WARNING_DURATION && this.count < this.constants.TIMEOUT_HANDLER_OPTIONS.LOGOUT_DURATION) {
                if (this.alertShown != true) {
                    this.alertShown = true;
                    this.timeOutActionSubject.next("showdialog");
                   // console.log("showdialog");
                }

            }
            else if (this.count >= this.constants.TIMEOUT_HANDLER_OPTIONS.LOGOUT_DURATION) {
                this.alertShown = false;
                this.timeOutActionSubject.next("logout");
                // console.log("this.count >= this.constants.TIMEOUT_HANDLER_OPTIONS.LOGOUT_DURATION");

            }
        });

        this.timeoutSubscribers.push(timeoutSubscriber);

    }

    stopAndKill() {
        // console.log("stopandkill");
        if (this.timeOutWarningTimer) {
            this.killAllSubscriptions();
            this.timeOutWarningTimer = null;
        }
    }

    restart() {
        // console.log("restart");
        this.stopAndKill();
        this.start();
    }

    killAllSubscriptions() {
        for (let subscription of this.timeoutSubscribers) {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }
        }

        this.timeoutSubscribers = [];
    }

    setStartTime() {
        var dateObj = new Date();
        var ticks = dateObj.getTime();
        this.storageHelper.SetLocalValue("session_start", ticks);
    }

    clearStartTime() {
        this.storageHelper.ClearLocalValue("session_start");
    }

    checkElapsedTime() {
        var sessionStartTime = this.storageHelper.GetLocalValue<number>("session_start") || 0;
        var dateObj = new Date();
        var currentTicks = dateObj.getTime();
        var elapsedTicks = currentTicks - sessionStartTime;
        return Math.floor(elapsedTicks / 1000);
    }
}