import { Component, Input, ViewChild, Injector } from '@angular/core';
//import { ModalModule, Modal } from 'ng2-modal';
import {Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    moduleId: module.id.toString(),
    selector: "alert-dialog",
    templateUrl: './alert-dialog.component.html',
})

export class AlertDialogComponent {
    title: string = "";
    message: string = "";
    showCloseButton: boolean = true;
    @ViewChild('alertModal', { static: true }) alertModal: ModalDirective ;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'ok';

    public config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };

    constructor(private injector: Injector) {
        this.title = this.injector.get('title');
        this.message = this.injector.get('message');
        this.showCloseButton = this.injector.get('showCloseButton');
    }

    ngOnInit() { }

    onModalClosed($event:any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }
    }

    okayClicked() {
        this.returnedValue = "ok";
        this.closeDialog();
    }

    openDialog() {
        this.returnedValue = "ok";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.alertModal.config = this.config;
        this.alertModal.show();
        return this.observable;
    }

    closeDialog() {
        this.alertModal.hide();
    }
}