import { Component, OnInit, Injector, ViewChild, ElementRef, AfterViewInit, Input, SimpleChanges, OnChanges, ViewEncapsulation } from '@angular/core';
import { UtilService } from '../../service/util.service';
import { Router, NavigationEnd, Event, NavigationStart, ActivatedRoute } from '@angular/router';
import { UserService } from './../../auth/service/user.service';
import { Constants } from './../../service/constant.service';
import { Location } from '@angular/common';
import { BaseComponent } from "../base.component";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../auth/service/auth.service";
import { filter } from 'rxjs/operators';
import { isAfter } from 'ngx-bootstrap/chronos';

@Component({
    selector: 'app-navigation',
    templateUrl: './app-navigation.component.html',
    styleUrls: ['./app-navigation.component.scss'],
    encapsulation:ViewEncapsulation.None
})

export class AppNavigationComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
    @Input('user') user: any;
    contentHeight: number = 500;
    showMenu: boolean = true;
    sideMenuWidth: number = 297;

    menuList: any = [];
    routePermission: any;
    isLoggedIn: boolean;

    constructor(
        private injector: Injector,
        private utilService: UtilService,
        private router: Router,
        private elementRef: ElementRef,
        private route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        private location: Location
    ) {
        super(injector);
        if (this.location.path() != "/general/logout") {

            var user = this.userService.getCurrentUser();
            if (user != null ) {
                this.menuList = user.permissions;
                this.isLoggedIn = true;
            }
            // To get the route permission to highlight the selected menu
            this.routePermission = "";

            this.router.events
                .pipe(filter((event: any) => event instanceof NavigationEnd))
                .subscribe(() => {
                    var root = this.router.routerState.snapshot.root;
                    while (root) {
                        if (root.children && root.children.length) {
                            root = root.children[0];

                        } else if (root.data && root.data["permission"]) {

                            this.routePermission = root.data["permission"];
                            this.activateMenu();
                            this.highlightIcons();
                            return;
                        } else {

                            this.routePermission = "";
                            this.activateMenu();
                            this.highlightIcons();
                            return;
                        }
                    }
                });
        } else {
            this.sideMenuWidth = 0;
        }
        
    }

    ngOnInit() {
        if (this.user) {
            this.menuList = this.user.permissions;
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (this.user) {
            this.menuList = this.user.permissions;
        }

    }

    ngAfterViewInit() {
        this.activateMenu();
    }

    openNav() {
        this.sideMenuWidth = 300;
        this.showMenu = true;

        this.activateMenu();
    }

    closeNav() {
        this.sideMenuWidth = 70;

        setTimeout(() => {
            this.showMenu = false;

            this.highlightIcons();
        }, 300);
    }

    highlightIcons() {
        setTimeout(() => {
            let submenu = document.getElementsByClassName("selected-icon") as HTMLCollectionOf<HTMLElement>;
            var i;
            if(submenu != null) {
                for (i = 0; i < submenu.length; i++) {
                    submenu[i].classList.remove("selected-icon");
                }
            }

            if (this.menuList && this.menuList.length > 0) {
                for (let menu of this.menuList) {
                    if (menu.hasChild) {
                        if (menu.children.length > 0) {
                            for (let submenu of menu.children) {
                                if (this.routePermission == submenu.menuName) {
                                    let mainIcon = document.getElementById("ico" + menu.menuName) as HTMLElement;
                                    if (mainIcon)
                                    mainIcon.classList.add("selected-icon");

                                    return;
                                }
                            }
                        }
                    }
                    else {
                        if (this.routePermission == menu.menuName) {
                            let mainIcon = document.getElementById("ico" + menu.menuName) as HTMLElement;
                            if (mainIcon)
                            mainIcon.classList.add("selected-icon");

                            return;
                        }
                    }
                }
            }
        },500);
    }

    activateMenu() {
        setTimeout(() => {
            let acc = document.getElementsByClassName("accordion") as HTMLCollectionOf<HTMLElement>;
            var i;

            for (i = 0; i < acc.length; i++) {
                acc[i].classList.remove("active");
                acc[i].classList.remove("selected-mainmenu");
            }

            let panels = document.getElementsByClassName("panel") as HTMLCollectionOf<HTMLElement>;
            var i;
            for (i = 0; i < panels.length; i++) {
                panels[i].style.maxHeight = null;
            }

            let subMenu = document.getElementsByClassName("active-submenu") as HTMLCollectionOf<HTMLElement>;
            var i;
            for (i = 0; i < subMenu.length; i++) {
                subMenu[i].classList.remove("active-submenu");
            }

            if (this.menuList && this.menuList.length > 0) {
                for (let menu of this.menuList) {
                    if (menu.hasChild) {
                        if (menu.children.length > 0) {
                            for (let submenu of menu.children) {
                                if (this.routePermission == submenu.menuName) {
                                    let subMenu = document.getElementById("sub" + submenu.menuName) as HTMLElement;
                                    if (subMenu)
                                    subMenu.classList.add("active-submenu");

                                    let mainMenu = document.getElementById("btn" + menu.menuName) as HTMLElement;
                                    if (mainMenu)
                                    mainMenu.classList.add("selected-mainmenu");

                                    let panel = document.getElementById("pnl" + menu.menuName) as HTMLElement;
                                    if (panel) {
                                        if (panel.scrollHeight > 0) {
                                            panel.style.maxHeight = panel.scrollHeight + "px";
                                        }
                                        else {
                                            if (panel.children && panel.children[0]) {
                                                panel.style.maxHeight = ((panel.children[0].childElementCount * 30) + 30) + "px";
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                    else {
                        if (this.routePermission == menu.menuName) {

                            let mainMenu = document.getElementById("btn" + menu.menuName) as HTMLElement;
                            if (mainMenu)
                            mainMenu.classList.add("active");

                            let panels = document.getElementsByClassName("panel") as HTMLCollectionOf<HTMLElement>;
                            var i;
                            for (i = 0; i < panels.length; i++) {
                                panels[i].style.maxHeight = null;
                            }

                            return;
                        }
                        
                    }
                }
            }
        },500);
    }

    showAccordianMenu(event) {

        let activeElement = false;
        if (event.target.nextElementSibling && event.target.nextElementSibling.style.maxHeight)
        { activeElement = true } else { activeElement = false };

        let acc = document.getElementsByClassName("accordion") as HTMLCollectionOf<HTMLElement>;
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].classList.remove("active");
            acc[i].classList.remove("selected-mainmenu");
        }

        let panels = document.getElementsByClassName("panel") as HTMLCollectionOf<HTMLElement>;
        var i;
        for (i = 0; i < panels.length; i++) {
            panels[i].style.maxHeight = null;
        }

        let accordian = event.target;
        accordian.classList.toggle("selected-mainmenu");
        let panel = accordian.nextElementSibling;
        if (panel) {
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                if (activeElement == true) {
                    panel.style.maxHeight = null
                    accordian.classList.toggle("active");
                    accordian.classList.toggle("selected-mainmenu");
                }
                else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }

            }
        }
    }

    navigationFromMainMenu(event, url: string) {
        this.showAccordianMenu(event);

        // To remove the highlighted submneu style
        let submenu = document.getElementsByClassName("sidenav-submenu") as HTMLCollectionOf<HTMLElement>;
        var i;
        for (i = 0; i < submenu.length; i++) {
            submenu[i].classList.remove("active-submenu");
        }

        if (url) {
            this.router.navigate([url]);
        }
    }

    navigationFromSubMenu(event, url: string) {
        this.highlightSubmenu(event);
        let redirectUrl = url;
        if (url) {
            if (url == '/content-management/translation/upload') {
                if (!this.userService.getEditPermissionUrl(url)) {
                    redirectUrl = '/content-management/translation/download';
                }
            }
            this.router.navigate([redirectUrl]);

        }
    }

    highlightSubmenu(event) {
        let submenu = document.getElementsByClassName("sidenav-submenu") as HTMLCollectionOf<HTMLElement>;
        var i;

        for (i = 0; i < submenu.length; i++) {
            submenu[i].classList.remove("active-submenu");
        }

        let selectedSubmenu = event.target;
        selectedSubmenu.classList.toggle("active-submenu");
    }
} 
