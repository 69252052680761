import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SubMenuService {
    subMenusFocus: { routerLink: string[], name: string }[] = [];
    subMenuObs = new Subject<any>();
    page: string;
    constructor() { }

    emitSubMenu() {
        this.subMenuObs.next(this.subMenusFocus);
    }
  
}
