import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './../service/user.service'
import { AuthenticationService } from "../service/auth.service";
//import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
import { MsalService} from '@azure/msal-angular';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {


    constructor(private router: Router,
        private userService: UserService,
        private authService: AuthenticationService,
     //   private dialogService: ModalDialogService,
        private msalService: MsalService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean> {
        return this.isAuthenticated(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.isAuthenticated(childRoute);
    }

    isAuthenticated(route: any): Promise<boolean> {
        return new Promise((resolve, reject) => {
            //Login changes - Evangelin
            //if (environment.USE_LoGIN_DEV && (environment.environmentName == 'dev' || environment.environmentName == 'qa')) {

            //    var currentUser: any = this.userService.getCurrentUser();

            //    if (currentUser && currentUser.token) {
            //        this.authService.checkAuth().subscribe(x => {

            //            if (this.hasPermission(route, currentUser)) {
            //                resolve(true);
            //                //return true;
            //            } else {
            //                this.router.navigate(['/general/permission-denied']);
            //                resolve(false);
            //            }

            //        }, error => {
            //            this.userService.logout();
            //           // this.msalService.logout();
            //            //this.router.navigate(['/general/logout']);
            //            resolve(false);
            //        })
            //    }
                
            //} else {
            // const tokenStored = this.msalService.getCachedTokenInternal(this.msalService.getScopesForEndpoint(""));
            if (this.msalService.instance.getAllAccounts().length > 0) {
                    //if (this.msalService._oauthData.isAuthenticated) {
                    var currentUser: any = this.userService.getCurrentUser();

                    if (currentUser && currentUser.token) {
                        this.authService.checkAuth().subscribe(x => {

                            if (this.hasPermission(route, currentUser)) {
                                resolve(true);
                                //return true;
                            } else {
                                this.router.navigate(['/general/permission-denied']);
                                resolve(false);
                            }

                        }, error => {
                            this.userService.logout();
                            this.msalService.logout();
                            //this.router.navigate(['/general/logout']);
                            resolve(false);
                        });

                    }
                    else {
                        if (this.msalService.instance.getAllAccounts().length > 0) {
                            var tokenRequest = {
                                scopes: []
                            };
                            this.msalService.instance.acquireTokenSilent(tokenRequest).then(response => {
                                console.log(response);
                                this.authService.login(this.msalService.instance.getActiveAccount().username,response.idToken)
                                    .subscribe((result: any) => {
                                        if (result === true) {
                                            var currentUser: any = this.userService.getCurrentUser();
                                            if (currentUser && currentUser.token) {
                                                if (this.hasPermission(route, currentUser)) {
                                                    //return true;
                                                    resolve(true);
                                                } else {
                                                    this.router.navigate(['/general/permission-denied']);
                                                    resolve(false);
                                                }
                                            }
                                        } else {
                                            this.userService.logout();
                                            this.msalService.logout();
                                            //this.router.navigate(['/general/logout']);
                                            //return false;
                                            resolve(false);
                                        }
                                    });
                            }).catch(err => {
                                console.log(err);
                                this.msalService.logoutRedirect();
                            });
                        } else {
                            this.msalService.loginRedirect();
                        }

                    }
                } else {
                    this.userService.logout();
                  
                if (!this.userService.inProgressMsal) {
                    this.msalService.loginRedirect();
                }
                    resolve(false);

                }
         //   }
        });
    }

    hasPermission(route: any, user: any) {
        let routePermission = route.data["permission"] as string;
        if (routePermission == "" || routePermission == " " || routePermission == undefined || routePermission == null) {
            return false;
        }
        
        for (let menu of user.permissions) {
            if (menu.hasChild && menu.children.length > 0) {
                for (let submenu of menu.children) {
                    if (submenu.menuName == routePermission){
                        return true;
                    }
                }
            }
            else {
                if (menu.menuName == routePermission) {
                    return true;
                }
            }
        }

        return false;
    }
}
