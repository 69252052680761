import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { VelocityService } from './velocity.service';
import { UserService } from '../auth/service/user.service';

@Injectable()
export class NotificationService {

    private notificationCount: number = 0;
    private notificationCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.notificationCount);
    public notificationCountObservable = this.notificationCountSubject.asObservable();

    constructor(private velocityService:VelocityService, private userService:UserService) {

    }
}