import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'datex', pure: false })

export class DatexPipe implements PipeTransform {
    transform(value: any, format: string = ""): string {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        var newDate = new Date(value);
        var sMonth = monthNames[newDate.getMonth()];
        var sDay = this.padValue(newDate.getDate());
        var sYear = newDate.getFullYear();
        var sHour = newDate.getHours();
        var sMinute = this.padValue(newDate.getMinutes());
        var sAMPM = "AM";

        var iHourCheck = sHour;

        if (iHourCheck > 12) {
            sAMPM = "PM";
            sHour = iHourCheck - 12;
        }
        else if (iHourCheck === 0) {
            sHour = 12;
        }

        sHour = this.padValue(sHour);

        return sDay + "-" + sMonth + "-" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
    }
    padValue (value) {
        return (value < 10) ? "0" + value : value;
    }
}