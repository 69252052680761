<div class="modal fade media-upload-modal" bsModal #mediaUploadModal="bs-modal" tabindex="-1" role="dialog" (onHide)="onModalClosed($event)"
    (onShown)="onShown($event)">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    Upload HTML - {{title}}
                </h4>
            </div>
            <div class="modal-body">

                <div *ngIf="mediaType==1">
                    <div class="row form-row">
                        <div class="col-md-2 add-label">
                            <span>HTML</span>
                        </div>
                        <div class="col-md-7">
                            <label for="files" class="btn btn-ey-default-gray btn-browse">Browse</label>
                           <input type="file" id="files" style="visibility:hidden;" (change)="onFileChange($event)" accept="text/html">
                           
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-7 offset-md-2">
                            {{uploadItem ? uploadItem.name:''}}
                            <a [href]="mediaFullUrl" target="_blank" *ngIf="mediaFullUrl!=''">View original media</a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="action-buttons">
                            <button class="btn btn-cancel me-2" (click)="cancelClicked();" type="button">Cancel</button>
                            <button class="btn btn-ey-default btn-ok" (click)="okayClicked();" type="button">Upload</button>
                            <!--[disabled]="this.selectedUsers.length==0"-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>